import React, { useState, useEffect } from "react";

const Calculator = () => {
  const [display, setDisplay] = useState("");

  useEffect(() => {
    const handleKeyPress = (event) => {
      const { key } = event;
      const validKeys = "0123456789+-*/.";

      if (validKeys.includes(key)) {
        handleInput(key);
      } else if (key === "Enter") {
        calculateResult();
      } else if (key === "Backspace") {
        deleteLastCharacter();
      } else if (key.toLowerCase() === "c") {
        clearDisplay();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => document.removeEventListener("keydown", handleKeyPress);
  }, []);

  const handleInput = (value) => {
    setDisplay((prev) =>
      prev === "0" || prev === "Error" ? value : prev + value
    );
  };

  const calculateResult = () => {
    try {
      const result = eval(display);
      setDisplay(result.toString());
    } catch {
      setDisplay("Error");
    }
  };

  const clearDisplay = () => setDisplay("0");

  const deleteLastCharacter = () => {
    setDisplay((prev) => (prev.length > 1 ? prev.slice(0, -1) : "0"));
  };

  const buttons = [
    { label: "C", action: clearDisplay, span: 2 },
    { label: "←", action: deleteLastCharacter },
    { label: "/", action: () => handleInput("/") },
    { label: "7", action: () => handleInput("7") },
    { label: "8", action: () => handleInput("8") },
    { label: "9", action: () => handleInput("9") },
    { label: "x", action: () => handleInput("*") },
    { label: "4", action: () => handleInput("4") },
    { label: "5", action: () => handleInput("5") },
    { label: "6", action: () => handleInput("6") },
    { label: "-", action: () => handleInput("-") },
    { label: "1", action: () => handleInput("1") },
    { label: "2", action: () => handleInput("2") },
    { label: "3", action: () => handleInput("3") },
    { label: "+", action: () => handleInput("+") },
    { label: "0", action: () => handleInput("0") },
    { label: ".", action: () => handleInput(".") },
    { label: "=", action: calculateResult, span: 2 },
  ];

  return (
    <div className="calculator bg-white p-4 rounded-md">
      <input
        type="text"
        value={display}
        disabled
        placeholder="Expressions here.."
        className="w-full mb-2 text-xl border-b-4 bg-main/10 border-main text-main px-2 py-2.5 rounded-md text-right"
      />
      <div className="grid grid-cols-4 gap-2">
        {buttons.map((item, index) => (
          <button
            key={index}
            className={`bg-main text-white py-[0.4rem] rounded-md ${item.span ? `col-span-2` : "col-span-1"
              }`}
            onClick={item.action}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Calculator;
