import React from 'react';
import * as Dialog from "@radix-ui/react-dialog";
import NoteBookEditor from '../components/NoteBookEditor';

function NoteBook() {
  return (
    <div className="px-6 md:px-24 py-6 text-main bg-white" >
      <div className="flex flex-col gap-10 lg:flex-row-reverse items-center lg:items-start w-full h-auto lg:h-fit pt-12 md:pt-24">
        <div className="w-full lg:w-[45%] flex items-center justify-center  lg:h-full mb-8 lg:mb-0">
          <div className="block w-full">
            <div class="relative mx-auto border-main/60  bg-main/60 border-[10px] rounded-t-xl h-[172px] max-w-[301px] md:h-[304px] md:max-w-[512px]">
              <div class="rounded-lg overflow-hidden h-[166px] md:h-[288px] bg-white">
                <img src={'https://ik.imagekit.io/vituepzjm/NoteBook.png?updatedAt=1725569713261'}
                  alt='NoteBook' class="h-[166px] md:h-[288px] w-full rounded-lg" />
              </div>
            </div>
            <div class="relative mx-auto bg-main rounded-b-xl rounded-t-sm h-[17px] max-w-[351px] md:h-[25px] md:max-w-[597px]">
              <div class="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-xl w-[56px] h-[5px] md:w-[96px] md:h-[10px] bg-white/15"></div>
            </div>
          </div>
        </div>
        <div className={`w-full lg:w-[50%] lg:py-12  h-full text-center lg:text-left transition-opacity duration-500`}>
          <span className="flex items-center mb-4 justify-center md:justify-start gap-5">
            <h1 className="text-2xl font-bold text-main sm:text-3xl">
              Note Book
            </h1>
            <div>
              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button
                    className="px-4 py-2 bg-main text-white rounded-lg">
                    Try Now
                  </button>
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
                  <Dialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[90vh] md:h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                    <NoteBookEditor />
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root></div>
          </span>
          <p className="mb-4 font-medium text-lg">Manage your notes and stories effortlessly with Notebook.</p>
          <ul className="list-disc grid grid-cols-2 text-left list-inside text-white h-full bg-main p-5 rounded-md">
            <li>Title</li>
            <li>Delimiter</li>
            <li>CheckList</li>
            <li>Code</li>
            <li>List</li>
            <li>Quote</li>
            <li>Mermaid</li>
            <li>Alert</li>
            <li>Math</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default NoteBook