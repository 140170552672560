import React, { useEffect, useState } from "react";
import { useData } from "../../context/DataContext";
import toast, { Toaster } from "react-hot-toast";
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { HiTrash } from "react-icons/hi2";

function SketchCard({ setResults, item, HandleDelete, isDeleting, setisEditing }) {
  const { setboardPreview, setCards } = useData();
  const [newCardName, setNewCardName] = useState(item.name);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleCardRename = async (e) => {
    e.preventDefault();
    if (newCardName.trim() && newCardName !== item.name) {
      try {
        setIsProcessing(true);

        const userDocQuery = query(
          collection(db, "notecard"),
          where("ncid", "==", item.ncid)
        );
        const querySnapshot = await getDocs(userDocQuery);

        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
          const updatedTime = new Date();

          await updateDoc(docRef, {
            name: newCardName,
            updatedAt: updatedTime,
          });

          setCards((prevCards) =>
            prevCards.map((c) =>
              c.ncid === item.ncid
                ? { ...c, name: newCardName, updatedAt: updatedTime }
                : c
            )
          );

          if (setResults) {
            setResults((prevCards) =>
              prevCards.map((c) =>
                c.ncid === item.ncid
                  ? { ...c, name: newCardName, updatedAt: updatedTime }
                  : c
              )
            );
          }

          setboardPreview({});
          setNewCardName("");
          toast.success("Renamed Successfully");
        } else {
          console.log("No matching document found.");
        }
      } catch (error) {
        console.error("Error renaming item:", error);
      } finally {
        setIsProcessing(false);
        setIsRenaming(false);
      }
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      setisEditing(false);
      window.history.pushState(null, "", window.location.pathname);

      console.log("Back navigation attempt prevented");
    };
    window.addEventListener('popstate', handlePopState);
    window.history.pushState(null, "", window.location.pathname);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [setisEditing]);

  return (
    <div
      className="bg-white w-full h-full rounded-t-2xl"
      style={{ backgroundColor: item.color }}
    >
      <div className="w-full h-[10vh] p-4 rounded-t-2xl flex items-center justify-between gap-3">
        {isRenaming ? (
          <form onSubmit={handleCardRename} className="flex-1">
            <input
              type="text"
              disabled={isProcessing}
              value={newCardName}
              onBlur={() => setIsRenaming(false)}
              onChange={(e) => setNewCardName(e.target.value)}
              className="text-lg text-white w-fit bg-main rounded-md px-4 py-2 font-bold outline-none"
              autoFocus
            />
          </form>
        ) : (
          <h1
            onClick={() => setIsRenaming(true)}
            className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold cursor-pointer"
          >
            {item.name.length > 15
              ? `${item.name.slice(0, 15)}..`
              : item.name}
          </h1>
        )}

        <button
          onClick={() => setIsDeleteOpen(true)}
          className="bg-red-500 text-white px-2 py-2 rounded-md"
        >
          <HiTrash className="text-lg" />
        </button>

        <AlertDialog.Root open={isDeleteOpen}>
          <AlertDialog.Portal>
            <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
            <AlertDialog.Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-md focus:outline-none">
              <AlertDialog.Title className="text-red-600 text-xl font-semibold">
                Are you absolutely sure?
              </AlertDialog.Title>
              <AlertDialog.Description className="text-gray-600 font-medium mt-2 mb-6">
                This action cannot be undone. This will move{" "}
                <span className="font-bold text-main">
                  {`${item.name} - ${item.type[0].toUpperCase() + item.type.slice(1)} Card`}
                </span>{" "}
                to the Trash page where you can restore it anytime.
              </AlertDialog.Description>
              <div className="flex justify-start gap-3">
                <button
                  disabled={isDeleting}
                  onClick={() => {
                    HandleDelete(item);
                    setisEditing(false);
                    setIsDeleteOpen(false);
                  }}
                  className="px-4 py-2 bg-red-600 disabled:bg-red-400 text-white rounded-md hover:bg-red-700"
                >
                  Move to Trash
                </button>
                <button
                  onClick={() => setIsDeleteOpen(false)}
                  className="px-4 py-2 bg-main/10 text-main/90 rounded-md hover:bg-main/20"
                >
                  Cancel
                </button>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      </div>

      <div
        className="w-full h-[85vh] flex items-center justify-center text-main overflow-y-auto px-8 py-3"
        style={{ backgroundColor: item.color }}
      >

        <div className="md:w-auto md:h-[70vh] object-cover rounded-xl" dangerouslySetInnerHTML={{ __html: item?.data }} />
      </div>

      <Toaster />
    </div>
  );
}

export default SketchCard;
