import React, { useEffect, useState } from 'react';
import { useData } from '../../../context/DataContext';
import { collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../../config/firebase';
import toast, { Toaster } from 'react-hot-toast';
import { MdOutlineIosShare } from 'react-icons/md';

function ImageCardEditor({ item, setisEditing, sortData }) {
    const { setSharedcards } = useData();
    const [newCardName, setNewCardName] = useState(item.name);
    const [isRenaming, setIsRenaming] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleCardRename = async (e) => {
        e.preventDefault();
        if (!newCardName.trim() || newCardName === item.name) return;

        setIsProcessing(true);

        try {
            const cardQuery = query(
                collection(db, "notecard"),
                where("ncid", "==", item.ncid)
            );
            const querySnapshot = await getDocs(cardQuery);

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                const updatedTime = new Date();

                await updateDoc(docRef, {
                    name: newCardName,
                    updatedAt: updatedTime,
                });

                setSharedcards((prevCards) =>
                    prevCards.map((card) =>
                        card.ncid === item.ncid
                            ? { ...card, name: newCardName, updatedAt: updatedTime }
                            : card
                    )
                );
                setisEditing(false);
                sortData()
                toast.success("Renamed Successfully");
            } else {
                console.log("No matching document found.");
            }
        } catch (error) {
            console.error("Error renaming item:", error);
        } finally {
            setIsProcessing(false);
            setIsRenaming(false);
        }
    };

    const HandleShare = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: item.name,
                    url: item.data,
                })
                .then(() => {
                    console.log("ok");
                })
                .catch((err) => {
                    console.error(err);
                    toast.error("Unable to Share");
                });
        } else {
            toast.error("Unable to Share");
        }
    }

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            setisEditing(false);
            window.history.pushState(null, "", window.location.pathname);
            console.log("Back navigation attempt prevented");
        };
        window.addEventListener('popstate', handlePopState);
        window.history.pushState(null, "", window.location.pathname);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [setisEditing]);

    return (
        <div
            className="bg-white w-full h-full rounded-t-2xl"
            style={{ backgroundColor: item.color }}
        >
            <div className="w-full h-[10vh] p-4 rounded-t-2xl flex items-center justify-between gap-3">
                {isRenaming ? (
                    <form onSubmit={handleCardRename} className="flex-1">
                        <input
                            type="text"
                            disabled={isProcessing}
                            value={newCardName}
                            onBlur={() => setIsRenaming(false)}
                            onChange={(e) => setNewCardName(e.target.value)}
                            className="text-lg text-white w-fit bg-main rounded-md px-4 py-2 font-bold outline-none"
                            autoFocus
                        />
                    </form>
                ) : (
                    <h1
                        onClick={() => setIsRenaming(true)}
                        className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold cursor-pointer"
                    >
                        {item.name.length > 15
                            ? `${item.name.slice(0, 15)}..`
                            : item.name}
                    </h1>
                )}

                <button
                    onClick={() => HandleShare()}
                    className="bg-main text-white active:scale-95 transition-all px-2 py-2 rounded-md"
                >
                    <MdOutlineIosShare className="text-lg" />
                </button>
            </div>

            <div
                className="w-full h-[85vh] flex items-center justify-center text-main overflow-y-auto px-8 py-3"
                style={{ backgroundColor: item.color }}
            >
                <img
                    src={item.data}
                    alt={item.name}
                    className="md:w-auto bg-white md:h-[70vh] object-cover rounded-xl shadow-md"
                />
            </div>
            <Toaster />
        </div>
    );
}

export default ImageCardEditor;
