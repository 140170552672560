import React, { useEffect, useState } from 'react';
import { NoteboardList } from '../../../common/links';
import * as Dialog from "@radix-ui/react-dialog";
import NoteCardEditor from '../components/NoteCardEditor';
import TodoEditor from "../components/TodoEditor";
import Drawing from "../components/Drawing";

function Noteboard() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const handleCardClick = (index) => {
    setFadeIn(false);
    setTimeout(() => {
      setActiveIndex(index);
      setFadeIn(true);
    }, 200);
  };

  return (
    <div className="px-6 md:px-24 py-12 text-main bg-white">
      <div className="w-full">
        {/* <div className="w-full mx-auto">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-main mb-2">Noteboard</h1>
            <p className="text-lg text-main/60">Choose a card to explore its contents</p>
          </div>
        </div> */}
        <div className="">
        <h1 className="text-4xl font-bold text-main text-center mb-5">Noteboard</h1>
          <div className="flex flex-wrap items-center justify-center gap-3 md:gap-6">
            {NoteboardList.map((item, index) => (
              <div
                key={index}
                onClick={() => handleCardClick(index)}
                className={`cursor-pointer px-3 py-2 md:px-8 md:py-4 transition-all duration-200 border rounded-lg shadow-md hover:shadow-xl ${activeIndex === index ? 'bg-main text-white' : 'bg-white text-main/60 border-gray-200'
                  } hover:scale-105`}
              >
                <h2 className="text-xl font-semibold">{item.name}</h2>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-10 lg:flex-row items-center lg:items-start w-full h-auto lg:h-fit pt-24">
          <div className="w-full lg:w-[45%] flex items-center justify-center  lg:h-full md:mb-8 lg:mb-0">
            <div className="block w-full">
              <div class="relative mx-auto border-main/60  bg-main/60 border-[10px] rounded-t-xl h-[172px] max-w-[301px] md:h-[304px] md:max-w-[512px]">
                <div class="rounded-lg overflow-hidden h-[166px] md:h-[288px] bg-white">
                  <img src={NoteboardList[activeIndex].url}
                    alt={NoteboardList[activeIndex].name} class="h-[166px] md:h-[288px] w-full rounded-lg" />
                </div>
              </div>
              <div class="relative mx-auto bg-main rounded-b-xl rounded-t-sm h-[17px] max-w-[351px] md:h-[25px] md:max-w-[597px]">
                <div class="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-xl w-[56px] h-[5px] md:w-[96px] md:h-[10px] bg-white/15"></div>
              </div>
            </div>
          </div>
          <div className={`w-full lg:w-[50%] lg:py-12  h-full text-center lg:text-left transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
            <span className="flex items-center mb-4 justify-center md:justify-start gap-5">
              <h1 className="text-2xl font-bold text-main sm:text-3xl">
                {NoteboardList[activeIndex].name}
              </h1>
              {NoteboardList[activeIndex].name == "NoteCard" && <div>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <button
                      className="px-4 py-2 bg-main text-white rounded-lg">
                      Try Now
                    </button>
                  </Dialog.Trigger>
                  <Dialog.Portal>
                    <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
                    <Dialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[90vh] md:h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                      <NoteCardEditor />
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root></div>}
              {NoteboardList[activeIndex].name == "TodoCard" && <div>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <button
                      className="px-4 py-2 bg-main text-white rounded-lg">
                      Try Now
                    </button>
                  </Dialog.Trigger>
                  <Dialog.Portal>
                    <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
                    <Dialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[90vh] md:h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                      <TodoEditor />
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root></div>}
              {NoteboardList[activeIndex].name == "SketchCard" && <div>
                <Dialog.Root>
                  <Dialog.Trigger asChild>
                    <button
                      className="px-4 py-2 bg-main text-white rounded-lg">
                      Try Now
                    </button>
                  </Dialog.Trigger>
                  <Dialog.Portal>
                    <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
                    <Dialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[90vh] md:h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                      <Drawing />
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root></div>}
            </span>
            <p className="mb-4 font-medium text-lg">{NoteboardList[activeIndex].desc}</p>
            <ul className="list-disc text-left list-inside text-white h-full bg-main p-5 rounded-md">
              {NoteboardList[activeIndex].contents.map((content, i) => (
                <li key={i}>{content}</li>
              ))}
            </ul>
          </div>
        </div>
        {/* <div className="">
          <div className="flex flex-wrap items-center justify-center gap-6">
            {NoteboardList.map((item, index) => (
              <div
                key={index}
                onClick={() => handleCardClick(index)}
                className={`cursor-pointer px-8 py-4 transition-all duration-200 border rounded-lg shadow-md hover:shadow-xl ${activeIndex === index ? 'bg-main text-white' : 'bg-white text-main/60 border-gray-200'
                  } hover:scale-105`}
              >
                <h2 className="text-xl font-semibold">{item.name}</h2>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Noteboard;
