import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import { Link } from "react-router-dom";
import { useData } from "../../context/DataContext";

function FreeCard() {
  const [loading, setloading] = useState(false);
  const { user } = useUserAuth();
  const { cards, books } = useData();

  return (
    <div className="absolute bottom-6 left-4 flex w-[235px] py-4 h-fit justify-center items-start">
      {!loading ? (
        <Link
          to={"profile"}
          className=" w-full px-3 dark:bg-main bg-white bord relative rounded-lg py-3 divide-y-2"
        >
          <h1 className="text-lg my-3 font-semibold inline-flex items-center gap-1 capitalize dark:text-white text-main">
            {" "}

            <img
              src={user.photoURL || 'https://xsgames.co/randomusers/assets/avatars/pixel/51.jpg'}
              alt="verified"
              className="w-8 bg-gray-300 rounded-full"
            />
            {" "}
            &nbsp;
            {user?.displayName?.length > 15
              ? `${user.displayName.slice(0, 15)}..`
              : user.displayName}{" "}
          </h1>

          <div className="">
            <span className="flex text-main dark:text-white pt-3 items-center justify-evenly">
              <div className="w-[80px] dark:bg-gray-500 bg-gray-200 flex rounded-full me-3 h-3.5">
                <div
                  className="bg-main dark:bg-white h-3.5 rounded-full"
                  style={{ width: (cards.length / 150) * 100 + '%' }}
                ></div>
              </div>
              {`${cards.length + books.length} / 150 used.`}
            </span>

          </div>
        </Link>
      ) : (
        <div class="dark:bg-main bg-white shadow rounded-3xl p-5 max-w-sm w-full mx-auto">
          <span className="animate-pulse ">
            <div class="flex space-x-4">
              <div class="rounded-full bg-main dark:bg-white h-10 w-10"></div>
              <div class="flex-1 space-y-6 py-1">
                <div class="h-5 bg-main dark:bg-white rounded"></div>
              </div>
            </div>
            <div class="h-4 mt-3 bg-main dark:bg-white rounded"></div>
          </span>
        </div>
      )}
    </div>
  );
}

export default FreeCard;
