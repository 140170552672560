import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD6YKjUbiz5s8M900KwgzSx19bUMjpoRZc",
  authDomain: "scribby-f9e46.firebaseapp.com",
  projectId: "scribby-f9e46",
  storageBucket: "scribby-f9e46.appspot.com",
  messagingSenderId: "762173384393",
  appId: "1:762173384393:web:026fbd3668b5022c6acf18",
  measurementId: "G-DP776TNK2H",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export default app;
