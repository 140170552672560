import React, { useEffect, useState } from "react";
import { FaCompressAlt, FaEdit, FaImage, FaPlus } from "react-icons/fa";
import { RiSearch2Line, RiShapesFill, RiSketching } from "react-icons/ri";
import TodoPreviewCard from '../editData/TodoEditor';
import NoteCardPreviewCard from '../editData/NoteCardEditor';
import DrawingPreviewCard from '../editData/Drawing';
import ImagePreviewCard from '../editData/ImageCard';
import { MdEditSquare, MdOutlinePlayCircleFilled } from "react-icons/md";
import { IoClose, IoCloseSharp } from "react-icons/io5";
import { FaListCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import Card from "./card/Card";
import NoteCardEditor from "../editors/NoteCardEditor";
import TodoEditor from "../editors/TodoEditor";
import Drawing from "../editors/Drawing";
import ImageCard from "../editors/ImageCard";
import { useData } from "../../context/DataContext";
import { ping } from "ldrs";
import CanvaCard from '../editors/CanvaCard';
import { generateHTML, ParseDate } from "../../../common/methods";
import { BsThreeDotsVertical } from "react-icons/bs";
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useUserAuth } from "../../context/UserAuthContext";
import { HiTrash } from "react-icons/hi2";
import toast, { Toaster } from "react-hot-toast";
import CanvaEditor from '../editData/CanvaEditor';

const getRandomColor = () => Quotes[Math.floor(Math.random() * Quotes.length)];

const Quotes = [{
  q: "The journey of a thousand miles begins with one step.",
  a: "Lao Tzu"
},
{
  q: "From small beginnings come great things.",
  a: "Anonymous"
},
{
  q: "Strive not to be a success, but rather to be of value.",
  a: "Albert Einstein"
},
{
  q: "It is never too late to be what you might have been.",
  a: "George Eliot"
},
{
  q: "It's so fine and yet so terrible to stand in front of a blank canvas.",
  a: "Paul Cezanne"
}]


function Noteboard() {
  const [popup, setPopup] = useState({
    note: false,
    todo: false,
    sketch: false,
    image: false,
    canva: false,
  });
  const { user } = useUserAuth();
  const { setboardPreview, boardPreview, setTitle, setTrashCards, cards, setCards, CardsLoading } = useData();
  const [newCardName, setNewCardName] = useState("");
  const [Share, setShare] = useState({});
  const [isRenameOpen, setisRenameOpen] = useState(false);
  const [isRenaming, setisRenaming] = useState(false);
  const [isDeleteOpen, setisDeleteOpen] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  const [isBoardEditing, setisBoardEditing] = useState(false);
  const [isShareOpen, setisShareOpen] = useState(false);
  const [isSharing, setisSharing] = useState(false);
  const [quote, setQuote] = useState(getRandomColor);

  useEffect(() => {
    setTitle("Your NoteBoard");
    ping.register();

    return () => setTitle("");
  }, []);


  const openPopup = (type) => setPopup({ ...popup, [type]: true });
  const closePopup = (type) => setPopup({ ...popup, [type]: false });
  const isNotEmptyObject = (obj) => Object.keys(obj).length > 0;

  const handleCardRename = async (card) => {
    if (newCardName.length > 0 && newCardName !== card.name) {
      try {
        setisRenaming(true);
        const userDocQuery = query(
          collection(db, "notecard"),
          where("ncid", "==", card.ncid)
        );

        const querySnapshot = await getDocs(userDocQuery);

        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
          const updatedTime = new Date();

          await updateDoc(docRef, {
            name: newCardName,
            updatedAt: updatedTime,
          });

          setCards((prevCards) => {
            const updatedCards = prevCards.map((c) =>
              c.ncid === card.ncid
                ? { ...c, name: newCardName, updatedAt: updatedTime }
                : c
            );

            return updatedCards.sort((a, b) => {
              const dateA = a.updatedAt?.toDate ? a.updatedAt.toDate() : a.updatedAt;
              const dateB = b.updatedAt?.toDate ? b.updatedAt.toDate() : b.updatedAt;

              return dateB - dateA;
            });
          });

          setboardPreview({});
          setNewCardName("");
          setisRenameOpen(false);
          toast.success("Renamed Successfully")
        } else {
          console.log("No matching document found.");
        }
      } catch (error) {
        console.error("Error renaming card:", error);
        setisRenameOpen(true);
      }
      finally {
        setisRenaming(false);
      }
    }
  };

  const SortData = () => {
    setCards(cards => cards.sort((a, b) => {
      const dateA = a.updatedAt?.toDate ? a.updatedAt.toDate() : a.updatedAt;
      const dateB = b.updatedAt?.toDate ? b.updatedAt.toDate() : b.updatedAt;

      return dateB - dateA;
    }))
  }

  const HandleShare = async (e) => {
    e.preventDefault();

    if (Share.email !== user.email) {
      try {
        const card = Share.obj;
        setisSharing(true);

        const userDocQuery = query(
          collection(db, "notecard"),
          where("ncid", "==", card.ncid)
        );

        const querySnapshot = await getDocs(userDocQuery);

        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;

          if (!card.shared.includes(Share.email)) {
            const updatedArray = [...card.shared, Share.email];

            await updateDoc(docRef, {
              shared: updatedArray,
            });

            setCards((prevCards) => {
              const updatedCards = prevCards.map((c) =>
                c.ncid === card.ncid
                  ? {
                    ...c,
                    shared: updatedArray,
                  }
                  : c
              );

              return updatedCards.sort((a, b) => {
                const dateA = a.updatedAt?.toDate
                  ? a.updatedAt.toDate()
                  : a.updatedAt;
                const dateB = b.updatedAt?.toDate
                  ? b.updatedAt.toDate()
                  : b.updatedAt;

                return dateB - dateA;
              });
            });
          } else {
            toast.error(`${card.type[0].toUpperCase() + card.type.slice(1)} Card is already Shared to ${Share.email}`);
          }

          setboardPreview({});
          setShare({});
          setisShareOpen(false);
          toast.success(`${card.type[0].toUpperCase() + card.type.slice(1)} Card is Shared successfully`)
        } else {
          console.log("No matching document found.");
        }
      } catch (error) {
        console.error("Error sharing card:", error);
      } finally {
        setisSharing(false);
      }
    } else {
      toast.error("Can't share with the same email address");
    }
  };

  const HandleDelete = async (card) => {
    try {
      setisDeleting(true);
      const updatedCard = { ...card, updatedAt: new Date() };
      const cardQuery = query(
        collection(db, "notecard"),
        where("ncid", "==", card.ncid)
      );
      const querySnapshot = await getDocs(cardQuery);
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await addDoc(collection(db, "notecardtrash"), updatedCard);
        await deleteDoc(docRef);
        setCards((prevCards) => prevCards.filter((ele) => ele.ncid !== card.ncid));
        setTrashCards((prevTrash) => [updatedCard, ...prevTrash]);
        setboardPreview({});
        setisDeleteOpen(false);
      } else {
        console.error("Error: Card not found.");
      }
    } catch (error) {
      console.error("Error deleting card:", error);
    }
    finally {
      setisDeleting(false);
    }
  };

  const Settings = (card) => (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button className="bg-gray-100 text-gray-800 p-2 rounded-md">
          <BsThreeDotsVertical />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={5}
          side="left"
          className="mt-[6.5rem] md:mt-[9.4rem] rounded-md p-2 flex flex-col border border-gray-300 bg-white shadow-md w-48"
        >
          <button onClick={() => { setisEditing(true) }} className="ps-3 py-2 hidden md:flex w-full text-start text-gray-700 hover:bg-gray-100 rounded-md">
            Edit
          </button>
          <AlertDialog.Root open={isEditing}>
            <AlertDialog.Portal>
              <AlertDialog.Overlay className="bg-main bg-opacity-70 data-[state=open]:animate-overlayShow fixed inset-0" />
              <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                <button
                  onClick={() => { SortData(); setisEditing(false); }}
                  className="fixed top-1 rounded-md p-1 right-1"
                >
                  <IoCloseSharp className="text-xl rounded-full text-white" />
                </button>

                {card.type == "image" && <ImagePreviewCard setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={card} />}
                {card.type == "sketch" && <DrawingPreviewCard setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={card} />}
                {card.type == "todo" && <TodoPreviewCard SortData={SortData} setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={card} />}
                {card.type == "note" && <NoteCardPreviewCard SortData={SortData} setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={card} />}
                {card.type == "canva" && <CanvaEditor SortData={SortData} setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={card} />}
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog.Root>

          <button onClick={() => { setisRenameOpen(true) }} className="ps-3 py-2 w-full text-start text-gray-700 hover:bg-gray-100 rounded-md">
            Rename
          </button>
          <AlertDialog.Root open={isRenameOpen}>
            <AlertDialog.Portal>
              <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
              <AlertDialog.Content className=" fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[400px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-lg focus:outline-none">
                <AlertDialog.Title className="text-gray-800 text-lg font-semibold">
                  Rename
                </AlertDialog.Title>
                <div className="my-4">
                  <input
                    type="text"
                    defaultValue={card.name}
                    onChange={(e) => setNewCardName(e.target.value)}
                    placeholder="Enter new name"
                    className="w-full border border-gray-300 focus:outline-none shadow-sm rounded-md p-2"
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <button onClick={() => setisRenameOpen(false)} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300">
                    Cancel
                  </button>
                  <button
                    onClick={() => handleCardRename(card)}
                    disabled={isRenaming}
                    className={`text-white bg-main hover:bg-main/80 disabled:bg-gray-400 px-4 py-2 rounded-md`}
                  >
                    Rename
                  </button>
                </div>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog.Root>

          <button onClick={() => { setisShareOpen(true) }} className="ps-3 py-2 w-full text-start text-gray-700 hover:bg-gray-100 rounded-md">
            Share
          </button>
          <AlertDialog.Root open={isShareOpen}>
            <AlertDialog.Portal>
              <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
              <AlertDialog.Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[900px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white shadow-md focus:outline-none">
                <div className="md:flex">
                  <div className="md:w-2/5 bg-cover hidden rounded-s-md md:block" style={{ backgroundImage: "url('https://ik.imagekit.io/vituepzjm/business-card-mockup-designs_1263741-10414.jpg?updatedAt=1724522878953')" }}></div>
                  <div className="md:w-3/5 md:border-t-0 rounded-t-md border-t-8 border-main w-full p-6">
                    <div className="flex justify-end">
                      <button onClick={() => { setisShareOpen(false) }} ><IoClose className="text-xl text-gray-700" /></button>
                    </div>
                    <div className="pt-4 pb-12">
                      <h1 className="text-xl md:text-2xl font-semibold mb-2 text-gray-800">Share Your {card.type[0].toUpperCase() + card.type.slice(1)} Card</h1>
                      <p className="text-gray-500 mb-4 font-medium">You can share your {card.type} card with others, so they can access and edit it from their shared space.</p>
                      <form onSubmit={HandleShare} className="relative mt-6 mb-4">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg className="w-4 h-4 text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                          </svg>
                        </div>
                        <input type="email" onChange={(e) => setShare({ email: e.target.value, obj: card })} className="placeholder-gray-500 border border-gray-300 text-gray-700 text-sm rounded-lg focus:outline-none block w-full pl-10 p-2.5" placeholder="example@gmail.com" required />
                      </form>

                      <p className="text-sm ps-2 text-gray-600 font-medium">* Please enter the email address of the recipient to share this {card.type} card.</p>
                    </div>
                  </div>
                </div>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog.Root>

          <button onClick={() => setisDeleteOpen(true)} className="ps-3 py-2 w-full text-start text-red-600 hover:bg-red-50 rounded-md">Move to Trash</button>
          <AlertDialog.Root open={isDeleteOpen} >
            <AlertDialog.Portal>
              <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
              <AlertDialog.Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-md focus:outline-none">
                <AlertDialog.Title className="text-red-600 text-xl font-semibold">
                  Are you absolutely sure?
                </AlertDialog.Title>
                <AlertDialog.Description className="text-gray-600 font-medium mt-2 mb-6">
                  This action cannot be undone. This will move <span className="font-bold text-main">{`${card.name} - ${card.type[0].toUpperCase() + card.type.slice(1) + ' Card'}`}</span> to Trash page you can restore at anytime.
                </AlertDialog.Description>
                <div className="flex justify-start gap-3">
                  <button disabled={isDeleting} onClick={() => HandleDelete(card)} className="px-4 py-2 bg-red-600 disabled:bg-red-400 text-white rounded-md hover:bg-red-700">
                    Move to Trash
                  </button>
                  <button onClick={() => setisDeleteOpen(false)} className="px-4 py-2 bg-main/10 text-main/90 rounded-md hover:bg-main/20">
                    Cancel
                  </button>
                </div>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog.Root>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );

  return (
    <div className="flex w-full h-full gap-3">
      <div className="bg-white dark:bg-main h-full overflow-auto rounded-md shadow-md p-3 w-full border border-gray-300">
        <div className="flex items-center mt-2 mb-10 justify-between gap-5">
          <div className="ms-3 hidden lg:flex">
            <ul className="flex items-center gap-3">
              <li>
                <Link
                  to="/dashboard"
                  className="flex items-center gap-2 cursor-pointer group"
                >
                  <span className="font-medium text-base hover:underline underline-offset-2 leading-7 text-main/60 dark:text-white">
                    Home
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/noteboard"
                  className="flex items-center gap-2 cursor-pointer group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5"
                    height="20"
                    viewBox="0 0 5 20"
                    fill="none"
                  >
                    <path
                      d="M4.12567 1.13672L1 18.8633"
                      className="stroke-[#1a1a1a60] dark:stroke-white"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="font-semibold hover:underline underline-offset-2 text-base leading-7 text-main dark:text-white">
                    NoteBoard
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/noteboard/search"
                  class="flex items-center gap-2 cursor-pointer group"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5"
                    height="20"
                    viewBox="0 0 5 20"
                    fill="none"
                  >
                    <path
                      d="M4.12567 1.13672L1 18.8633"
                      className="stroke-[#1a1a1a60] dark:stroke-white"
                      stroke-width="1.6"
                      stroke-linecap="round"
                    />
                  </svg>
                  <span class="font-medium hover:underline underline-offset-2 text-base leading-7 text-main/60 dark:text-white">
                    Search
                  </span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="inline-flex justify-between w-full lg:w-auto lg:justify-normal items-center gap-5">
            <Link
              to="/dashboard/noteboard/search"
              className="text-main border border-main/20 dark:border-white inline-flex gap-2 items-center justify-start bg-main/10 dark:bg-white rounded-lg px-4 py-1.5 ms-2 w-[200px]"
            >
              <RiSearch2Line /> Search
            </Link>
            <span className=" dark:bg-main" >
              <Popover.Root>
                <Popover.Trigger asChild>
                  <button className="bg-main dark:bg-white text-white dark:text-main p-2 me-2 rounded-lg">
                    <FaPlus />
                  </button>
                </Popover.Trigger>
                <Popover.Portal >
                  <Popover.Content sideOffset={10} side="bottom" className="me-4 rounded-lg p-3  bg-white w-fit border border-main/20 shadow-lg">
                    <div className="mb-3 ms-1 font-semibold text-xl">Add a New Card</div>
                    <div className="grid place-items-center grid-cols-2 gap-3">
                      <ActionButton label="NoteCard" icon={MdEditSquare} onClick={() => openPopup("note")} />
                      <ActionButton label="TodoCard" icon={FaListCheck} onClick={() => openPopup("todo")} />
                      <ActionButton label="SketchCard" icon={RiSketching} onClick={() => openPopup("sketch")} />
                      <ActionButton label="ImageCard" icon={FaImage} onClick={() => openPopup("image")} />
                      {/* <ActionButton label="CanvaCard" icon={RiShapesFill} onClick={() => openPopup("canva")} /> */}
                      {/* <ActionButton label="AudioCard" icon={AiFillAudio} onClick={() => openPopup("audio")} /> */}
                    </div>
                    <Popover.Arrow className="fill-main/30 " />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
              <Link to="trash">
                <button className="bg-main dark:bg-white text-white dark:text-main p-2 rounded-lg">
                  <HiTrash />
                </button>
              </Link>
            </span>
          </div>
        </div>

        {CardsLoading ?
          <div className="flex w-full h-[70vh] items-center justify-center text-main">
            <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
          </div>
          :
          <div>
            {cards.length == 0 ?
              <div className="flex items-center justify-center w-full h-[70vh]">
                {quote && <div className="flex flex-col items-center justify-center">
                  <h1 className="md:text-3xl font-serif italic text-center break-words text-2xl mb-2 text-main/30 dark:text-white">{quote.q}</h1>
                  <p className="text-main/20 text-base italic tracking-widest font-serif md:text-lg dark:text-white">- {quote.a}</p>
                </div>}
              </div> :
              <div className={`grid gap-5 w-full md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-2 ${isNotEmptyObject(boardPreview) ? "xl:grid-cols-2 " : "xl:grid-cols-3"}`}>
                {cards.map((card, index) => (
                  <div key={index} >
                    {card.type == "image" && <div
                      style={{ backgroundColor: card.color }} className="relative bg-white border hidden md:block shadow-sm rounded-md">
                      <img onClick={() => {
                        const selfCheck = boardPreview?.ncid === card.ncid;
                        setboardPreview(selfCheck ? {} : card);
                      }} className="w-full cursor-pointer h-[300px] brightness-95 object-cover rounded-md" src={card.data} alt={card.name} />
                      <div className="absolute top-3 start-3">
                        <h3 className="text-main px-2 py-1 bg-white rounded-md w-fit">
                          {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                        </h3>
                      </div>
                      <div className="absolute top-3 end-3">
                        {Settings(card)}
                      </div>
                      <div className="absolute bottom-3 start-3">
                        <h3 class="text-main px-2 text-sm py-1 bg-white rounded-md w-fit">
                          {ParseDate(card.updatedAt)}
                        </h3>
                      </div>
                    </div>}
                    {card.type === "note" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px]  p-3 w-full hidden md:block border rounded-md shadow-sm"
                      >
                        <div className="w-full flex items-center justify-between">
                          <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          {Settings(card)}
                        </div>

                        <div onClick={() => {
                          const selfCheck = boardPreview?.ncid === card.ncid;
                          setboardPreview(selfCheck ? {} : card);
                        }} className="h-[200px] overflow-y-auto cursor-pointer my-2 mx-2">
                          <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                        </div>

                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}
                    {card.type === "todo" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px] p-3 w-full border hidden md:block rounded-md shadow-sm"
                      >
                        <div className="w-full flex items-center justify-between">
                          <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          {Settings(card)}
                        </div>

                        <div onClick={() => {
                          const selfCheck = boardPreview?.ncid === card.ncid;
                          setboardPreview(selfCheck ? {} : card);
                        }} className="h-[200px] overflow-y-auto cursor-pointer my-2 mx-2">
                          <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                        </div>

                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}
                    {card.type === "canva" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px] p-3 w-full border hidden md:block rounded-md shadow-sm"
                      >
                        <div className="w-full flex items-center justify-between">
                          <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          {Settings(card)}
                        </div>

                        <div onClick={() => {
                          const selfCheck = boardPreview?.ncid === card.ncid;
                          setboardPreview(selfCheck ? {} : card);
                        }} className="h-[200px] overflow-y-auto cursor-pointer my-2 mx-2">
                        </div>

                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}
                    {card.type === "sketch" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px] p-3 w-full border hidden md:block rounded-md shadow-sm"
                      >
                        <div className="flex w-full items-center justify-between" >
                          <div className="w-fit top-3 left-3 px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          <div className=" top-3 end-3">
                            {Settings(card)}
                          </div>
                        </div>

                        <div onClick={() => {
                          const selfCheck = boardPreview?.ncid === card.ncid;
                          setboardPreview(selfCheck ? {} : card);
                        }} className="cursor-pointer z-[100] my-2">
                          <div className="w-full h-[200px]" dangerouslySetInnerHTML={{ __html: card?.data }} />
                        </div>


                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}

                    {card.type == "image" && <div
                      style={{ backgroundColor: card.color }} className="relative bg-white border md:hidden shadow-sm rounded-md">
                      <img onClick={() => {
                        setboardPreview(card);
                        setisBoardEditing(true);
                        SortData();
                      }} className="w-full cursor-pointer h-[300px] object-cover brightness-95 rounded-md" src={card.data} alt={card.name} />
                      <div className="absolute top-3 start-3">
                        <h3 className="text-main px-2 py-1 bg-white rounded-md w-fit">
                          {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                        </h3>
                      </div>
                      <div className="absolute top-3 end-3">
                        {Settings(card)}
                      </div>
                      <div className="absolute bottom-3 start-3">
                        <h3 class="text-main px-2 text-sm py-1 bg-white rounded-md w-fit">
                          {ParseDate(card.updatedAt)}
                        </h3>
                      </div>
                    </div>}
                    {card.type === "note" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px]  p-3 w-full border md:hidden rounded-md shadow-sm"
                      >
                        <div className="w-full flex items-center justify-between">
                          <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          {Settings(card)}
                        </div>

                        <div onClick={() => {
                          setboardPreview(card);
                          setisBoardEditing(true);
                          SortData();
                        }} className="h-[200px] overflow-y-auto cursor-pointer my-2 mx-2">
                          <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                        </div>

                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}
                    {card.type === "todo" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px]  p-3 w-full border md:hidden rounded-md shadow-sm"
                      >
                        <div className="w-full flex items-center justify-between">
                          <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          {Settings(card)}
                        </div>

                        <div onClick={() => {
                          setboardPreview(card);
                          setisBoardEditing(true);
                          SortData();
                        }} className="h-[200px] overflow-y-auto cursor-pointer my-2 mx-2">
                          <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                        </div>

                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}
                    {card.type === "canva" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px]  p-3 w-full border md:hidden rounded-md shadow-sm"
                      >
                        <div className="w-full flex items-center justify-between">
                          <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          {Settings(card)}
                        </div>

                        <div onClick={() => {
                          setboardPreview(card);
                          setisBoardEditing(true);
                          SortData();
                        }} className="h-[200px] overflow-y-auto cursor-pointer my-2 mx-2"></div>

                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}
                    {card.type === "sketch" && (
                      <div
                        style={{ backgroundColor: card.color }}
                        className="h-[300px] p-3 w-full border md:hidden rounded-md shadow-sm"
                      >
                        <div className="flex w-full items-center justify-between" >
                          <div className="w-fit top-3 left-3 px-2 py-1 rounded-md bg-white text-main">
                            {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                          </div>
                          <div className=" top-3 end-3">
                            {Settings(card)}
                          </div>
                        </div>

                        <div onClick={() => {
                          setboardPreview(card);
                          setisBoardEditing(true);
                          SortData();
                        }} className="cursor-pointer z-[100] my-2">
                          <div className="w-full h-[200px]" dangerouslySetInnerHTML={{ __html: card?.data }} />
                        </div>


                        <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                          {ParseDate(card.updatedAt)}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            }
          </div>}
      </div>

      {isNotEmptyObject(boardPreview) && (
        <div className="bg-white rounded-md shadow-md xl:block hidden w-[430px] border border-gray-300">
          <div className="flex p-3 rounded-t-md items-center justify-between bg-main text-white">
            <p className="text-lg font-medium capitalize">{boardPreview.type + 'Card'}</p>
            <span className="inline-flex items-center gap-2">
              <button onClick={() => { setisBoardEditing(true) }} >
                <FaEdit />
              </button>

              <AlertDialog.Root open={isBoardEditing}>
                <AlertDialog.Portal>
                  <AlertDialog.Overlay className="bg-main bg-opacity-70 data-[state=open]:animate-overlayShow fixed inset-0" />
                  <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                    <button
                      onClick={() => { SortData(); setisBoardEditing(false); }}
                      className="fixed top-1 rounded-md p-1 right-1"
                    >
                      <IoCloseSharp className="text-xl rounded-full text-white" />
                    </button>

                    {boardPreview.type == "image" && <ImagePreviewCard setisEditing={setisBoardEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={boardPreview} />}
                    {boardPreview.type == "sketch" && <DrawingPreviewCard setisEditing={setisBoardEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={boardPreview} />}
                    {boardPreview.type == "todo" && <TodoPreviewCard SortData={SortData} setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={boardPreview} />}
                    {boardPreview.type == "note" && <NoteCardPreviewCard SortData={SortData} setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={boardPreview} />}
                    {boardPreview.type == "canva" && <CanvaEditor SortData={SortData} setisEditing={setisEditing} HandleDelete={HandleDelete} isDeleting={isDeleting} item={boardPreview} />}
                  </AlertDialog.Content>
                </AlertDialog.Portal>
              </AlertDialog.Root>
              <button
                onClick={() => setboardPreview({})}
                className="text-white active:scale-90 p-2 rounded-lg transition-all ease-linear"
              >
                <FaCompressAlt />
              </button>
            </span>
          </div>
          <div className="p-3 overflow-y-auto h-[75vh]">
            {isNotEmptyObject(boardPreview) ? <Card item={boardPreview} /> : <div>No Card Selected</div>}
          </div>
        </div>
      )}


      <CardPopup open={popup.note} onClose={() => closePopup("note")}>
        <NoteCardEditor setImagePopup={setPopup} />
      </CardPopup>
      <CardPopup open={popup.todo} onClose={() => closePopup("todo")}>
        <TodoEditor setImagePopup={setPopup} />
      </CardPopup>
      <CardPopup open={popup.sketch} onClose={() => closePopup("sketch")}>
        <Drawing setImagePopup={setPopup} />
      </CardPopup>
      <CardPopup open={popup.image} onClose={() => closePopup("image")}>
        <ImageCard setImagePopup={setPopup} />
      </CardPopup>
      <CardPopup open={popup.canva} onClose={() => closePopup("canva")}>
        <CanvaCard setImagePopup={setPopup} />
      </CardPopup>
      {/* <CardPopup open={popup.audio} onClose={() => closePopup("audio")}>
        <AudioCard className="mt-5" setImagePopup={setPopup} />
      </CardPopup> */}

      <Toaster />

    </div >
  );
}

const ActionButton = ({ label, icon: Icon, onClick }) => (
  <button
    onClick={onClick}
    className="w-full inline-flex items-center gap-2 justify-start px-4 py-3 text-sm bg-main rounded-md text-white focus:outline-none"
    role="menuitem"
  >
    <Icon /> {label}
  </button>
);

const CardPopup = ({ open, onClose, children }) => (
  <AlertDialog.Root open={open}>
    <AlertDialog.Portal>
      <AlertDialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
      <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
        <button
          onClick={onClose}
          className="fixed top-1 rounded-md bg-main bg-opacity-40 p-1 right-1"
        >
          <IoCloseSharp className="text-xl rounded-full text-white" />
        </button>
        {children}
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
);

export default Noteboard;
