import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from "../../config/firebase";
import { useUserAuth } from "./UserAuthContext";

const DataContext = createContext();

export function DataContextProvider({ children }) {
  const [cards, setCards] = useState([]);
  const { user } = useUserAuth();
  const [Sharedcards, setSharedcards] = useState([]);
  const [SharedcardsLoading, setSharedcardsLoading] = useState(false);
  const [Sharedbooks, setSharedbooks] = useState([]);
  const [SharedbooksLoading, setSharedbooksLoading] = useState(false);
  const [Trashcards, setTrashCards] = useState([]);
  const [TrashcardsLoading, setTrashCardsLoading] = useState(false);
  const [Trashbooks, setTrashBooks] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [Aidata, setAidata] = useState([]);
  const [TrashbooksLoading, setTrashBooksLoading] = useState(false);
  const [CardsLoading, setCardsLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [BooksLoading, setBooksLoading] = useState(false);
  const [bookPreview, setbookPreview] = useState({});
  const [Preview, setPreview] = useState({});
  const [previewCardTrash, setpreviewCardTrash] = useState({});
  const [previewBookTrash, setpreviewBookTrash] = useState({});
  const [previewSharedCard, setpreviewSharedCard] = useState({});
  const [previewSharedBook, setpreviewSharedBook] = useState({});
  const [Local, setLocal] = useState({});
  const [boardPreview, setboardPreview] = useState({});
  const [widgets, setWidgets] = useState([]);
  const [title, setTitle] = useState("");
  const [isSyncing, setisSyncing] = useState(false);

  const getWidgets = () => {
    try {
      const storedWidgets = window.localStorage.getItem("widgets");
      if (storedWidgets) {
        setWidgets(JSON.parse(storedWidgets));
      } else {
        setWidgets([
          {
            name: "Banner",
            desc: "Banner Component displays your name and a Overview.",
            icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M131.79,69.65l-43.63,96A4,4,0,0,1,84.52,168H28.23a8.2,8.2,0,0,1-6.58-3.13,8,8,0,0,1,.43-10.25L57.19,116,22.08,77.38a8,8,0,0,1-.43-10.26A8.22,8.22,0,0,1,28.23,64h99.92A4,4,0,0,1,131.79,69.65ZM237.56,42.24A8.3,8.3,0,0,0,231.77,40H168a8,8,0,0,0-7.28,4.69l-42.57,93.65a4,4,0,0,0,3.64,5.66h57.79l-34.86,76.69a8,8,0,1,0,14.56,6.62l80-176A8,8,0,0,0,237.56,42.24Z"></path></svg>`,
          },
          {
            name: "Recent Cards",
            desc: "Card Component displays recently created Cards from Noteboard.",
            icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M408 16H104a24 24 0 0 0-24 24v432a24 24 0 0 0 24 24h304a24 24 0 0 0 24-24V40a24 24 0 0 0-24-24zm-61.1 296.77a43 43 0 1 1-40.71-40.71 43 43 0 0 1 40.71 40.71zM192 64h128v32H192zm192 384H224v-24.6c0-32.72 53.27-49.21 80-49.21s80 16.49 80 49.21z"></path></svg>`,
          },
          {
            name: "NoteCard Counts",
            desc: "Displays the total number of note cards that have been created.",
            icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512"  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z"></path></svg>`,
          },
          {
            name: "Calendar & Time",
            desc: "Provides a calendar view with the current date and time.",
            icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM2.545 3h10.91c.3 0 .545.224.545.5v1c0 .276-.244.5-.546.5H2.545C2.245 5 2 4.776 2 4.5v-1c0-.276.244-.5.545-.5"></path></svg>`,
          },
        ]);
      }
    } catch (error) {
      console.error(error);
      setWidgets([
        {
          name: "Banner",
          desc: "Banner Component displays your name and a Overview.",
          icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M131.79,69.65l-43.63,96A4,4,0,0,1,84.52,168H28.23a8.2,8.2,0,0,1-6.58-3.13,8,8,0,0,1,.43-10.25L57.19,116,22.08,77.38a8,8,0,0,1-.43-10.26A8.22,8.22,0,0,1,28.23,64h99.92A4,4,0,0,1,131.79,69.65ZM237.56,42.24A8.3,8.3,0,0,0,231.77,40H168a8,8,0,0,0-7.28,4.69l-42.57,93.65a4,4,0,0,0,3.64,5.66h57.79l-34.86,76.69a8,8,0,1,0,14.56,6.62l80-176A8,8,0,0,0,237.56,42.24Z"></path></svg>`,
        },
        {
          name: "Recent Cards",
          desc: "Card Component displays recently created Cards from Noteboard.",
          icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M408 16H104a24 24 0 0 0-24 24v432a24 24 0 0 0 24 24h304a24 24 0 0 0 24-24V40a24 24 0 0 0-24-24zm-61.1 296.77a43 43 0 1 1-40.71-40.71 43 43 0 0 1 40.71 40.71zM192 64h128v32H192zm192 384H224v-24.6c0-32.72 53.27-49.21 80-49.21s80 16.49 80 49.21z"></path></svg>`,
        },
        {
          name: "NoteCard Counts",
          desc: "Displays the total number of note cards that have been created.",
          icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512"  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z"></path></svg>`,
        },
        {
          name: "Calendar & Time",
          desc: "Provides a calendar view with the current date and time.",
          icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM2.545 3h10.91c.3 0 .545.224.545.5v1c0 .276-.244.5-.546.5H2.545C2.245 5 2 4.776 2 4.5v-1c0-.276.244-.5.545-.5"></path></svg>`,
        },
      ]);
    }
  };

  useEffect(() => {
    getWidgets();
  }, []);

  const SyncData = () => {
    try {
      setisSyncing(true);
      getCards();
      getBooks();
      getCardTrash();
      getBookTrash();
      fetchSharedItems("notecard", setSharedcards, setSharedcardsLoading);
      fetchSharedItems("notebooks", setSharedbooks, setSharedbooksLoading);
    } catch (e) {
      console.log(e);
    } finally {
      setisSyncing(false);
    }
  };

  const OnlineSync = () => {
    try {
      setisSyncing(true);
      getCards();
      getBooks();
      getCardTrash();
      getBookTrash();
      fetchSharedItems("notecard", setSharedcards, setSharedcardsLoading);
      fetchSharedItems("notebooks", setSharedbooks, setSharedbooksLoading);
    } catch (e) {
      console.log(e);
    } finally {
      setisSyncing(false);
    }
  };

  const getCards = async () => {
    try {
      setCardsLoading(true);
      const cardsQuery = query(
        collection(db, "notecard"),
        where("uid", "==", user.uid),
        orderBy("updatedAt", "desc")
      );

      const cardsSnapshot = await getDocs(cardsQuery);

      if (!cardsSnapshot.empty) {
        const cardsData = cardsSnapshot.docs.map((doc) => doc.data());
        setCards(cardsData);
        console.log(cardsData);
        setCardsLoading(false);
      } else {
        setCards([]);
        setCardsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
      setCardsLoading(false);
    }
  };

  const getBooks = async () => {
    try {
      setBooksLoading(true);
      const cardsQuery = query(
        collection(db, "notebooks"),
        where("uid", "==", user.uid),
        orderBy("updatedAt", "desc")
      );

      const cardsSnapshot = await getDocs(cardsQuery);

      if (!cardsSnapshot.empty) {
        const booksData = cardsSnapshot.docs.map((doc) => doc.data());
        setBooks(booksData);
        setBooksLoading(false);
      } else {
        setBooks([]);
        setBooksLoading(false);
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
      setBooksLoading(false);
    }
  };

  const getCardTrash = async () => {
    try {
      setTrashCardsLoading(true);
      const cardsQuery = query(
        collection(db, "notecardtrash"),
        where("uid", "==", user.uid),
        orderBy("updatedAt", "desc")
      );

      const cardsSnapshot = await getDocs(cardsQuery);

      if (!cardsSnapshot.empty) {
        const cardsData = cardsSnapshot.docs.map((doc) => doc.data());
        setTrashCards(cardsData);
        console.log(cardsData);
      } else {
        setTrashCards([]);
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
    } finally {
      setTrashCardsLoading(false);
    }
  };

  const getBookTrash = async () => {
    try {
      setTrashBooksLoading(true);
      const cardsQuery = query(
        collection(db, "notebooktrash"),
        where("uid", "==", user.uid),
        orderBy("updatedAt", "desc")
      );

      const cardsSnapshot = await getDocs(cardsQuery);

      if (!cardsSnapshot.empty) {
        const cardsData = cardsSnapshot.docs.map((doc) => doc.data());
        setTrashBooks(cardsData);
        console.log(cardsData);
      } else {
        setTrashBooks([]);
      }
    } catch (error) {
      console.error("Error fetching cards:", error);
    } finally {
      setTrashBooksLoading(false);
    }
  };

  const fetchSharedItems = async (collectionName, setItems, setLoading) => {
    setLoading(true);
    try {
      const itemsQuery = query(
        collection(db, collectionName),
        where("shared", "array-contains", user.email),
        orderBy("updatedAt", "desc")
      );
      const itemsSnapshot = await getDocs(itemsQuery);

      if (!itemsSnapshot.empty) {
        const itemsData = itemsSnapshot.docs.map((doc) => doc.data());
        setItems(itemsData);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error(`Error fetching shared ${collectionName}:`, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataContext.Provider
      value={{
        bookPreview,
        setbookPreview,
        boardPreview,
        setboardPreview,
        widgets,
        setWidgets,
        setTitle,
        title,
        cards,
        setCards,
        books,
        setBooks,
        setCardsLoading,
        CardsLoading,
        setBooksLoading,
        BooksLoading,
        Local,
        setLocal,
        Trashcards,
        setTrashCards,
        TrashcardsLoading,
        setTrashCardsLoading,
        setpreviewCardTrash,
        previewCardTrash,
        setPreview,
        Preview,
        setSharedcards,
        Sharedcards,
        setSharedcardsLoading,
        SharedcardsLoading,
        previewSharedCard,
        setpreviewSharedCard,
        Sharedbooks,
        setSharedbooks,
        SharedbooksLoading,
        setSharedbooksLoading,
        setTrashBooks,
        Trashbooks,
        setTrashBooksLoading,
        TrashbooksLoading,
        setpreviewSharedBook,
        previewSharedBook,
        isSyncing,
        SyncData,
        getCards,
        getBooks,
        getCardTrash,
        getBookTrash,
        fetchSharedItems,
        OnlineSync,
        setpreviewBookTrash,
        previewBookTrash,
        setConversation,
        conversation,
        Aidata,
        setAidata,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useData() {
  return useContext(DataContext);
}
