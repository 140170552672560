import React from 'react'
import { useUserAuth } from '../../context/UserAuthContext';
import { Link } from 'react-router-dom';

function Links() {
    const { user } = useUserAuth();
    return (
        <section class="text-gray-300 bg-main">
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-col text-center items-center justify-center w-full mb-12">
                    <h1 className="px-8 py-3 mb-5 bg-white  rounded-full text-sm font-bold text-main">Join With Us </h1>
                    <h1 class="sm:text-4xl text-2xl lg:font-semibold font-bold title-font mb-4 text-white break-words">Try it now for an efficient and Collabrative experience!</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed lg:text-xl">Unlock The future of work is seamless and connected. Linked notes are key to unlocking a more efficient, productive, and collaborative workflow.</p>
                    {user ? <Link to='/dashboard' class="flex mt-10 justify-center  items-center bg-white ps-5 pe-3 py-3 rounded-md  gap-5">
                        <span class="text-main font-semibold">Explore More</span>
                        <i className="fas fa-arrow-right bg-main text-white p-2 rounded-md"></i>
                    </Link> : <Link to='/login' class="flex mt-10 justify-center  items-center bg-white ps-5 pe-3 py-3 rounded-md  gap-5">
                        <span class="text-main font-semibold">Get Started Now</span>
                        <i className="fas fa-arrow-right bg-main text-white p-2 rounded-md"></i>
                    </Link>}
                </div>
            </div>
        </section>
    )
}

export default Links