import React from 'react'
import { Link } from 'react-router-dom'

function CTA2() {
  return (
    <section class="text-main/60 body-font">
      <div class="container mx-auto flex lg:px-24 px-5 py-12 md:py-24 md:flex-row flex-col-reverse items-center">
        <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="px-8 py-3 bg-main/5 text-sm rounded-md mb-5 font-bold text-main">NOTE BOARD</h1>
          <h1 class="title-font sm:text-5xl text-3xl capitalize mb-4 font-bold text-main">
            Manage Different Cards
            <br class="hidden lg:inline-block" /> with Scribby
          </h1>
          <p class="mb-8 leading-relaxed w-[80%]">Different types of notes should be treated differently. Each Card type is designed to complement the type of note you're taking.</p>
          <Link to='/about' class="flex justify-center items-center bg-main ps-5 pe-3 py-3 rounded-md gap-5">
            <span class="text-white">Learn More</span>
            <i className="fas fa-arrow-right text-main bg-white p-2 rounded-md"></i>
          </Link>
        </div>
        <div class=" lg:max-w-lg lg:w-full md:w-1/2 w-5/6"> <img class="object-cover lg:-ml-24 object-center rounded" alt="hero" src="https://ik.imagekit.io/vituepzjm/noteboard.svg?updatedAt=1725464738970" />

        </div>
      </div>
    </section>
  )
}

export default CTA2