import React, { useState } from "react";
import { useLocation } from "react-router";
import { useUserAuth } from "../../context/UserAuthContext";
import * as Popover from "@radix-ui/react-popover";
import { Link } from "react-router-dom";
import { useData } from "../../context/DataContext";
import { TbReload } from "react-icons/tb";
import * as Dialog from "@radix-ui/react-dialog";
import Darkmodebtn from '../../common/Darkmodebtn';
import { FaUsers } from "react-icons/fa";
import { links } from "../../../common/links";

function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { user, logOut } = useUserAuth();
  const { title, SyncData, isSyncing, cards, books } = useData();
  const [loading, setloading] = useState(false);

  return (
    <div className="bg-white dark:bg-main z-[1000] dark:text-white w-full flex items-center justify-between px-3 md:px-6 py-2 h-full rounded-md">
      <h1 className="text-xl text-main dark:text-white hidden md:block font-semibold">{title}</h1>

      <div className="inline-flex items-center justify-center">
        <span className="md:hidden inline-flex items-center justify-center">
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="inline-flex active:scale-90 transition-all items-center p-2 text-sm mt-1 text-main rounded-lg lg:hidden"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <Dialog.Root open={open} >
            <Dialog.Portal>
              <Dialog.Overlay onClick={() => { setOpen(!open) }} className="bg-blackA6 z-[1000] data-[state=open]:left-0 left-[-50%] fixed inset-0">
                <Dialog.Content className="z-[10000] h-screen data-[state=open]:animate-slideDrawer fixed top-0 left-0 w-[75%] flex items-start justify-between flex-col max-w-[450px]  bg-white p-4 focus:outline-none">
                  <div className="w-full" >
                    <Link
                      to="/"
                      onClick={() => { setOpen(false) }}
                      className="flex ms-3 active:scale-95 transition-all select-none items-center"
                    >
                      <img src="https://ik.imagekit.io/vituepzjm/3.png?updatedAt=1725199107156" alt="logo" className="w-fit h-16" />
                    </Link>
                    <div className=" w-full flex-col flex mt-5" id="navbar">
                      <div className="flex w-full justify-start flex-col lg:flex-row gap-4 lg:w-max max-lg:gap-4 lg:ml-14 lg:justify-end">
                        <Link
                          to="/dashboard/overview"
                          onClick={() => { setOpen(false) }}
                          className="px-4 py-3 border-2 text-xl border-white outline outline-[3px] outline-main active:scale-90 transition-all bg-main text-center text-white rounded-xl"
                        >
                          Overview
                        </Link>
                      </div>
                      <ul className="w-full flex mt-5 flex-col items-center justify-center gap-3">
                        {links.map((item, index) => (
                          <Link
                            key={index}
                            to={item.link}
                            onClick={() => { setOpen(false) }}
                            className={`text-lg border inline-flex justify-start items-center gap-3 active:scale-95 transition-all text-start ps-3 py-3 rounded-md dark:text-white text-main font-medium w-[80%] ${location.pathname.includes(item.link)
                              ? " border-main dark:border-white shadow-lg dark:text-main text-white dark:bg-white bg-main"
                              : "border-transparent"
                              }`}
                          >
                            <div dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                            {item.name}
                          </Link>
                        ))}
                        <Link
                          to={'shared'}
                          onClick={() => { setOpen(false) }}
                          className={`text-lg border inline-flex justify-start items-center gap-3 active:scale-95 transition-all text-start ps-3 py-3 rounded-md dark:text-white text-main font-medium w-[80%] ${location.pathname.includes('shared')
                            ? " border-main relative dark:border-white shadow-lg dark:text-main text-white dark:bg-white bg-main"
                            : "border-transparent"
                            }`}
                        >
                          <FaUsers />
                          Shared Space
                        </Link>
                      </ul>
                    </div>
                  </div>

                  <div className="flex w-full py-4 justify-center items-start">
                    {!loading ? (
                      <Link
                        to={"profile"}
                        onClick={() => { setOpen(false) }}
                        className=" w-full px-3 dark:bg-main bg-white bord relative rounded-lg py-3 divide-y-2"
                      >
                        <h1 className="text-lg my-3 font-semibold inline-flex items-center gap-1 capitalize dark:text-white text-main">
                          {" "}
                          {user.emailVerified && (
                            <img
                              src={user.photoURL}
                              alt="verified"
                              className="w-8 rounded-full"
                            />
                          )}{" "}
                          &nbsp;
                          {user?.displayName?.length > 13
                            ? `${user.displayName.slice(0, 13)}..`
                            : user.displayName}{" "}
                        </h1>

                        <div className="">
                          <span className="flex text-main dark:text-white pt-3 items-center justify-evenly">
                            <div className="w-[80px] dark:bg-gray-500 bg-gray-200 flex rounded-full me-3 h-3.5">
                              <div
                                className="bg-main dark:bg-white h-3.5 rounded-full"
                                style={{ width: (cards.length / 150) * 100 + '%' }}
                              ></div>
                            </div>
                            {`${cards.length + books.length} / 150 used.`}
                          </span>

                        </div>
                      </Link>
                    ) : (
                      <div class="dark:bg-main bg-white shadow rounded-3xl p-5 max-w-sm w-full mx-auto">
                        <span className="animate-pulse ">
                          <div class="flex space-x-4">
                            <div class="rounded-full bg-main dark:bg-white h-10 w-10"></div>
                            <div class="flex-1 space-y-6 py-1">
                              <div class="h-5 bg-main dark:bg-white rounded"></div>
                            </div>
                          </div>
                          <div class="h-4 mt-3 bg-main dark:bg-white rounded"></div>
                        </span>
                      </div>
                    )}
                  </div>
                </Dialog.Content>
              </Dialog.Overlay>
            </Dialog.Portal>
          </Dialog.Root>
          <img src="https://ik.imagekit.io/vituepzjm/3.png?updatedAt=1725199107156" alt="logo" className="w-fit h-10" />
        </span>
      </div>

      <div className="flex items-center">
        <div className="ml-3 inline-flex items-center justify-end gap-3 relative">
          <button disabled={isSyncing} onClick={() => {
            SyncData();
          }} className="p-2 hover:bg-main/20 transition-all disabled:animate-spin group rounded-full bg-main/10 dark:bg-white" >
            <TbReload className="text-base transition-all group-active:animate-spin text-main" />
          </button>
          <Popover.Root>
            <Popover.Trigger asChild>
              <button
                onClick={() => {
                  setIsDropdownOpen(!isDropdownOpen);
                }}
                className="flex w-fit items-center cursor-pointer gap-2 active:scale-95 transition-all"
              >
                <div className="h-12 w-12 rounded-full flex border dark:border-white border-main/30 items-center justify-center text-white md:text-3xl text-2xl text-center relative">
                  <img
                    src={user.photoURL || 'https://xsgames.co/randomusers/assets/avatars/pixel/51.jpg'}
                    alt="user_logo"
                    className="h-10 w-10 rounded-full bg-main/30"
                  />
                  <div className="absolute w-3 h-3 bg-green-500 rounded-[4px] -top-1 right-0 md:right-1.5 transform md:translate-x-2 translate-x-0 translate-y-2 border dark:border-main/60 border-white"></div>
                </div>
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className="grid grid-cols-1 me-4 gap-3 rounded-lg px-1 py-2 mt-3 dark:bg-main bg-white w-[180px] border border-gray-200 shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                sideOffset={3}
              >
                <div
                  className="px-2 flex flex-col gap-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <Link
                    to="profile"
                    className={`${location.pathname.includes("profile")
                      ? " bg-main shadow-lg text-white rounded-md"
                      : ""
                      } block w-full text-start px-4 py-2 text-sm dark:text-white text-main focus:outline-none`}
                    role="menuitem"
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen);
                    }}
                  >
                    Profile
                  </Link>
                  <button
                    onClick={() => {
                      localStorage.removeItem("scribbyToken");
                      logOut();
                      window.location.href = "/home";
                    }}
                    className="block w-full text-start px-4 py-2 text-sm dark:text-white text-main focus:outline-none"
                    role="menuitem"
                  >
                    Sign out
                  </button>
                </div>
                <Popover.Arrow className="dark:fill-main/50 fill-white -ms-3" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
