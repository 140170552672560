import React, { useState, useEffect } from "react";

function App() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const hours = time.getHours() % 12 || 12; // Adjust for 12-hour format
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");
  const amPm = time.getHours() >= 12 ? "PM" : "AM";

  return (
    <div className="h-full flex flex-col px-5 gap-3 py-5 items-center justify-center">
      <div className="text-center text-4xl  xl:text-5xl font-bold text-main">
        <span>{hours < 10 ? "0" + hours : hours}</span>
        <span>:</span>
        <span>{minutes}</span>
      </div>
      <div className="text-center text-4xl  xl:text-5xl font-bold text-main/90">
        <span>{seconds}</span>
        <span>:</span>
        <span>{amPm}</span>
      </div>
    </div>
  );
}

export default App;
