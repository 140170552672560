import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  GithubAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../config/firebase";

const UserAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);

  function logOut() {
    localStorage.removeItem("scribbyToken");
    return signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error("Error during logout: ", error);
      });
  }

  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function githubSignIn() {
    const githubAuthProvider = new GithubAuthProvider();
    return signInWithPopup(auth, githubAuthProvider);
  }

  function verifyAccount() {
    return new Promise((resolve, reject) => {
      sendEmailVerification(auth.currentUser)
        .then(() => {
          resolve({ status: true });
        })
        .catch((error) => {
          reject({ status: false });
          console.error("Error sending email verification: ", error);
        });
    });
  }

  const getUserNameFromStorage = (email) => {
    const name = email.split("@")[0];
    return name;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log(currentuser);
      if (currentuser && currentuser.displayName) {
        setUser(currentuser);
      } else if (currentuser) {
        const name = getUserNameFromStorage(currentuser.email);
        setUser({ ...currentuser, displayName: name });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserAuthContext.Provider
      value={{ user, logOut, googleSignIn, githubSignIn, verifyAccount }}
    >
      {children}
    </UserAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(UserAuthContext);
}
