import React, { useState, useEffect } from 'react';
import { cardsList } from '../../../common/links';

function Data() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            handleAutoChange();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const handleAutoChange = () => {
        setFadeIn(false);
        setTimeout(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % cardsList.length);
            setFadeIn(true);
        }, 300);
    };

    const handleCardClick = (index) => {
        setFadeIn(false);
        setTimeout(() => {
            setActiveIndex(index);
            setFadeIn(true);
        }, 200);
    };

    return (
        <div className="bg-white px-6 lg:px-16 py-8 lg:py-12">
            <div className="flex flex-col gap-5 lg:flex-row items-center lg:items-start w-full min-h-[90vh] h-auto lg:h-[90vh]">
                <div className="w-full lg:w-[40%] flex items-center justify-center min-h-[400px] lg:h-full mb-8 lg:mb-0">
                    <div className="relative mx-auto border-main/80 bg-main/80 border-[14px] rounded-3xl h-[600px] w-[300px] shadow-xl">
                        {/* <div className="size-[18px] bg-main/80 top-2 flex items-center justify-center rounded-full left-2 absolute"></div> */}
                        <div className="h-[32px] w-[3px] bg-main/80 absolute -start-[17px] top-[72px] rounded-s-2xl"></div>
                        <div className="h-[46px] w-[3px] bg-main/80 absolute -start-[17px] top-[124px] rounded-s-2xl"></div>
                        <div className="h-[46px] w-[3px] bg-main/80 absolute -start-[17px] top-[178px] rounded-s-2xl"></div>
                        <div className="h-[64px] w-[3px] bg-main/80 absolute -end-[17px] top-[142px] rounded-e-2xl"></div>
                        <div className="rounded-xl overflow-hidden w-[272px] h-[572px] bg-white">
                            <img
                                src={cardsList[activeIndex].url}
                                className={`dark:hidden w-[272px] h-[572px] object-cover transition-opacity duration-500 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}
                                alt={cardsList[activeIndex].name}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-[60%] lg:py-12 h-full text-center lg:text-left transition-all duration-500">
                    <h1 className="mb-6 text-2xl font-bold text-main sm:text-3xl lg:text-4xl transition-opacity duration-500">
                        Explore Our Specialized Cards and Book
                    </h1>
                    <h2 className={`mb-8 text-main/70 sm:text-lg transition-opacity duration-500`}>
                        Discover a variety of tools tailored for specific tasks. Whether you're working on content creation, design, or project management, we have the perfect solution for you. Each tool is crafted to enhance your workflow and make your tasks easier to manage.
                    </h2>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        {cardsList.map((item, index) => (
                            <div
                                onClick={() => handleCardClick(index)}
                                key={index}
                                className={`p-4 cursor-pointer rounded-lg shadow-md transition-all duration-500 ${activeIndex === index ? 'border-2 border-main' : 'border-2 border-transparent'} bg-white text-main `}>
                                <h1 className="text-lg font-semibold">{item.name}</h1>
                                <p className="text-base font-medium">{item.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Data;
