import React, { useEffect, useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { FaAngleDown } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import { PiGlobeHemisphereEastLight, PiSwatchesFill } from "react-icons/pi";
import { useUserAuth } from "../../context/UserAuthContext";
import { IoHome } from "react-icons/io5";

function Navbar() {
  const location = useLocation();
  const { user } = useUserAuth();
  return (
    <div className="px-5 py-3">
      <nav className=" transition-all top-0 left-0 z-50 duration-500 w-full bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="w-full flex flex-col lg:flex-row">
            <div className="flex justify-between lg:flex-row">
              <Link
                to="/home"
                className="flex active:scale-95 transition-all select-none items-center"
              >
                <img src="https://ik.imagekit.io/vituepzjm/3.png?updatedAt=1725199107156" alt="logo" className="w-auto h-14" />
              </Link>

              <Dialog.Root>
                <Dialog.Trigger asChild>
                  <button
                    type="button"
                    className="inline-flex active:scale-90 transition-all items-center p-2 ml-3 text-sm text-main rounded-lg lg:hidden  "
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </Dialog.Trigger>
                <Dialog.Portal>
                  <Dialog.Overlay className="bg-blackA6 z-[1000] data-[state=open]:left-0 left-[-50%] fixed inset-0" />
                  <Dialog.Content className="z-[10000] h-screen data-[state=open]:animate-slideDrawer fixed top-0 left-0 w-[75%] flex flex-col max-w-[450px]  bg-white p-6 focus:outline-none">
                    <span className="">
                      <Dialog.Title>
                        <Link
                          to="/"
                          className="flex ms-3 active:scale-95 transition-all select-none items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            zoomAndPan="magnify"
                            viewBox="0 0 375 225"
                            className="w-fit h-16 "
                            preserveAspectRatio="xMidYMid meet"
                            version="1.0"
                          >
                            <defs>
                              <g />
                              <clipPath id="cd1e5c3fc9">
                                <path
                                  d="M 12.429688 40.171875 L 181.96875 40.171875 L 181.96875 184.828125 L 12.429688 184.828125 Z M 12.429688 40.171875 "
                                  clip-rule="nonzero"
                                />
                              </clipPath>
                              <clipPath id="750cfe7c29">
                                <path
                                  d="M 46.660156 44.734375 L 62.839844 42.714844 C 85.574219 39.871094 108.574219 39.871094 131.308594 42.714844 L 147.488281 44.734375 C 167.042969 47.179688 181.722656 63.804688 181.722656 83.515625 L 181.722656 141.484375 C 181.722656 161.195312 167.042969 177.820312 147.488281 180.265625 L 131.308594 182.285156 C 108.574219 185.128906 85.574219 185.128906 62.839844 182.285156 L 46.660156 180.265625 C 27.105469 177.820312 12.429688 161.195312 12.429688 141.484375 L 12.429688 83.515625 C 12.429688 63.804688 27.105469 47.179688 46.660156 44.734375 Z M 46.660156 44.734375 "
                                  clip-rule="nonzero"
                                />
                              </clipPath>
                            </defs>
                            <g clip-path="url(#cd1e5c3fc9)">
                              <g clip-path="url(#750cfe7c29)">
                                <path
                                  fill="#000000"
                                  d="M 12.429688 38.433594 L 181.96875 38.433594 L 181.96875 186.566406 L 12.429688 186.566406 Z M 12.429688 38.433594 "
                                  fill-opacity="1"
                                  fill-rule="nonzero"
                                />
                              </g>
                            </g>
                            <g fill="#ffffff" fill-opacity="1">
                              <g transform="translate(45.071021, 145.966382)">
                                <g>
                                  <path d="M 23.515625 1.046875 C 19.296875 1.046875 15.203125 0.523438 11.234375 -0.515625 C 7.265625 -1.554688 4.039062 -2.945312 1.5625 -4.6875 L 6.40625 -15.546875 C 8.726562 -14.015625 11.425781 -12.773438 14.5 -11.828125 C 17.582031 -10.890625 20.613281 -10.421875 23.59375 -10.421875 C 29.238281 -10.421875 32.0625 -11.832031 32.0625 -14.65625 C 32.0625 -16.144531 31.253906 -17.25 29.640625 -17.96875 C 28.035156 -18.6875 25.445312 -19.441406 21.875 -20.234375 C 17.957031 -21.078125 14.679688 -21.984375 12.046875 -22.953125 C 9.421875 -23.921875 7.164062 -25.46875 5.28125 -27.59375 C 3.394531 -29.726562 2.453125 -32.609375 2.453125 -36.234375 C 2.453125 -39.410156 3.316406 -42.273438 5.046875 -44.828125 C 6.785156 -47.378906 9.378906 -49.398438 12.828125 -50.890625 C 16.273438 -52.378906 20.503906 -53.125 25.515625 -53.125 C 28.941406 -53.125 32.316406 -52.738281 35.640625 -51.96875 C 38.960938 -51.195312 41.890625 -50.070312 44.421875 -48.59375 L 39.875 -37.65625 C 34.914062 -40.332031 30.109375 -41.671875 25.453125 -41.671875 C 22.523438 -41.671875 20.390625 -41.234375 19.046875 -40.359375 C 17.703125 -39.492188 17.03125 -38.367188 17.03125 -36.984375 C 17.03125 -35.585938 17.820312 -34.539062 19.40625 -33.84375 C 21 -33.15625 23.554688 -32.441406 27.078125 -31.703125 C 31.046875 -30.859375 34.332031 -29.953125 36.9375 -28.984375 C 39.539062 -28.015625 41.796875 -26.472656 43.703125 -24.359375 C 45.617188 -22.253906 46.578125 -19.394531 46.578125 -15.78125 C 46.578125 -12.65625 45.707031 -9.828125 43.96875 -7.296875 C 42.238281 -4.765625 39.632812 -2.738281 36.15625 -1.21875 C 32.6875 0.289062 28.472656 1.046875 23.515625 1.046875 Z M 23.515625 1.046875 " />
                                </g>
                              </g>
                            </g>
                            <g fill="#ffffff" fill-opacity="1">
                              <g transform="translate(93.804602, 145.966382)">
                                <g>
                                  <path d="M 25.15625 0.671875 C 20.6875 0.671875 16.691406 -0.21875 13.171875 -2 C 9.648438 -3.789062 6.910156 -6.273438 4.953125 -9.453125 C 2.992188 -12.628906 2.015625 -16.222656 2.015625 -20.234375 C 2.015625 -24.253906 2.992188 -27.835938 4.953125 -30.984375 C 6.910156 -34.140625 9.648438 -36.597656 13.171875 -38.359375 C 16.691406 -40.117188 20.6875 -41 25.15625 -41 C 29.71875 -41 33.660156 -40.019531 36.984375 -38.0625 C 40.304688 -36.101562 42.660156 -33.382812 44.046875 -29.90625 L 33.109375 -24.328125 C 31.273438 -27.953125 28.597656 -29.765625 25.078125 -29.765625 C 22.546875 -29.765625 20.445312 -28.921875 18.78125 -27.234375 C 17.125 -25.546875 16.296875 -23.210938 16.296875 -20.234375 C 16.296875 -17.210938 17.125 -14.84375 18.78125 -13.125 C 20.445312 -11.414062 22.546875 -10.5625 25.078125 -10.5625 C 28.597656 -10.5625 31.273438 -12.375 33.109375 -16 L 44.046875 -10.421875 C 42.660156 -6.941406 40.304688 -4.222656 36.984375 -2.265625 C 33.660156 -0.304688 29.71875 0.671875 25.15625 0.671875 Z M 25.15625 0.671875 " />
                                </g>
                              </g>
                            </g>
                            <g fill="#ffffff" fill-opacity="1">
                              <g transform="translate(139.264476, 145.966382)">
                                <g>
                                  <path d="M 18 -35.484375 C 19.445312 -37.328125 21.320312 -38.707031 23.625 -39.625 C 25.925781 -40.539062 28.566406 -41 31.546875 -41 L 31.546875 -28.28125 C 30.253906 -28.425781 29.1875 -28.5 28.34375 -28.5 C 25.320312 -28.5 22.953125 -27.679688 21.234375 -26.046875 C 19.523438 -24.410156 18.671875 -21.90625 18.671875 -18.53125 L 18.671875 0 L 4.53125 0 L 4.53125 -40.328125 L 18 -40.328125 Z M 18 -35.484375 " />
                                </g>
                              </g>
                            </g>
                            <g fill="#000000" fill-opacity="1">
                              <g transform="translate(182.880747, 147.736503)">
                                <g>
                                  <path d="M 4.53125 -40.328125 L 18.671875 -40.328125 L 18.671875 0 L 4.53125 0 Z M 11.59375 -44.796875 C 9.019531 -44.796875 6.9375 -45.515625 5.34375 -46.953125 C 3.757812 -48.390625 2.96875 -50.175781 2.96875 -52.3125 C 2.96875 -54.4375 3.757812 -56.21875 5.34375 -57.65625 C 6.9375 -59.101562 9.019531 -59.828125 11.59375 -59.828125 C 14.175781 -59.828125 16.257812 -59.144531 17.84375 -57.78125 C 19.4375 -56.414062 20.234375 -54.664062 20.234375 -52.53125 C 20.234375 -50.300781 19.4375 -48.453125 17.84375 -46.984375 C 16.257812 -45.523438 14.175781 -44.796875 11.59375 -44.796875 Z M 11.59375 -44.796875 " />
                                </g>
                              </g>
                            </g>
                            <g fill="#000000" fill-opacity="1">
                              <g transform="translate(206.763199, 147.736503)">
                                <g>
                                  <path d="M 29.90625 -41 C 33.582031 -41 36.921875 -40.15625 39.921875 -38.46875 C 42.921875 -36.78125 45.300781 -34.359375 47.0625 -31.203125 C 48.820312 -28.054688 49.703125 -24.398438 49.703125 -20.234375 C 49.703125 -16.066406 48.820312 -12.394531 47.0625 -9.21875 C 45.300781 -6.050781 42.921875 -3.609375 39.921875 -1.890625 C 36.921875 -0.179688 33.582031 0.671875 29.90625 0.671875 C 24.601562 0.671875 20.632812 -0.890625 18 -4.015625 L 18 0 L 4.53125 0 L 4.53125 -55.203125 L 18.671875 -55.203125 L 18.671875 -36.828125 C 21.398438 -39.609375 25.144531 -41 29.90625 -41 Z M 26.9375 -10.5625 C 29.414062 -10.5625 31.445312 -11.414062 33.03125 -13.125 C 34.625 -14.84375 35.421875 -17.210938 35.421875 -20.234375 C 35.421875 -23.210938 34.625 -25.546875 33.03125 -27.234375 C 31.445312 -28.921875 29.414062 -29.765625 26.9375 -29.765625 C 24.457031 -29.765625 22.421875 -28.921875 20.828125 -27.234375 C 19.242188 -25.546875 18.453125 -23.210938 18.453125 -20.234375 C 18.453125 -17.210938 19.242188 -14.84375 20.828125 -13.125 C 22.421875 -11.414062 24.457031 -10.5625 26.9375 -10.5625 Z M 26.9375 -10.5625 " />
                                </g>
                              </g>
                            </g>
                            <g fill="#000000" fill-opacity="1">
                              <g transform="translate(259.066422, 147.736503)">
                                <g>
                                  <path d="M 29.90625 -41 C 33.582031 -41 36.921875 -40.15625 39.921875 -38.46875 C 42.921875 -36.78125 45.300781 -34.359375 47.0625 -31.203125 C 48.820312 -28.054688 49.703125 -24.398438 49.703125 -20.234375 C 49.703125 -16.066406 48.820312 -12.394531 47.0625 -9.21875 C 45.300781 -6.050781 42.921875 -3.609375 39.921875 -1.890625 C 36.921875 -0.179688 33.582031 0.671875 29.90625 0.671875 C 24.601562 0.671875 20.632812 -0.890625 18 -4.015625 L 18 0 L 4.53125 0 L 4.53125 -55.203125 L 18.671875 -55.203125 L 18.671875 -36.828125 C 21.398438 -39.609375 25.144531 -41 29.90625 -41 Z M 26.9375 -10.5625 C 29.414062 -10.5625 31.445312 -11.414062 33.03125 -13.125 C 34.625 -14.84375 35.421875 -17.210938 35.421875 -20.234375 C 35.421875 -23.210938 34.625 -25.546875 33.03125 -27.234375 C 31.445312 -28.921875 29.414062 -29.765625 26.9375 -29.765625 C 24.457031 -29.765625 22.421875 -28.921875 20.828125 -27.234375 C 19.242188 -25.546875 18.453125 -23.210938 18.453125 -20.234375 C 18.453125 -17.210938 19.242188 -14.84375 20.828125 -13.125 C 22.421875 -11.414062 24.457031 -10.5625 26.9375 -10.5625 Z M 26.9375 -10.5625 " />
                                </g>
                              </g>
                            </g>
                            <g fill="#000000" fill-opacity="1">
                              <g transform="translate(311.369651, 147.736503)">
                                <g>
                                  <path d="M 47.03125 -40.328125 L 29.609375 1.640625 C 27.578125 6.597656 25.097656 10.082031 22.171875 12.09375 C 19.242188 14.101562 15.671875 15.109375 11.453125 15.109375 C 9.328125 15.109375 7.179688 14.769531 5.015625 14.09375 C 2.859375 13.425781 1.113281 12.546875 -0.21875 11.453125 L 4.6875 1.5625 C 5.476562 2.300781 6.429688 2.878906 7.546875 3.296875 C 8.660156 3.722656 9.789062 3.9375 10.9375 3.9375 C 12.28125 3.9375 13.382812 3.664062 14.25 3.125 C 15.113281 2.582031 15.84375 1.710938 16.4375 0.515625 L -0.8125 -40.328125 L 13.6875 -40.328125 L 23.59375 -15.703125 L 33.5625 -40.328125 Z M 47.03125 -40.328125 " />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </Link>
                      </Dialog.Title>
                    </span>

                    <div className=" w-full flex-col flex mt-5" id="navbar">
                      <div className="flex w-full justify-start flex-col lg:flex-row gap-4 lg:w-max max-lg:gap-4 lg:ml-14 lg:justify-end">
                        {user ? <Link
                          to="/dashboard/overview"
                          className="px-5 py-2 border-2  text-[15px] border-white outline outline-[3px] outline-main active:scale-90 transition-all bg-main text-center text-white rounded-full"
                        >
                          Your Space
                        </Link> : <Link
                          to="/access-now"
                          className="px-5 py-2 border-2  text-[15px] border-white outline outline-[3px] outline-main active:scale-90 transition-all bg-main text-center text-white rounded-full"
                        >
                          Access Now
                        </Link>}
                      </div>
                      <ul className="flex flex-col  mt-5 px-3 py-2  rounded-full gap-3">
                        <li>
                          <Link
                            to="/"
                            href="javascript:;"
                            className={`${location.pathname.includes("home")
                              ? "bg-main text-white"
                              : "text-main"
                              } rounded-lg inline-flex items-center justify-start gap-3 w-full active:scale-90 px-5 py-3  text-base font-medium transition-all duration-500`}
                          >
                            <IoHome /> Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/about"
                            href="javascript:;"
                            className={`${location.pathname.includes("about")
                              ? "bg-main text-white"
                              : "text-main"
                              } rounded-lg inline-flex items-center justify-start gap-3 w-full active:scale-90 px-5 py-3  text-base font-medium transition-all duration-500`}
                          >
                          <PiSwatchesFill />  Why Scribby
                          </Link>
                        </li>

                        {/* <li>
                          <Popover.Root>
                            <Popover.Trigger asChild>
                              <button
                                className={`inline-flex items-center max-w-[200px] w-full gap-1 rounded-lg text-main active:scale-90 ms-2 px-3 py-1 text-base font-medium transition-all duration-500`}
                              >
                                Explore <FaAngleDown className="text-main" />
                              </button>
                            </Popover.Trigger>
                            <Popover.Portal>
                              <Popover.Content
                                className="grid grid-cols-1 z-[10000] rounded-lg p-3 mt-1 border border-gray-200 bg-gray-100 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                                sideOffset={5}
                              >
                                <span className=" inline-flex items-center flex-col gap-3">
                                  <Link
                                    to="/notes"
                                    className={`${location.pathname.includes("notes")
                                      ? "bg-main text-white"
                                      : "text-main"
                                      } rounded-lg py-2 px-5 w-full hover:bg-main hover:text-white `}
                                  >
                                    <button className="w-full">
                                      Note Card
                                    </button>
                                  </Link>
                                  <Link
                                    to="/tasks"
                                    className={`${location.pathname.includes("tasks")
                                      ? "bg-main text-white"
                                      : "text-main"
                                      } rounded-lg py-2 px-5 w-full hover:bg-main hover:text-white `}
                                  >
                                    <button className="w-full">
                                      Note Books
                                    </button>
                                  </Link>
                                  <Link
                                    to="/ai-features"
                                    className={`${location.pathname.includes("ai-features")
                                      ? "bg-main text-white"
                                      : "text-main"
                                      } rounded-lg py-2 px-5 w-full hover:bg-main hover:text-white `}
                                  >
                                    <button className="w-full">
                                      Ai Features
                                    </button>
                                  </Link>
                                </span>
                                <Popover.Arrow className="fill-gray-300 ms-[500%]" />
                              </Popover.Content>
                            </Popover.Portal>
                          </Popover.Root>
                        </li> */}
                      </ul>
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
            <div
              className="hidden w-full lg:ml-14 lg:flex mt-1 max-lg:overflow-y-auto"
              id="navbar"
            >
              <ul className="flex lg:items-center px-3 py-2  rounded-full gap-3 lg:justify-center flex-col lg:mt-0 lg:flex-row lg:mx-auto">
                <li>
                  <Link
                    to="/"
                    href="javascript:;"
                    className={`${location.pathname.includes("home")
                      ? "bg-main text-white"
                      : "text-main"
                      } block rounded-full active:scale-90 px-5 py-2 text-base font-medium transition-all duration-500`}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    href="javascript:;"
                    className={`${location.pathname.includes("about")
                      ? "bg-main text-white"
                      : "text-main"
                      } block rounded-full active:scale-90  px-5 py-2 text-base font-medium transition-all duration-500`}
                  >
                    Why Scribby ?
                  </Link>
                </li>

                {/* <li>
                  <Link
                    to="/contact"
                    href="javascript:;"
                    className={`${location.pathname.includes("contact")
                      ? "bg-main text-white"
                      : "text-main"
                      } block rounded-full active:scale-90  px-5 py-2 text-base font-medium transition-all duration-500`}
                  >
                    Contact
                  </Link>
                </li> */}

                {/* <li>
                  <Popover.Root>
                    <Popover.Trigger asChild>
                      <button
                        className={` inline-flex items-center gap-1 rounded-full text-main active:scale-90  px-3 py-1 text-base font-medium transition-all duration-500`}
                        aria-label="Update dimensions"
                      >
                        Explore <FaAngleDown className="text-main" />
                      </button>
                    </Popover.Trigger>
                    <Popover.Portal>
                      <Popover.Content
                        className="grid grid-cols-2 gap-3 rounded-lg p-5 mt-3 bg-white w-[400px] border border-gray-200 shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                        sideOffset={5}
                      >
                        <span className="col-span-1 flex items-end bg-gradient-to-bl from-gray-600 via-main to-main h-[180px] rounded-lg p-3">
                          <span className="break-words">
                            <PiGlobeHemisphereEastLight className="text-white fa-3x mb-2" />
                            <h1 className="text-white text-2xl  leading-7 ">
                              Scribby <br /> Features
                            </h1>
                          </span>
                        </span>
                        <span className="col-span-1 inline-flex items-center flex-col gap-3">
                          <Link
                            to="/notes"
                            className={`${location.pathname.includes("notes")
                              ? "bg-main text-white"
                              : "text-main"
                              } rounded-lg py-3.5 w-full hover:bg-main hover:text-white `}
                          >
                            <button className="w-full">Note Card</button>
                          </Link>
                          <Link
                            to="/tasks"
                            className={`${location.pathname.includes("tasks")
                              ? "bg-main text-white"
                              : "text-main"
                              } rounded-lg py-3.5 w-full  hover:bg-main hover:text-white `}
                          >
                            <button className="w-full">Note Books</button>
                          </Link>
                          <Link
                            to="/ai-features"
                            className={`${location.pathname.includes("ai-features")
                              ? "bg-main text-white"
                              : "text-main"
                              } rounded-lg py-3.5 w-full hover:bg-main hover:text-white `}
                          >
                            <button className="w-full">Ai Features</button>
                          </Link>
                        </span>
                        <Popover.Arrow className="fill-gray-200" />
                      </Popover.Content>
                    </Popover.Portal>
                  </Popover.Root>
                </li> */}
              </ul>
              <div className="flex lg:items-center w-full justify-start flex-col lg:flex-row gap-4 lg:w-max max-lg:gap-4 lg:ml-14 lg:justify-end">
                {user ? <Link
                  to="/dashboard/overview"
                  className="px-5 py-2 border-2 text-[15px] border-white outline outline-[3px] outline-main active:scale-90 transition-all bg-main text-center text-white rounded-full"
                >
                  Your Space
                </Link> : <Link
                  to="/access-now"
                  className="px-5 py-2 border-2 text-[15px] border-white outline outline-[3px] outline-main active:scale-90 transition-all bg-main text-center text-white rounded-full"
                >
                  Access Now
                </Link>}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
