import React, { useEffect, useState } from "react";
import LoadingPage from "../Loading/LoadingPage";
import toast, { Toaster } from "react-hot-toast";
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from "react-router";
import { FaGithub, FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";

function AccessNow() {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setloading] = useState(false);
  const { googleSignIn, githubSignIn } = useUserAuth();
  const navigate = useNavigate();

  const GoogleAuth = async () => {
    setloading(true);
    try {
      await googleSignIn();
      navigate("/dashboard");
    } catch (err) {
      toast.error(
        err.message
          .replace("Firebase:", "")
          .replace(".", "")
          .replace("Error (", "")
          .replace(")", "")
          .replace("auth/", "")
          .replaceAll("-", " "),
        { position: "top-right" }
      );
    } finally {
      setloading(false);
    }
  };

  const GitHubAuth = async () => {
    setloading(true);

    try {
      await githubSignIn();
      navigate("/dashboard");
    } catch (err) {
      toast.error(
        err.message
          .replace("Firebase:", "")
          .replace(".", "")
          .replace("Error (", "")
          .replace(")", "")
          .replace("auth/", "")
          .replaceAll("-", " "),
        { position: "top-right" }
      );
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <div className="overflow-hidden">
        <div className="absolute border-e-8 animate-slideDown border-t-8 border-main left-0 h-[85vh] hover:bg-org hover:border-org transition-all w-[20vw] max-w-[300px] rounded-tr-full bottom-0 bg-main hidden lg:block"></div>
        <div className="flex w-full h-screen items-center justify-center">
          <div className="w-full max-w-[550px] bg-white px-6 py-2 text-center md:px-12 ">
            <div className="flex flex-col items-center space-y-2">
              <img
                src="https://ik.imagekit.io/vituepzjm/Scribby/7.svg?updatedAt=1722814793279"
                alt="Scribby Logo"
                className="bg-main rounded-xl  mb-3 size-[100px]"
              />
              <h1 className="text-xl font-semibold leading-tighter text-grey-15 md:text-[32px]">
                Sign in
              </h1>
              <p className="text-lg font-normal leading-normal text-grey-15">
                to continue to your Scribby account.{" "}
              </p>
            </div>
            <div className=" relative z-20 flex flex-col space-y-4 pt-8 md:pt-10">
              <div className="flex w-full flex-col space-y-4">
                <button
                  disabled={loading}
                  onClick={GoogleAuth}
                  className="flex justify-center disabled:bg-main disabled:text-white p-3 w-full rounded-full border hover:bg-main hover:text-white group border-main"
                >
                  <span className="whitespace-nowrap text-[16px] font-semibold flex items-center justify-center">
                    <FaGoogle className="mr-2 h-5 w-5 group-hover:text-white" />
                    Continue with Google
                  </span>
                </button>
                <button
                  disabled={loading}
                  onClick={GitHubAuth}
                  className="flex justify-center disabled:bg-main disabled:text-white p-3 w-full rounded-full border hover:bg-main hover:text-white group border-main"
                >
                  <span className="whitespace-nowrap text-[16px] font-semibold flex items-center justify-center">
                    <FaGithub className="mr-2 h-5 w-5 group-hover:text-white" />
                    Continue with Github
                  </span>
                </button>
              </div>
              <div>
                <p className="block w-full px-6 py-2 text-[14px] font-medium text-grey-45">
                  by signing into the scribby you accept the <Link className="underline" to="/terms-of-use" >Terms of Service</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute right-0 h-[85vh] transition-all animate-slideUp border-s-8 border-b-8 border-main hover:bg-org hover:border-org rounded-bl-full w-[20vw] max-w-[300px] top-0 bg-main   hidden lg:block"></div>
        <Toaster
          toastOptions={{
            className: "",
            style: {
              background: "#ffffff",
              color: "#1a1a1a",
            },
          }}
        />
      </div>
    </div>
  );
}

export default AccessNow;
