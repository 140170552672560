import React, { useEffect, useState } from "react";
import LoadingPage from "../Loading/LoadingPage";
import Banner from "./components/Banner";
import Navbar from "./components/Navbar";

function Tasks() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <div>
        <Banner />
        <Navbar />
      </div>
    </div>
  );
}

export default Tasks;
