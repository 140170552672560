import React from "react";
import { BsArrowsFullscreen } from "react-icons/bs";
import * as Dialog from "@radix-ui/react-dialog";

function Card() {
  return (
    <div className="relative grayscale">
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <button className="absolute bg-main text-white p-2 rounded-md z-[1000] top-2 right-2">
            <BsArrowsFullscreen />
          </button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
          <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] h-[85vh] w-[80vw] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg focus:outline-none">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21412356.931014374!2d80.23916402333549!3d20.844066499114852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1723914239266!5m2!1sen!2sin"
              className="w-full h-full focus:outline-none rounded-lg"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21412356.931014374!2d80.23916402333549!3d20.844066499114852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1723914239266!5m2!1sen!2sin"
        className="w-full select-none rounded-md focus:outline-none h-[300px]"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Card;