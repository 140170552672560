import React, { useState, useEffect } from "react";
import { RiUploadCloudFill } from "react-icons/ri";

const UploadModal = ({ handleUpload }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState(false);
    const [fileActive, setFileActive] = useState(false);
    const [previewURL, setPreviewURL] = useState("");

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (fileActive) {
                event.preventDefault();
                event.returnValue = "";
                const result = window.confirm("Are you sure you want to proceed?");
                if (result) {
                    window.location.reload();
                }
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [fileActive]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        handleFileSelection(file);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        handleFileSelection(file);
    };

    const handleFileSelection = (file) => {
        if (file && file.type.startsWith("image/")) {
            setFileActive(true);
            setSelectedFile(file);
            setError(false);
            setPreviewURL(URL.createObjectURL(file));
        } else {
            resetFileSelection();
            setError(true);
        }
    };

    const resetFileSelection = () => {
        setSelectedFile(null);
        setPreviewURL("");
        setFileActive(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedFile) {
            setError(false);
            const response = await handleUpload(selectedFile);
            if (response) {
                resetFileSelection();
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    };

    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="relative w-auto max-h-[50vh] -mt-16 max-w-2xl">
                <div className="bg-white flex items-center border-2 outline-[15px] outline outline-white border-dashed border-main/80 max-h-[50vh] flex-col justify-center rounded-xl shadow-lg p-4">
                    {selectedFile ? (
                        <div className="flex flex-col px-2 py-5 items-center">
                            <div className="relative">
                                <img
                                    src={previewURL}
                                    alt="Preview"
                                    className="max-h-60 rounded"
                                />
                            </div>
                            <div className="flex items-center mt-5 justify-center gap-3">
                                <button
                                    onClick={handleSubmit}
                                    className="bg-main inline-flex items-center justify-center gap-2 text-white rounded px-4 py-2 focus:outline-none"
                                >
                                    <RiUploadCloudFill /> Upload
                                </button>
                                <button
                                    onClick={resetFileSelection}
                                    className="bg-main text-white rounded px-4 py-2 focus:outline-none"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="w-full rounded-xl relative h-[90vh]"
                            onDrop={handleDrop}
                            onDragOver={(event) => event.preventDefault()}
                        >
                            <div className="h-full absolute w-full text-center flex flex-col items-center justify-center">
                                <img
                                    className="mx-auto w-52"
                                    src="https://ik.imagekit.io/vituepzjm/Image%20upload-bro.svg?updatedAt=1724153065990"
                                    alt="Drag and Drop"
                                />
                                <span className="text-small text-main/80">
                                    Drag and Drop File here, or browse files
                                </span>
                            </div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50"
                            />
                        </div>
                    )}
                    {error && (
                        <span className="text-sm mt-4 text-red-500">
                            *No file selected or file size exceeds the limit
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UploadModal;
