import React, { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useUserAuth } from "../../context/UserAuthContext";
import { v4 as uuidv4 } from "uuid";
import { IoColorFill } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import UploadModal from "./Upload/Upload";
import { db, storage } from "../../../config/firebase";
import { getCurrentTimeInUTCFormat } from "../../../common/methods";
import { addDoc, collection } from "firebase/firestore";
import { useData } from "../../context/DataContext";

const bgColors = [
  "#ffffff", "#fad154", "#61d1ff", "#d1d9c9",
  "#f7bfff", "#d4e0e3", "#ffc27d", "#ffa8b3", "#cfc4ff"
];

const getRandomColor = () => bgColors[Math.floor(Math.random() * bgColors.length)];

function ImageCard({ setImagePopup }) {
  const { user } = useUserAuth();
  const { setCards } = useData();
  const [imgUrl, setImgUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [color, setColor] = useState(getRandomColor());

  const handleUpload = async (uploadedImg) => {
    setUploading(true);

    try {
      const maxSize = 100 * 1024 * 1024;
      const filename = uploadedImg.name.split(".").slice(0, -1).join(".");
      const fileType = uploadedImg.type;
      const size = uploadedImg.size;

      if (!fileType.startsWith("image/")) {
        toast.error("Invalid File Type", { position: "top-center" });
        setUploading(false);
        return;
      }

      if (size > maxSize) {
        toast.error("File Size Exceeds Limit", { position: "top-center" });
        setUploading(false);
        return;
      }

      const imgRef = ref(storage, `${user.email}/${filename}`);
      const uploadTask = uploadBytes(imgRef, uploadedImg);

      toast.promise(
        uploadTask,
        {
          loading: "Uploading file...",
          success: "File uploaded successfully!",
          error: "Failed to upload file",
        },
        { position: "top-center" }
      );

      const snapshot = await uploadTask;
      const url = await getDownloadURL(snapshot.ref);

      const insertData = {
        uid: user.uid,
        ncid: uuidv4(),
        name: filename,
        type: "image",
        shared: [],
        data: url,
        createdAt: new Date(),
        updatedAt: new Date(),
        color: color
      }

      await addDoc(collection(db, "notecard"), insertData);

      setCards(prevList =>
        [insertData, ...prevList]
      )

      setImgUrl(url);
      setImagePopup(false);
      return true;
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred while uploading the file", { position: "top-center" });
      return false;
    } finally {
      setUploading(false);
    }
  };

  return (
    <div style={{ backgroundColor: color }} className="bg-white w-full h-full rounded-t-2xl">
      <div className="w-full h-[10vh] p-4 rounded-t-2xl flex items-center justify-between gap-3">
        <h1 className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold">ImageCard</h1>
        <div className="flex items-center gap-3">
          <Popover.Root>
            <Popover.Trigger asChild>
              <button className="bg-main transition-all text-white font-bold p-2 rounded-full">
                <IoColorFill />
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className="rounded-lg px-3 py-5 z-[10000] w-[180px] bg-white border shadow-md"
                sideOffset={10} side="bottom"
              >
                <div className="grid grid-cols-3 place-items-center gap-3">
                  {bgColors.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => setColor(item)}
                      style={{ backgroundColor: item }}
                      className={`size-8 border shadow ${color === item ? "border-main/60" : "border-transparent"} rounded-md`}
                    />
                  ))}
                </div>
                <Popover.Arrow className="fill-gray-200" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      </div>

      <div className="w-full h-[85vh] text-main overflow-y-auto px-4 py-3" style={{ backgroundColor: color }}>
        <UploadModal handleUpload={handleUpload} />
      </div>

      <Toaster
        toastOptions={{
          className: "",
          style: { background: "#ffffff", color: "#1a1a1a" },
        }}
      />
    </div>
  );
}

export default ImageCard;
