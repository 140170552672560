import React, { useState, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Marker from "@editorjs/marker";
import Underline from "@editorjs/underline";
import ChangeCase from "editorjs-change-case";
import Checklist from "@editorjs/checklist";
import { IoMdCloud } from "react-icons/io";
import { useHotkeys } from "react-hotkeys-hook";
import { IoArrowUpCircle, IoColorFill } from "react-icons/io5";
import * as Popover from "@radix-ui/react-popover";
import { collection, updateDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useData } from "../../context/DataContext";
import toast, { Toaster } from "react-hot-toast";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { HiTrash } from "react-icons/hi2";
import { helix } from 'ldrs'
import { RiAiGenerate } from "react-icons/ri";
import { FaGear } from "react-icons/fa6";
import {
  GoogleGenerativeAI,
  HarmCategory,
  HarmBlockThreshold
} from "@google/generative-ai";
import { SystemInfoTodo } from "../noteboard/ai/Data";
import { API_KEY } from "../../../common/links";
import { convertBoldSyntax } from "../../../common/methods";

const bgColors = [
  "#ffffff", "#fad154", "#61d1ff", "#d1d9c9", "#f7bfff",
  "#d4e0e3", "#ffc27d", "#ffa8b3", "#cfc4ff",
];

function TodoEditor({ setResults, setisEditing, item, HandleDelete, isDeleting, SortData }) {
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] = useState(null);
  const [name, setName] = useState(item.name);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isSaving, setisSaving] = useState(false);
  const [editorData, setEditorData] = useState(item.data);
  const [bgColor, setBgColor] = useState(item.color);
  const { setCards, setboardPreview } = useData();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [Lastprompt, setLastPrompt] = useState("");
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);

  const genAI = new GoogleGenerativeAI(API_KEY);

  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    systemInstruction: SystemInfoTodo
  });

  const generationConfig = {
    temperature: 1,
    topP: 0.95,
    topK: 64,
    maxOutputTokens: 8192,
    responseMimeType: "application/json",
  };

  const safetySettings = [{ category: HarmCategory.HARM_CATEGORY_HARASSMENT, threshold: HarmBlockThreshold.BLOCK_NONE, }, { category: HarmCategory.HARM_CATEGORY_HATE_SPEECH, threshold: HarmBlockThreshold.BLOCK_NONE, }, { category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT, threshold: HarmBlockThreshold.BLOCK_NONE, }, { category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT, threshold: HarmBlockThreshold.BLOCK_NONE, },];

  const handleChatSubmission = async (message) => {
    setLoading(true);
    try {
      const history = [
        {
          role: "user",
          parts: [{ text: Lastprompt }]
        },
        {
          role: "model",
          parts: [{ text: JSON.stringify(editorData) }]
        }
      ];

      const chatSession = model.startChat({
        generationConfig,
        safetySettings,
        history,
      });

      const result = await chatSession.sendMessage(message);
      const response = await result.response;

      if (response.status === "blocked") {
        toast.error(
          `Unable to process request due to potentially harmful content!`,
          {
            position: "top-center",
            icon: "❌",
          }
        );
        throw new Error("Response blocked due to potentially harmful content");
      }

      const text = await response.text();
      const aidata = JSON.parse(text);
      editorInstance.render(convertBoldSyntax(aidata));
      setEditorData(aidata);
      setLastPrompt(prompt);
      setPrompt("");
    } catch (error) {
      console.error(error.message);
      toast.error(`Unable to process your request! `, {
        position: "top-center",
        icon: "❌",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!prompt.trim()) {
      toast.success("Please enter a prompt!", {
        position: "top-center",
        icon: "✏️",
      });
      return;
    }
    handleChatSubmission(prompt);
  };


  useHotkeys("ctrl+s", (event) => {
    event.preventDefault();
    handleSave();
  });

  useEffect(() => {
    helix.register()
    if (editorRef.current) {
      const newEditor = new EditorJS({
        holder: "editorjs",
        autofocus: true,
        tools: {
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          underline: {
            class: Underline,
            shortcut: "alt+u",
          },
          Marker: {
            class: Marker,
            shortcut: "alt+v",
          },
          changeCase: {
            class: ChangeCase,
            config: {
              showLocaleOption: true,
              shortcut: "alt+c",
              locale: "tr",
            },
          },
        },
        placeholder: "Let`s write an awesome story!",
        onChange: (api, event) => console.log(editorData),
        data: editorData,
      });
      setEditorInstance(newEditor);
    }

    return () => {
      if (editorInstance) {
        editorInstance.destroy();
      }
    };
  }, [editorData]);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      setisEditing(false);
      window.history.pushState(null, "", window.location.pathname);

      console.log("Back navigation attempt prevented");
    };
    window.addEventListener('popstate', handlePopState);
    window.history.pushState(null, "", window.location.pathname);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [setisEditing]);

  const handleSave = async () => {
    try {
      setisSaving(true);
      if (editorInstance) {
        const savedData = await editorInstance.save();
        if (savedData.blocks.length > 0) {
          const noteData = {
            name,
            data: savedData,
            updatedAt: new Date(),
            color: bgColor,
          };
          const userDocQuery = query(
            collection(db, "notecard"),
            where("ncid", "==", item.ncid)
          );
          const querySnapshot = await getDocs(userDocQuery);
          if (!querySnapshot.empty) {
            const docRef = querySnapshot.docs[0].ref;
            await updateDoc(docRef, noteData);

            setCards((prevCards) =>
              prevCards.map((c) =>
                c.ncid === item.ncid
                  ? { ...c, ...noteData }
                  : c
              )
            );
            if (setResults) {
              setResults((prevCards) =>
                prevCards.map((c) =>
                  c.ncid === item.ncid
                    ? { ...c, ...noteData }
                    : c
                )
              );
            }

            setboardPreview({});
            setisEditing(false);
            SortData();
            toast.success("Card Saved Successfully");
          } else {
            console.log("No matching document found.");
          }

          setisEditing(false);
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setisSaving(false);
    }
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    setIsEditingName(false);
  };

  return (
    <div className="bg-white relative w-full rounded-t-2xl">
      <div className="w-full h-[10vh] px-4 rounded-t-2xl bg-main flex items-center justify-between gap-3">
        {isEditingName ? (
          <form onSubmit={handleNameSubmit} className="flex-1">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={handleNameSubmit}
              className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold w-full outline-none"
              autoFocus
            />
          </form>
        ) : (
          <h1
            onClick={() => setIsEditingName(true)}
            className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold cursor-pointer"
          >
            {name.length > 15
              ? `${name.slice(0, 15)}..`
              : name}
          </h1>
        )}
        <div className="flex items-center gap-3">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="bg-white disabled:opacity-50 transition-all text-main font-bold p-2 rounded-full"
          >
            <IoMdCloud />
          </button>

          <Popover.Root>
            <Popover.Trigger asChild>
              <button className="bg-white transition-all text-main font-bold p-2 rounded-full">
                <IoColorFill />
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className="rounded-lg px-3 py-5 z-[10000] w-[180px] bg-white border shadow-md"
                sideOffset={10}
                side="bottom"
              >
                <div className="grid grid-cols-3 gap-3">
                  {bgColors.map((color) => (
                    <button
                      key={color}
                      onClick={() => setBgColor(color)}
                      style={{ backgroundColor: color }}
                      className={`w-8 h-8 border shadow ${bgColor === color ? "border-main/60" : "border-transparent"
                        } rounded-md`}
                    />
                  ))}
                </div>
                <Popover.Arrow className="fill-gray-200" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>

          <button
            onClick={() => setIsDeleteOpen(true)}
            className="bg-red-500 disabled:opacity-50 transition-all text-white font-bold p-2 rounded-full"
          >
            <HiTrash className="text-lg" />
          </button>
          <AlertDialog.Root open={isDeleteOpen}>
            <AlertDialog.Portal>
              <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
              <AlertDialog.Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-md focus:outline-none">
                <AlertDialog.Title className="text-red-600 text-xl font-semibold">
                  Are you absolutely sure?
                </AlertDialog.Title>
                <AlertDialog.Description className="text-gray-600 font-medium mt-2 mb-6">
                  This action cannot be undone. This will move{" "}
                  <span className="font-bold text-main">
                    {`${item.name} - ${item.type[0].toUpperCase() + item.type.slice(1)} Card`}
                  </span>{" "}
                  to the Trash page where you can restore it anytime.
                </AlertDialog.Description>
                <div className="flex justify-start gap-3">
                  <button
                    disabled={isDeleting}
                    onClick={() => {
                      HandleDelete(item);
                      setisEditing(false);
                      setIsDeleteOpen(false);
                    }}
                    className="px-4 py-2 bg-red-600 disabled:bg-red-400 text-white rounded-md hover:bg-red-700"
                  >
                    Move to Trash
                  </button>
                  <button
                    onClick={() => setIsDeleteOpen(false)}
                    className="px-4 py-2 bg-main/10 text-main/90 rounded-md hover:bg-main/20"
                  >
                    Cancel
                  </button>
                </div>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog.Root>

          <AlertDialog.Root open={isSaving}>
            <AlertDialog.Portal>
              <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
              <AlertDialog.Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[400px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-md focus:outline-none">
                <div className="flex items-center pb-3 pt-6 justify-center">
                  <l-helix
                    size={60} bg-opacity="0.1" speed="1.75" color="#1a1a1a"
                  ></l-helix>
                </div>
                <AlertDialog.Title className="text-center text-lg font-semibold mt-4">
                  Hold Still Saving Changes
                </AlertDialog.Title>
                <AlertDialog.Description className="text-center text-gray-500 mt-2">
                  Please wait while we save your changes. This might take a few moments.
                </AlertDialog.Description>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog.Root>

        </div>
      </div>
      <div
        ref={editorRef}
        id="editorjs"
        style={{ backgroundColor: bgColor }}
        className="w-full h-[85vh]  text-main placeholder:text-main overflow-y-auto px-4 py-3"
      />

      <div className="absolute z-[10000000] right-5 top-24 md:top-[80px] ">
        <Popover.Root>
          <Popover.Trigger asChild>
            <button className="bg-main text-white p-2 rounded-lg" >
              <RiAiGenerate className="text-xl" />
            </button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="rounded-lg border-2 border-main outline outline-2 outline-white z-[10000] w-[80vw] max-w-[400px] me-5 bg-white shadow-md"
              sideOffset={10}
              side="bottom"
            >
              <form
                onSubmit={handleFormSubmit}
                className="w-full flex items-center justify-between p-2 rounded-xl bg-white space-x-2"
              >
                <input
                  value={prompt}
                  autoFocus
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="What you want to Create..."
                  className="p-2 rounded-sm placeholder:text-main hover:outline-none bg-transparent focus:outline-none hover:ring-0 w-full text-main"
                />
                <button
                  type="submit"
                  className="focus:outline-none font-bold p-1 text-4xl rounded-full"
                  title="send"
                  disabled={loading}
                >
                  {loading ? (
                    <FaGear className="animate-spin p-[0.5rem] text-main" />
                  ) : (
                    <IoArrowUpCircle
                      className={` ${prompt.length < 1 ? "text-main" : "text-main rotate-90"
                        } transition-all duration-100 ease-linear`}
                    />
                  )}
                </button>
              </form>
              <Popover.Arrow className="fill-main/70" />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>

      <Toaster />

    </div>
  );
}

export default TodoEditor;
