import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const RingBar = ({ data, color }) => {
    const { completed, incomplete } = data;
    const totalItems = completed.count + incomplete.count;
    const percentage = Math.round((completed.count / totalItems) * 100);

    return (
        <div className="w-48 h-48">
            <CircularProgressbar
                value={percentage}
                text={percentage == 0 ? 'Not Started' : `${percentage}%`}
                background
                backgroundPadding={6}
                styles={buildStyles({
                    strokeLinecap: 'round',
                    textSize: '12px',
                    backgroundColor: "#1a1a1a",
                    textColor: color,
                    pathColor: color,
                    trailColor: "transparent",
                })}
                className=''
            />
        </div>
    );
};

export default RingBar;