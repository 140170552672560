import React, { useEffect } from 'react'
import { useData } from '../../context/DataContext'
import { MdOutlinePlayCircleFilled } from 'react-icons/md';
import { generateHTML } from '../../../common/methods';
import { ping } from "ldrs";

function RecentsCards() {
    const { cards, CardsLoading } = useData();

    useEffect(() => {
        ping.register();
    }, [])

    const renderCardHeader = (name) => (
        <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
            {name.length <= 10 ? name : `${name.slice(0, 10)}..`}
        </div>
    );

    const renderCardContent = (card) => {
        switch (card.type) {
            case "image":
                return (
                    <div className="relative bg-white border shadow-sm rounded-md" style={{ backgroundColor: card.color }}>
                        <img className="w-full h-[300px] object-cover grayscale rounded-md" src={card.data} alt={card.name} />
                        <div className="absolute top-3 start-3">
                            {renderCardHeader(card.name)}
                        </div>
                    </div>
                );
            case "note":
                return (
                    <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
                        <div className="flex justify-between">{renderCardHeader(card.name)}</div>
                        <div className="h-[230px] overflow-y-auto my-2">
                            <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                        </div>
                    </div>
                );
            case "todo":
                return (
                    <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
                        <div className="flex justify-between">{renderCardHeader(card.name)}</div>
                        <div className="h-[230px] overflow-y-auto my-2">
                            <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                        </div>
                    </div>
                );
            case "sketch":
                return (
                    <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
                        <div className="flex justify-between">{renderCardHeader(card.name)}</div>
                        <div className="h-[200px] my-2" dangerouslySetInnerHTML={{ __html: card.data }} />
                    </div>
                );
            case "audio":
                return (
                    <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
                        <div className="flex justify-between">{renderCardHeader(card.name)}</div>
                        <div className="h-[200px] flex items-center justify-center p-4">
                            <MdOutlinePlayCircleFilled className="text-8xl text-white border-dashed border-[3px] border-white rounded-full" />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {CardsLoading ? <div className="flex items-center flex-col w-full h-[200px] mb-5 px-3 justify-center ">
                <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
            </div> :
                <div className="">
                    {cards.length < 1 ?
                        <div className="flex items-center flex-col w-full h-full mb-5 px-3 justify-center ">
                            <img src="https://ik.imagekit.io/vituepzjm/No%20data-pana.svg?updatedAt=1724334371763" alt="NO DATA" className="size-52" />
                            <p className="text-main text-xl break-words">Not Created Any Cards Yet !! </p>
                        </div>
                        :
                        <div className="grid gap-5 h-auto pb-5 overflow-y-auto px-3 md:grid-cols-1 grid-cols-1 lg:grid-cols-2 sm:grid-cols-2 xl:grid-cols-3">
                            {cards.length > 0 && cards.slice(0, 3).map((card, index) => <div key={index}>{renderCardContent(card)}</div>)}
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default RecentsCards