import React, { useEffect, useState } from "react";
import { useData } from "../../../context/DataContext";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import { generateHTML, ParseDate } from "../../../../common/methods";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
import Card from "./preview/Card";
import { BsThreeDotsVertical } from "react-icons/bs";
import * as Popover from "@radix-ui/react-popover";
import * as Dialog from '@radix-ui/react-dialog';
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { FaXmark } from "react-icons/fa6";
import { useUserAuth } from "../../../context/UserAuthContext";
import { addDoc, collection, deleteDoc, getDocs, query, where, writeBatch } from "firebase/firestore";
import { db } from "../../../../config/firebase";

function NoteCardTrash() {
    const { setTitle, previewCardTrash, TrashcardsLoading, Trashcards, setTrashCards, setpreviewCardTrash, setCards } = useData();
    const [isDeleteOpen, setisDeleteOpen] = useState(false);
    const [isDeleting, setisDeleting] = useState(false);
    const [isRestoring, setisRestoring] = useState(false);
    const [isDelete, setisDelete] = useState(false);
    const { user } = useUserAuth();

    const HandleRestore = async (card) => {
        try {
            setisRestoring(true);
            const updatedCard = { ...card, updatedAt: new Date() };
            const cardQuery = query(
                collection(db, "notecardtrash"),
                where("ncid", "==", card.ncid)
            );
            const querySnapshot = await getDocs(cardQuery);
            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await addDoc(collection(db, "notecard"), updatedCard);
                await deleteDoc(docRef);
                setTrashCards((prevCards) => prevCards.filter((ele) => ele.ncid !== card.ncid));
                setCards((prevTrash) => [updatedCard, ...prevTrash]);
                setpreviewCardTrash({});
            } else {
                console.error("Error: Card not found.");
            }
        } catch (error) {
            console.error("Error deleting card:", error);
        }
        finally {
            setisRestoring(false);
        }
    }

    const HandleDelete = async (card) => {
        try {
            setisDelete(true);
            const cardQuery = query(
                collection(db, "notecardtrash"),
                where("ncid", "==", card.ncid)
            );
            const querySnapshot = await getDocs(cardQuery);
            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;
                await deleteDoc(docRef);
                setTrashCards((prevCards) => prevCards.filter((ele) => ele.ncid !== card.ncid));
                setpreviewCardTrash({});
            } else {
                console.error("Error: Card not found.");
            }
        } catch (error) {
            console.error("Error deleting card:", error);
        }
        finally {
            setisDelete(false);
        }
    };

    const HandleEmptyTrash = async () => {
        setisDeleting(true);
        try {
            const userTrashSnapshot = await fetchUserTrash();
            if (!userTrashSnapshot.empty) {
                await deleteAllUserTrash(userTrashSnapshot);
                updateLocalStateAfterDeletion();
                setisDeleteOpen(false);
            } else {
                console.error("Error: No trash items found for the user.");
            }
        } catch (error) {
            console.error("Error emptying trash:", error);
        } finally {
            setisDeleting(false);
        }
    };

    const fetchUserTrash = async () => {
        const userTrashQuery = query(
            collection(db, "notecardtrash"),
            where("uid", "==", user.uid)
        );
        return await getDocs(userTrashQuery);
    };

    const deleteAllUserTrash = async (snapshot) => {
        const batch = writeBatch(db);
        snapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });
        await batch.commit();
    };

    const updateLocalStateAfterDeletion = () => {
        setpreviewCardTrash({});
        setTrashCards([]);
    };

    useEffect(() => {
        setTitle("NoteBoard Trash");

        return () => {
            setTitle("");
        };
    }, [setTitle]);

    const isNotEmptyObject = (obj) => Object.keys(obj).length > 0;

    const Settings = (card) => (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button className="bg-gray-100 text-gray-800 p-2 rounded-md">
                    <BsThreeDotsVertical />
                </button>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    sideOffset={5}
                    side="left"
                    className="mt-12 rounded-md p-2 flex flex-col border border-gray-300 bg-white shadow-md w-48"
                >
                    <button disabled={isRestoring} onClick={() => HandleRestore(card)} className="ps-3 py-1 w-full text-start text-gray-700 hover:bg-gray-100 rounded-md disabled:opacity-50">
                        Restore
                    </button>
                    <button disabled={isDelete} onClick={() => HandleDelete(card)} className="ps-3 py-1 w-full text-start text-red-700 hover:bg-red-100 rounded-md disabled:bg-red-400">
                        Delete Forever
                    </button>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );

    return (
        <div className="flex w-full h-full gap-3">
            <div className={`bg-white h-full overflow-auto rounded-md shadow-md p-3 w-full ${isNotEmptyObject(previewCardTrash) ? "lg:w-[50%] xl:w-[60%]" : "lg:w-full"} lg:min-w-[300px] xl:min-w-[400px] border border-gray-300`}>
                <div className="flex items-center mt-2 mb-10 justify-between gap-5">
                    <div className="ms-2 flex">
                        <Link to='/dashboard/noteboard'>
                            <IoArrowBackCircle className="text-4xl focus:outline-none text-main" />
                        </Link>
                    </div>
                    <div className="flex me-2">
                        {Trashcards.length > 0 && <button onClick={() => setisDeleteOpen(true)} className="text-red-700 hover:underline underline-offset-2 font-medium">
                            Empty Trash
                        </button>}
                        <Dialog.Root open={isDeleteOpen}>
                            <Dialog.Portal>
                                <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
                                <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-md focus:outline-none">
                                    <Dialog.Title className="text-mauve12 m-0 text-xl font-semibold">
                                        Delete Permenantly
                                    </Dialog.Title>
                                    <Dialog.Description className="text-mauve11 font-normal mt-[10px] mb-5 text-[15px] leading-normal">
                                        Are you sure you want to permanently erase the items in Trash?
                                    </Dialog.Description>

                                    <div className="flex justify-start gap-3">
                                        <button disabled={isDeleting} onClick={() => HandleEmptyTrash()} className="px-4 py-2 bg-red-600 disabled:bg-red-400 text-white rounded-md hover:bg-red-700">
                                            Delete
                                        </button>
                                        <button onClick={() => setisDeleteOpen(false)} className="px-4 py-2 bg-main/10 text-main/90 rounded-md hover:bg-main/20">
                                            Cancel
                                        </button>
                                    </div>

                                    <button
                                        onClick={() => setisDeleteOpen(false)}
                                        className="text-main hover:bg-gray-100  absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:outline-none"
                                    >
                                        <FaXmark />
                                    </button>

                                </Dialog.Content>
                            </Dialog.Portal>
                        </Dialog.Root>
                    </div>
                </div >

                {TrashcardsLoading ? (
                    <div className="flex w-full h-[50vh] items-center justify-center text-main" >
                        <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
                    </div>
                ) : (
                    <div>
                        {(Trashcards && Trashcards.length === 0) ? (
                            <div className="flex flex-col items-center justify-center w-full h-[60vh]">
                                <img src="https://ik.imagekit.io/vituepzjm/undraw_taken_re_yn20.svg?updatedAt=1724617636363" alt="No data" className="size-36 mb-3" />
                                <p className="text-2xl italic text-center text-main/50 font-serif font-normal">Looks Like, You've got an empty trash can.</p>
                            </div>
                        ) : (
                            <div className={`grid gap-5 w-full md:grid-cols-1  sm:grid-cols-2 ${isNotEmptyObject(previewCardTrash) ? "xl:grid-cols-1 lg:grid-cols-1" : "xl:grid-cols-3 lg:grid-cols-2"}`}>
                                {Trashcards?.map((card, index) => (
                                    <div key={index}>
                                        {card.type === "image" && (
                                            <div
                                                style={{ backgroundColor: card.color }}
                                                className="relative bg-white border shadow-sm rounded-md"
                                            >
                                                <img
                                                    onClick={() => {
                                                        const selfCheck = previewCardTrash?.ncid === card.ncid;
                                                        setpreviewCardTrash(selfCheck ? {} : card);
                                                    }}
                                                    className="w-full h-[300px] object-cover brightness-95 rounded-md"
                                                    src={card.data}
                                                    alt={card.name}
                                                />
                                                <div className="absolute top-3 start-3">
                                                    <h3 className="text-main px-2 py-1 bg-white rounded-md w-fit">
                                                        {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                                                    </h3>
                                                </div>
                                                <div className="absolute top-3 end-3">
                                                    {Settings(card)}
                                                </div>
                                                <div className="absolute bottom-3 start-3">
                                                    <h3 className="text-main px-2 text-sm py-1 bg-white rounded-md w-fit">
                                                        {ParseDate(card.updatedAt)}
                                                    </h3>
                                                </div>
                                            </div>
                                        )}
                                        {card.type === "note" && (
                                            <div
                                                style={{ backgroundColor: card.color }}
                                                className="h-[300px] p-3 w-full border rounded-md shadow-sm"
                                            >
                                                <div className="w-full flex items-center justify-between">
                                                    <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                                                        {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                                                    </div>
                                                    {Settings(card)}
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        const selfCheck = previewCardTrash?.ncid === card.ncid;
                                                        setpreviewCardTrash(selfCheck ? {} : card);
                                                    }}
                                                    className="h-[200px] overflow-y-auto my-2 mx-2"
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                                                </div>

                                                <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                                                    {ParseDate(card.updatedAt)}
                                                </div>
                                            </div>
                                        )}
                                        {card.type === "todo" && (
                                            <div
                                                style={{ backgroundColor: card.color }}
                                                className="h-[300px] p-3 w-full border rounded-md shadow-sm"
                                            >
                                                <div className="w-full flex items-center justify-between">
                                                    <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                                                        {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                                                    </div>
                                                    {Settings(card)}
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        const selfCheck = previewCardTrash?.ncid === card.ncid;
                                                        setpreviewCardTrash(selfCheck ? {} : card);
                                                    }}
                                                    className="h-[200px] overflow-y-auto my-2 mx-2"
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
                                                </div>

                                                <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                                                    {ParseDate(card.updatedAt)}
                                                </div>
                                            </div>
                                        )}
                                        {card.type === "sketch" && (
                                            <div
                                                style={{ backgroundColor: card.color }}
                                                className="h-[300px] p-3 w-full border rounded-md shadow-sm"
                                            >
                                                <div className="flex w-full items-center justify-between">
                                                    <div className="w-fit top-3 left-3 px-2 py-1 rounded-md bg-white text-main">
                                                        {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                                                    </div>
                                                    {Settings(card)}
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        const selfCheck = previewCardTrash?.ncid === card.ncid;
                                                        setpreviewCardTrash(selfCheck ? {} : card);
                                                    }}
                                                    className="z-[100] my-2"
                                                >
                                                    <div
                                                        className="w-full h-[200px]"
                                                        dangerouslySetInnerHTML={{ __html: card?.data }}
                                                    />
                                                </div>

                                                <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                                                    {ParseDate(card.updatedAt)}
                                                </div>
                                            </div>
                                        )}
                                        {card.type === "audio" && (
                                            <div
                                                style={{ backgroundColor: card.color }}
                                                className="h-[300px] p-3 w-full border rounded-md shadow-sm"
                                            >
                                                <div className="w-full flex items-center justify-between">
                                                    <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
                                                        {card.name.length <= 10 ? card.name : `${card.name.slice(0, 10)}..`}
                                                    </div>
                                                    {Settings(card)}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        const selfCheck = previewCardTrash?.ncid === card.ncid;
                                                        setpreviewCardTrash(selfCheck ? {} : card);
                                                    }}
                                                    className="h-[200px] my-2 flex items-center justify-center p-4"
                                                >
                                                    <MdOutlinePlayCircleFilled className="text-8xl text-white border-dashed border-[3px] border-white rounded-full" />
                                                </div>

                                                <div className="w-fit bottom-3 left-3 px-2 py-1 text-sm rounded-md bg-white text-main">
                                                    {ParseDate(card.updatedAt)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>)}
            </div >
            {isNotEmptyObject(previewCardTrash) && <div style={{ backgroundColor: previewCardTrash.color }} className=" rounded-md shadow-md lg:block hidden lg:w-[50%] xl:w-[60%] border border-gray-300">
                <Card isRestoring={isRestoring} HandleRestore={HandleRestore} isDelete={isDelete} HandleDelete={HandleDelete} item={previewCardTrash} />
            </div>}
        </div >
    );
}

export default NoteCardTrash;
