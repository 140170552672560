import React from 'react'

function Widgets() {
  return (
    <div class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div class="mb-4 flex items-center justify-center gap-8 sm:mb-8 md:mb-12">
          <h2 class="text-4xl text-center font-bold text-gray-800">Manage Your Personal Tools Kit</h2>
        </div>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 md:gap-6 xl:gap-8">

          <div href="#" class="group relative flex items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg h-96">
            <img src="https://ik.imagekit.io/vituepzjm/count.png?updatedAt=1725559264218" loading="lazy" alt="Photo by Minh Pham" class="absolute inset-0 h-full w-full transition duration-200 " />

            <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-main via-main/5 to-transparent opacity-50"></div>

            <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">NoteCard Counts</span>
          </div>



          <div href="#" class="group relative flex items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 h-96">
            <img src="https://ik.imagekit.io/vituepzjm/Calender.png?updatedAt=1725559327559" loading="lazy" alt="Photo by Magicle" class="absolute inset-0 h-full w-full transition duration-200  object-cover md:object-none" />

            <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-main via-main/5 to-transparent opacity-50"></div>

            <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Calender & Time</span>
          </div>


          <div href="#" class="group relative flex items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg h-96">
            <img src="https://ik.imagekit.io/vituepzjm/Stop%20Watch.png?updatedAt=1725559269290" loading="lazy" alt="Photo by Minh Pham" class="absolute inset-0 h-full w-full transition duration-200 " />

            <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-main via-main/5 to-transparent opacity-50"></div>

            <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Stopwatch</span>
          </div>

          <div href="#" class="group relative flex items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg h-96">
            <img src="https://ik.imagekit.io/vituepzjm/Calculator.png?updatedAt=1725559267490" loading="lazy" alt="Photo by Minh Pham" class="absolute inset-0 h-full w-full transition duration-200 " />

            <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-main via-main/5 to-transparent opacity-50"></div>

            <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Calculator</span>
          </div>


          <div href="#" class="group relative flex items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg h-96">
            <img src="https://ik.imagekit.io/vituepzjm/EmbedCard.png?updatedAt=1725559261339" loading="lazy" alt="Photo by Minh Pham" class="absolute inset-0 h-full w-full transition duration-200 " />

            <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-main via-main/5 to-transparent opacity-50"></div>

            <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Embed Cards</span>
          </div>


          <div href="#" class="group relative flex items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-2 h-96">
            <img src="https://ik.imagekit.io/vituepzjm/Maps.png?updatedAt=1725559274804" loading="lazy" alt="Photo by Martin Sanchez" class="absolute inset-0 h-full w-full transition duration-200 object-cover md:object-none" />

            <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-main via-main/5 to-transparent opacity-50"></div>

            <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">Maps</span>
          </div>



          <div href="#" class="group relative flex items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg h-96">
            <img src="https://ik.imagekit.io/vituepzjm/NewsFeed.png?updatedAt=1725559327464" loading="lazy" alt="Photo by Lorenzo Herrera" class="absolute inset-0 h-full w-full transition duration-200 " />

            <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-main via-main/5 to-transparent opacity-50"></div>

            <span class="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">News Feed</span>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Widgets