import { ReactSketchCanvas } from "react-sketch-canvas";
import { useEffect, useRef, useState } from "react";
import { BsEraserFill, BsJoystick } from "react-icons/bs";
import { RiArrowDropDownLine, RiPencilFill } from "react-icons/ri";
import * as Dialog from "@radix-ui/react-dialog";
import { CgRedo, CgUndo } from "react-icons/cg";
import { LuXCircle } from "react-icons/lu";
import * as Popover from "@radix-ui/react-popover";
import { saveAs } from "file-saver";
import { TbFileDownload, TbLayoutGridFilled } from "react-icons/tb";
import { useHotkeys } from "react-hotkeys-hook";
import { IoGrid } from "react-icons/io5";
import LoadingPage from "../../Loading/LoadingPage";
import { MdSimCardDownload } from "react-icons/md";
import { FaCloud } from "react-icons/fa";

export default function App() {
  const canvasRef = useRef(null);
  const [data, setData] = useState("");
  const [Url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [eraseMode, setEraseMode] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(5);
  const [eraserWidth, setEraserWidth] = useState(10);
  const [strokeColor, setStrokeColor] = useState("#1a1a1a");
  const [canvasColor, setCanvasColor] = useState("#ffffff");
  const [isLoading, setIsLoading] = useState(true);

  useHotkeys("ctrl+z", (event) => {
    event.preventDefault();
    handleUndoClick();
  });

  useHotkeys("ctrl+y", (event) => {
    event.preventDefault();
    handleRedoClick();
  });

  useHotkeys("ctrl+s", (event) => {
    event.preventDefault();
    setOpen(false);
    SaveImage();
    setOpen(true);
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  // if (isLoading) {
  //   return <LoadingPage />;
  // }

  const handleEraserClick = () => {
    setEraseMode(true);
    canvasRef.current?.eraseMode(true);
  };

  const handlePenClick = () => {
    setEraseMode(false);
    canvasRef.current?.eraseMode(false);
  };

  const handleStrokeColorChange = (event) => {
    setStrokeColor(event.target.value);
  };

  const handleCanvasColorChange = (event) => {
    setUrl("");
    setCanvasColor(event.target.value);
  };

  const handleStrokeWidthChange = (event) => {
    setStrokeWidth(Number(event.target.value));
  };

  const handleUndoClick = () => {
    canvasRef.current?.undo();
  };

  const handleRedoClick = () => {
    canvasRef.current?.redo();
  };

  const handleClearClick = () => {
    canvasRef.current?.clearCanvas();
  };

  const SaveImage = async () => {
    try {
      setData("");
      const data = await canvasRef.current.exportSvg();
      setData(data);
      console.log("hello");
    } catch (e) {
      console.log(e);
    }
  };

  const DownloadImage = () => {
    const blob = new Blob([data], { type: "image/svg+xml" });
    saveAs(blob, `${new Date().toISOString()}.svg`);
  };

  const handleEraserWidthChange = (event) => {
    setEraserWidth(Number(event.target.value));
  };

  return (
    <div className="flex flex-col items-center justify-end bg-main/30 ">
      <div className="flex flex-col items-center justify-center relative w-full h-[95vh] gap-4">
        <div className="flex scale-75 md:scale-100 absolute top-1/2 -translate-y-1/2 right-5 gap-5 flex-col w-fit bg-gray-100/50 border z-[1000] px-3 py-5 rounded-xl backdrop-blur-md items-end">
          <span className="size-8 border border-main rounded-full overflow-hidden">
            <input
              type="color"
              value={strokeColor}
              title="Stroke Color"
              className="w-[200%] h-[200%] bg-transparent border-none cursor-pointer appearance-none transform-cpu -translate-x-1/4 -translate-y-1/4"
              onChange={handleStrokeColorChange}
            />
          </span>

          <span className="size-8 border border-main rounded-full overflow-hidden">
            <input
              type="color"
              value={canvasColor}
              title="Canvas Color"
              className="w-[200%] h-[200%] bg-transparent border-none cursor-pointer appearance-none transform-cpu -translate-x-1/4 -translate-y-1/4"
              onChange={handleCanvasColorChange}
            />
          </span>

          <span className="lg:block hidden">
            <Popover.Root>
              <Popover.Trigger asChild>
                <button
                  type="button"
                  title="Background Image"
                  className={`p-2 bg-main text-white rounded-full group`}
                >
                  <IoGrid className="" />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="rounded px-3 py-5 z-[10000] me-5 w-[180px] bg-white border shadow-md will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                  side="left"
                >
                  <div className="grid grid-cols-3 place-items-center gap-3">
                    <button
                      onClick={() => {
                        setUrl("");
                      }}
                      className="w-8 h-8 text-white bg-white border border-main/20 rounded-md"
                    ></button>

                    <button
                      onClick={() => {
                        setCanvasColor("#ffffff");
                        setUrl(
                          "https://ik.imagekit.io/santech/10169742.jpg?updatedAt=1723225687751"
                        );
                      }}
                      className=""
                    >
                      <img
                        src="https://ik.imagekit.io/vituepzjm/demo/image.png?updatedAt=1723224891897"
                        alt="boxes"
                        className="w-8 h-8 rounded-md border border-main/20"
                      />
                    </button>

                    <button
                      onClick={() => {
                        setCanvasColor("#ffffff");
                        setUrl(
                          "https://ik.imagekit.io/santech/v602-nunoon-32-rippednotes.jpg?updatedAt=1723225662503"
                        );
                      }}
                      className=""
                    >
                      <img
                        src="https://ik.imagekit.io/vituepzjm/Scribby/image.png?updatedAt=1723224054349"
                        alt="boxes"
                        className="w-8 h-8 rounded-md border border-main/20"
                      />
                    </button>

                    <button
                      onClick={() => {
                        setCanvasColor("#ffffff");
                        setUrl(
                          "https://ik.imagekit.io/vituepzjm/Scribby/sl_072622_51930_13.jpg?updatedAt=1723221327760"
                        );
                      }}
                      className=""
                    >
                      <img
                        src="https://ik.imagekit.io/vituepzjm/demo/image.png?updatedAt=1723224975295"
                        alt="boxes"
                        className="w-8 h-8 rounded-md border border-main/20"
                      />
                    </button>

                    <button
                      onClick={() => {
                        setCanvasColor("#ffffff");
                        setUrl(
                          "https://ik.imagekit.io/santech/blank-lined-paper-background-texture-art-paper-design-your-work_35956-4069.jpg?updatedAt=1723225768909"
                        );
                      }}
                      className=""
                    >
                      <img
                        src="https://ik.imagekit.io/santech/image.png?updatedAt=1723226073577"
                        alt="boxes"
                        className="w-8 h-8 rounded-md border border-main/20"
                      />
                    </button>
                  </div>

                  <Popover.Arrow className="fill-gray-200" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </span>

          <span title="Pen" className="">
            <Popover.Root>
              <Popover.Trigger asChild>
                <button
                  type="button"
                  disabled={eraseMode}
                  className={`ps-1 py-2 rounded-s-md group ${!eraseMode ? "bg-main text-white" : "text-main bg-white"
                    }`}
                >
                  <RiArrowDropDownLine className="rotate-90 group-active:scale-95 transition-all" />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="rounded px-3 py-5 z-[10000] me-5 w-[250px] bg-white border shadow-md will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                  side="left"
                >
                  <div className="flex flex-col gap-5">
                    <label
                      htmlFor="strokeWidth"
                      className="text-sm font-medium"
                    >
                      Stroke width
                    </label>
                    <input
                      disabled={eraseMode}
                      type="range"
                      className="range-slider accent-main disabled:accent-gray-200 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      min="0"
                      max="40"
                      step="5"
                      id="strokeWidth"
                      value={strokeWidth}
                      onChange={handleStrokeWidthChange}
                    />
                  </div>
                  <Popover.Arrow className="fill-gray-200" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <button
              type="button"
              className={`pe-2 ps-1 py-2 rounded-e-md group ${!eraseMode ? "bg-main text-white" : "text-main bg-white"
                }`}
              disabled={!eraseMode}
              onClick={handlePenClick}
            >
              <RiPencilFill className="group-active:scale-95 transition-all" />
            </button>
          </span>

          <span title="Eraser" className="">
            <Popover.Root>
              <Popover.Trigger asChild>
                <button
                  type="button"
                  disabled={!eraseMode}
                  className={`ps-1 py-2 rounded-s-md group  ${eraseMode ? "bg-main text-white" : "text-main bg-white"
                    }`}
                >
                  <RiArrowDropDownLine className="rotate-90 group-active:scale-50 transition-all" />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="rounded px-3 py-5 z-[10000] me-5 w-[250px] bg-white border shadow-md will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                  side="left"
                >
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="eraserWidth"
                      className="text-sm font-medium"
                    >
                      Eraser width
                    </label>
                    <input
                      disabled={!eraseMode}
                      type="range"
                      className="range-slider w-full h-2 accent-main disabled:accent-gray-200 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      min="0"
                      max="40"
                      step="5"
                      id="eraserWidth"
                      value={eraserWidth}
                      onChange={handleEraserWidthChange}
                    />
                  </div>
                  <Popover.Arrow className="fill-gray-200" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <button
              type="button"
              className={`pe-2 ps-1 py-2 rounded-e-md group  ${eraseMode ? "bg-main text-white" : "text-main bg-white"
                }`}
              disabled={eraseMode}
              onClick={handleEraserClick}
            >
              <BsEraserFill className="group-active:scale-95 transition-all" />
            </button>
          </span>

          <span title="Undo" className="">
            <button
              type="button"
              className={`px-3 py-2 rounded-md transition-all text-white bg-main active:scale-90 `}
              onClick={handleUndoClick}
            >
              <CgUndo />
            </button>
          </span>

          <span title="Redo" className="">
            <button
              type="button"
              className={`px-3 py-2 rounded-md transition-all text-white bg-main active:scale-90 `}
              onClick={handleRedoClick}
            >
              <CgRedo />
            </button>
          </span>

          <span title="Clear" className="">
            <button
              type="button"
              className={`px-3 py-2 rounded-md transition-all text-white bg-main active:scale-90 `}
              onClick={handleClearClick}
            >
              <LuXCircle />
            </button>
          </span>

          <span title="Save" className="">
            <Dialog.Root open={open} onOpenChange={setOpen}>
              <Dialog.Trigger asChild>
                <button
                  type="button"
                  className={`px-3 py-2 rounded-md transition-all text-white bg-main active:scale-90 `}
                  onClick={SaveImage}
                >
                  <FaCloud />
                </button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="bg-main/20 z-[100] data-[state=open]:animate-overlayShow fixed inset-0" />
                <Dialog.Content className="z-[1000] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                  <Dialog.Title className="text-main m-0 text-2xl font-semibold">
                    Sketch saved and ready to go
                  </Dialog.Title>
                  <Dialog.Description className="text-main mt-[10px] font-normal mb-5 text-base">
                    Your sketch has been saved to your Noteboard. You can now
                    easily export it whenever you're ready.{" "}
                  </Dialog.Description>
                  <span className="flex items-center justify-start gap-3">
                    <button
                      type="button"
                      className={`px-3 py-2 text-sm rounded-lg transition-all text-white bg-main active:scale-90 `}
                    >
                      Finished Editing
                    </button>
                    <button
                      type="button"
                      className={`px-3 py-2 rounded-lg transition-all text-white bg-main active:scale-90 `}
                      onClick={DownloadImage}
                    >
                      <MdSimCardDownload className="text-lg" />
                    </button>
                  </span>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </span>
        </div>
        <span className="absolute transition-all rounded-xl bg-main text-white top-5 left-5">
          <h1
            className="text-base text-white bg-main rounded-md px-4 py-2 font-bold cursor-pointer"
          >
            SketchCard
          </h1>
        </span>
        <ReactSketchCanvas
          ref={canvasRef}
          readOnly={false}
          backgroundImage={Url}
          preserveBackgroundImageAspectRatio={"none"}
          strokeWidth={strokeWidth}
          eraserWidth={eraserWidth}
          strokeColor={strokeColor}
          canvasColor={canvasColor}
          className="h-full w-full cursor-grab"
        />
      </div>
    </div>
  );
}
