import React, { useEffect, useState } from "react";
import { useData } from "../../context/DataContext";

function CardCount() {
  const { cards, books } = useData();
  const [counts, setCounts] = useState({
    image: 0,
    sketch: 0,
    note: 0,
    todo: 0,
  });

  useEffect(() => {
    const newCounts = { image: 0, sketch: 0, note: 0, todo: 0 };

    cards.forEach((item) => {
      if (newCounts[item.type] !== undefined) {
        newCounts[item.type]++;
      }
    });

    setCounts(newCounts);
  }, [cards]);

  const formatCount = (count) => (count < 10 ? `0${count}` : count);

  return (
    <div className="">
      {cards.length + books.length > 0 ? <div className="flex flex-col items-start gap-6 justify-center">
        <div>
          <h1 className="text-2xl font-semibold dark:text-main text-white">Notebook Counts</h1>
          <p className="bg-white dark:bg-main text-main dark:text-white rounded-md px-3 py-2 mt-3 w-fit">
            {formatCount(books.length)} - Books
          </p>
        </div>
        <div>
          <h1 className="text-2xl font-semibold dark:text-main text-white">NoteCard Counts</h1>
          <span className="flex flex-wrap items-center justify-start gap-3">
            {Object.entries(counts).map(([type, count]) => (
              <p
                key={type}
                className="bg-white dark:bg-main text-main dark:text-white rounded-md px-3 py-2 mt-3"
              >
                {type.charAt(0).toUpperCase() + type.slice(1)} - {formatCount(count)}
              </p>
            ))}
          </span>
        </div>
      </div> : <div className="flex flex-col items-center justify-center" >
        <img src="https://ik.imagekit.io/vituepzjm/No%20data-cuate.svg?updatedAt=1724311699660" alt="NO DATA" className="size-48" />
        <p className="text-white break-words">Not Created Any Cards and Books Yet :( </p>
      </div>}
    </div>
  );
}

export default CardCount;
