import React, { useState, useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { FaPause, FaPlay } from "react-icons/fa";
import { FaRedoAlt } from "react-icons/fa";

const Stopwatch = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const intervalRef = useRef(null);

  useHotkeys("s", (event) => {
    event.preventDefault();
    handleStartStop();
  });

  useHotkeys("r", (event) => {
    event.preventDefault();
    handleReset();
  });

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 100);
      }, 100);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }

    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  const handleStartStop = () => {
    setIsRunning((prevIsRunning) => !prevIsRunning);
  };

  const handleReset = () => {
    setIsRunning(false);
    setTime(0);
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    const ms = Math.floor((milliseconds % 1000) / 10);
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}:${String(ms).padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col items-center border-dashed border-2 border-white rounded-lg justify-center h-[300px]">
      <div className="text-6xl text-white font-bold mb-4">
        {formatTime(time)}
      </div>
      <span className="flex items-center justify-center gap-3">
        {isRunning ? (
          <button
            className="p-3 rounded-lg bg-white text-main"
            onClick={handleStartStop}
          >
            <FaPause className="" />
          </button>
        ) : (
          <button
            className="p-3 rounded-lg bg-white text-main"
            onClick={handleStartStop}
          >
            <FaPlay className="ms-[0.065rem]" />
          </button>
        )}

        <button
          className="bg-white text-main inline-flex items-center justify-center gap-2 py-2 px-4 rounded focus:outline-none active:scale-95 transition-all"
          onClick={handleReset}
        >
          <FaRedoAlt /> Reset
        </button>
      </span>
    </div>
  );
};

export default Stopwatch;
