import React from 'react'
import { Link } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext';

function Hero() {
    const { user } = useUserAuth();
    const buttonText = user ? "Go to your Space" : "Access Your Scribby Now";
    const buttonLink = user ? "/dashboard/overview" : "/access-now";
    return (
        <section class="flex h-fit py-24 md:py-0 md:h-[80vh] flex-col bg-contain items-center justify-center bg-[url(https://dashboard.algolia.com/client-assets/c1c9361fe75370d1b156733e962f7214/514f2ec3798090c6df00dad1592c8166.svg)]">
            <div class="flex max-w-3xl flex-col px-6 items-center text-center lg:pb-48 lg:pt-32">
                {/* <img
                    src="https://ik.imagekit.io/vituepzjm/Scribby/7.svg?updatedAt=1722814793279"
                    alt="Scribby Logo"
                    className="bg-main rounded-xl  mb-3 size-[100px]"
                /> */}
                <h1 class="mb-8 text-4xl font-bold text-black sm:text-5xl md:mb-6 md:text-6xl">Why Choose Scribby?</h1>
                <h2 class="mb-8 text-xl font-bold text-black md:mb-12">Scribby is your one-stop shop for staying organized. Take notes, plan projects, and easily find what you need, all in one place.</h2>
                <Link to={buttonLink}>
                    <button className="group/button relative inline-flex items-center justify-center overflow-hidden rounded-md bg-main backdrop-blur-lg px-6 py-4 text-base font-semibold text-white transition-all duration-300 ease-in-out hover:scale-[1.03] border border-white/20">
                        <span className="text-lg">{buttonText}</span>
                        <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
                            <div className="relative h-full w-10 bg-white/30"></div>
                        </div>
                    </button>
                </Link>
            </div>
        </section>
    )
}

export default Hero