import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import LoadingPage from "../Loading/LoadingPage";
import Hero from "./why/Hero";
import Links from "./components/Links";
import Footer from "./components/Footer";
import Widgets from "./why/Widgets";
import Noteboard from "./why/Noteboard";
import NoteBook from "./why/NoteBook";
import Collabration from "./why/Collabration";

function About() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <div>
      <Banner />
      <Navbar />
      <Hero />
      <Widgets />
      <Noteboard />
      <NoteBook />
      <Collabration />
      <Links />
      <Footer />
    </div>
  );
}

export default About;
