import { useEffect } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";

const App = () => {
  const recorderControls = useVoiceVisualizer();
  const { recordedBlob, error } = recorderControls;

  useEffect(() => {
    if (!recordedBlob) return;

    console.log(recordedBlob);
  }, [recordedBlob]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <div className="bg-main">
      <VoiceVisualizer
        width={"80%"}
        height={"300"}
        speed={1}
        barWidth={2}
        gap={1}
        rounded={5}
        animateCurrentPick={true}
        backgroundColor="#fff"
        mainBarColor="#1a1a1a"
        defaultAudioWaveIconColor="#1a1a1a"
        defaultMicrophoneIconColor="#1a1a1a"
        controls={recorderControls}
      />
    </div>
  );
};

export default App;
