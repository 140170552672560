import React, { useEffect, useState } from 'react';
import { useData } from '../../context/DataContext';
import { Excalidraw, MainMenu } from '@excalidraw/excalidraw';

function PresentCanva() {
    const { setTitle } = useData();
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        setTitle("Present Canva");
        return () => setTitle("");
    }, [setTitle]);

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem('excalidrawData'));
        if (savedData && excalidrawAPI) {
            console.log(savedData)
            setData(savedData)
        }
    }, [excalidrawAPI]);

    const handleExcalidrawChange = (elements, appState) => {
        if (elements.length > 0) {
            const exportData = {
                elements: elements,
                appState: appState
            };
            localStorage.setItem('excalidrawData', JSON.stringify(exportData));
        }
    };

    return (
        <div className="flex w-full h-full gap-3">
            <div className="h-full w-full bg-white rounded-md p-2">
                <Excalidraw
                    initialData={data}
                    onChange={handleExcalidrawChange}
                    excalidrawAPI={(api) => setExcalidrawAPI(api)} // Assign API through callback
                >
                    <MainMenu>
                        <MainMenu.DefaultItems.ChangeCanvasBackground />
                        <MainMenu.DefaultItems.ClearCanvas />
                        <MainMenu.DefaultItems.Export />
                    </MainMenu>
                </Excalidraw>
            </div>
        </div>
    );
}

export default PresentCanva;
