import React from 'react'

function Quotes() {
  return (
    <section class="text-main/60 body-font">
      <div class="w-full px-5 py-12 mx-auto">
        <div class="md:w-4/5 w-full mx-auto text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block size-12 text-main mb-8" viewBox="0 0 975.036 975.036">
            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
          </svg>
          <p class="leading-relaxed font-medium text-lg">
            Scribby Saved My Sanity (and My Grades!)
            As a college student juggling multiple classes and a part-time job, staying organized felt like a losing battle. Then I discovered Scribby.

            Scribby's intuitive interface and powerful features have been a lifesaver. I love how I can easily create different types of notes, from basic text to complex code snippets, and even include images and diagrams. It's like having a digital notebook that actually gets my brain!

            My favorite feature is the ability to create checklists. It helps me break down big projects into manageable tasks, and those little checkmarks make me feel so much more accomplished! Plus, Scribby's sharing feature has been amazing for collaborating with my study group.

            Scribby has helped me stay on top of my workload and boosted my grades. I can't recommend it enough to anyone who needs a better way to organize their thoughts and ideas.
          </p>
          <span class="inline-block h-1 w-10 rounded bg-main mt-8 mb-4"></span>
          <h2 class="text-main font-medium title-font tracking-wider text-sm">Prakash</h2>
          <p class="text-main/80 font-semibold">College Student</p>
        </div>
      </div>
    </section>
  )
}

export default Quotes