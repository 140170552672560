import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import Banner from "./components/Banner";
import LoadingPage from "../Loading/LoadingPage";
import Quotes from "./components/Quotes";
import CTA1 from "./components/CTA1";
import CTA2 from "./components/CTA2";
import Features from "./components/Features";
import Links from "./components/Links";
import Footer from "./components/Footer";
import CTA3 from "./components/CTA3";
import Data from "./components/Data";


function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <div>
        <Banner />
        <Navbar />
        <Landing />
        <CTA1 />
        <CTA2 />
        <CTA3 />
        <Data />
        <Features />
        <Quotes />
        <Links />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
