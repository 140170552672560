import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ping } from "ldrs";

const TopHeadlinesCard = ({ article }) => {
  return (
    <Link
      to={article.link}
      target="_blank"
      className="bg-white border-main border-2 rounded-md overflow-hidden"
    >
      <img
        src={article.og || "https://via.placeholder.com/150"}
        alt={article.title}
        className="w-full h-[150px] rounded-t-sm grayscale object-cover"
      />
      <div className="p-4 rounded-b-md">
        <h2 className="text-lg font-semibold text-gray-800">{article.title}</h2>
      </div>
    </Link>
  );
};

const Headlines = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [news, setNews] = useState([]);

  const fetchNews = () => {
    setLoading(true);
    axios
      .get(`https://ok.surf/api/v1/cors/news-feed`)
      .then((response) => {
        setNews(response.data.Business);
      })
      .catch((error) => console.error("Error fetching news:", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    ping.register();
    fetchNews();
  }, []);

  const handleShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    if (nextPage * 9 >= news.length) {
      setShowLoadMore(false);
    }
  };

  return (
    <div className="w-full h-[300px] overflow-y-auto">
      {loading ? (
        <div className="w-full h-[300px] flex items-center justify-center">
          <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
        </div>
      ) : (
        <div className="p-4">
          <div className="grid grid-cols-1 gap-5 ">
            {news &&
              news
                .slice(0, page * 4)
                .sort((a, b) => a.title.length - b.title.length)
                .map((article, index) => (
                  <TopHeadlinesCard key={index} article={article} />
                ))}
          </div>
          {showLoadMore && (
            <div className="flex justify-center mt-4">
              <button
                className="bg-main text-white font-semibold py-2 px-4 rounded"
                onClick={handleShowMore}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Headlines;
