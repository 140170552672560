import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/Landing/Home";
import About from "./components/Landing/About";
import Contact from "./components/Landing/Contact";
import Tasks from "./components/Landing/Tasks";
import AiFeatures from "./components/Landing/AiFeatures";
import Notes from "./components/Landing/Notes";
import AccessNow from "./components/auth/AccessNow";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import NoteCardEditor from "./components/Landing/components/NoteCardEditor";
import Drawing from "./components/Landing/components/Drawing";
import AudioRecorder from "./components/Landing/components/AudioCard";
import NoteBookEditor from "./components/Landing/components/NoteBookEditor";
import TodoEditor from "./components/Landing/components/TodoEditor";
import { UserAuthContextProvider } from "./components/context/UserAuthContext";
import Structure from "./components/dashboard/Structure";
import DashboardHome from "./components/dashboard/overview/DashboardHome";
import Noteboard from "./components/dashboard/noteboard/Noteboard";
import Notebook from "./components/dashboard/notebook/Notebook";
import SharedSpace from "./components/dashboard/sharedspace/SharedSpace";
import Profile from "./components/dashboard/profile/Profile";
import NoteCardTrash from "./components/dashboard/noteboard/trash/NoteCardTrash";
import NotebookTrash from "./components/dashboard/notebook/trash/NotebookTrash";
import { DataContextProvider } from "./components/context/DataContext";
import NotebookSearch from "./components/dashboard/notebook/search/Search";
import NoteboardSearch from "./components/dashboard/noteboard/search/Search";
import Creator from "./components/dashboard/creator/Creator";
import TermsOfUse from "./components/Landing/components/TermsOfUse";
import CanvaCard from "./components/dashboard/editors/CanvaCard";
import PresentCanva from "./components/dashboard/canva/PresentCanva";

function App() {
  return (
    <div className="">
      <Router>
        <UserAuthContextProvider>
          <DataContextProvider>
            <Routes>
              <Route path="" element={<Navigate to="home" />} />
              <Route path="home" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="notes" element={<Notes />} />
              <Route path="tasks" element={<Tasks />} />
              <Route path="terms-of-use" element={<TermsOfUse />} />
              <Route path="audio" element={<AudioRecorder />} />
              <Route path="sketch" element={<Drawing />} />
              <Route path="notebook" element={<NoteBookEditor />} />
              <Route path="notecard" element={<NoteCardEditor />} />
              <Route path="todocard" element={<TodoEditor />} />
              <Route path="canvacard" element={<CanvaCard />} />
              <Route path="ai-features" element={<AiFeatures />} />
              <Route path="access-now" element={<AccessNow />} />
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <Structure />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=""
                  element={
                    <ProtectedRoute>
                      <Navigate to="overview" />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="overview"
                  element={
                    <ProtectedRoute>
                      <DashboardHome />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="noteboard"
                  element={
                    <ProtectedRoute>
                      <Noteboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="notebook"
                  element={
                    <ProtectedRoute>
                      <Notebook />
                    </ProtectedRoute>
                  }
                />
                {/* <Route
                  path="creator"
                  element={
                    <ProtectedRoute>
                      <Creator />
                    </ProtectedRoute>
                  }
                /> */}
                <Route
                  path="present"
                  element={
                    <ProtectedRoute>
                      <PresentCanva />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="notebook/trash"
                  element={
                    <ProtectedRoute>
                      <NotebookTrash />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="notebook/search"
                  element={
                    <ProtectedRoute>
                      <NotebookSearch />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="noteboard/trash"
                  element={
                    <ProtectedRoute>
                      <NoteCardTrash />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="noteboard/search"
                  element={
                    <ProtectedRoute>
                      <NoteboardSearch />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="shared"
                  element={
                    <ProtectedRoute>
                      <SharedSpace />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="canvacard"
                  element={
                    <ProtectedRoute>
                      <CanvaCard />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="*"
                  element={
                    <ProtectedRoute>
                      <Navigate to="overview" />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="*" element={<Navigate to="home" />} />
            </Routes>
          </DataContextProvider>
        </UserAuthContextProvider>
      </Router>
    </div>
  );
}

export default App;
