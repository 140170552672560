import React, { useEffect, useState } from "react";
import { generateHTML, ParseDate } from "../../../../common/methods";
import { LuLoader2 } from "react-icons/lu";
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useData } from "../../../context/DataContext";
import { db } from "../../../../config/firebase";
import toast, { Toaster } from "react-hot-toast";

function Card({ item }) {
  const [revokingEmail, setRevokingEmail] = useState(null);
  const { setBooks, setbookPreview } = useData();

  useEffect(() => {
    console.log(item);
  }, [item]);

  const revokeAccess = async (email) => {
    try {
      setRevokingEmail(email);
      const userDocQuery = query(
        collection(db, "notebooks"),
        where("nbid", "==", item.nbid)
      );

      const querySnapshot = await getDocs(userDocQuery);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        const updatedArray = item.shared.filter((id) => id !== email);

        await updateDoc(docRef, {
          shared: updatedArray,
        });

        setBooks((prevCards) =>
          prevCards.map((c) =>
            c.nbid === item.nbid ? { ...c, shared: updatedArray } : c
          )
        );

        setbookPreview({});
        toast.success("Access revoked successfully");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRevokingEmail(null);
    }
  };

  return (
    <div className="bg-white pt-6 px-6">
      <div
        style={{ backgroundColor: item?.color }}
        className="p-4 rounded-lg shadow-md mb-4"
      >
        <div
          dangerouslySetInnerHTML={{ __html: generateHTML(item?.data) }}
          className="text-gray-800 break-all leading-relaxed"
        />
      </div>
      <h2 className="text-2xl font-semibold text-gray-900 mb-2">
        {item?.name}
      </h2>
      <div className="text-gray-700 mb-4">
        <p className="mb-1 break-all">{item?.nbid}</p>
        <p className="capitalize inline-flex items-center gap-3">
          <div
            style={{ backgroundColor: item?.color }}
            className="size-5 border rounded-md shadow"
          />{" "}
          {item?.type} Card
        </p>
        <p className="mb-1">
          <span className="font-medium">Created:</span>{" "}
          {ParseDate(item?.createdAt)}
        </p>
        <p className="mb-1">
          <span className="font-medium">Edited:</span>{" "}
          {ParseDate(item?.updatedAt)}
        </p>
      </div>
      <div className="">
        {item.shared.length > 0 && <h1 className="text-main font-semibold text-lg mb-3 underline underline-offset-2">
          Shared with
        </h1>}
        {item?.shared.map((email) => (
          <span
            key={email}
            className="flex items-center justify-between mb-1 gap-3"
          >
            <h1 title={email} className="text-gray-500">
              {email.slice(0, 15) + ".."}
            </h1>
            {revokingEmail === email ? (
              <LuLoader2 className="text-red-700 animate-spin text-xs mt-1" />
            ) : (
              <button
                onClick={() => revokeAccess(email)}
                className="text-red-700 hover:underline underline-offset-2"
              >
                Revoke
              </button>
            )}
          </span>
        ))}
      </div>
      <Toaster />
    </div>
  );
}

export default Card;
