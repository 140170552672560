import React, { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import { Outlet } from "react-router";
import Navbar from "./components/Navbar";
import LoadingPage from "../Loading/LoadingPage";
import { addDoc, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useUserAuth } from "../context/UserAuthContext";
import { db } from "../../config/firebase";
import { useData } from "../context/DataContext";
import Creator from "./creator/Creator";

function Structure() {
  const [isLoading, setIsLoading] = useState(true);
  const { getCards, getBooks, getCardTrash, getBookTrash, OnlineSync } = useData();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const loadApp = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(loadApp);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      getCards();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      OnlineSync();
    }
  }, [isOnline]);

  useEffect(() => {
    if (!isLoading) {
      getBooks();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      getBookTrash();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      getCardTrash();
    }
  }, [isLoading]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="w-full dark:bg-white bg-main/15 h-screen flex md:gap-2 p-2">
      <div className="h-[98vh] py-0.5 flex rounded-md shadow-md">
        <Sidebar />
      </div>
      <div className="w-full h-[98vh] py-1">
        <div className="w-full h-[9%] min-h-[60px] mb-2 rounded-md shadow-md">
          <Navbar />
        </div>
        <div className="h-[90%]">
          {isOnline ?
            <Outlet /> :
            <div style={{
              width: '100%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'white',
              overflowY: 'auto',
              borderRadius: '8px',
              height: '86vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <p style={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#1a1a1a80'
              }}>Connect to the Internet</p>
              <p style={{
                fontSize: '1rem',
                color: 'gray',
                marginBottom: '15px'
              }}>You're offline. Check your connection.</p>

              <button onClick={() => {
                window.location.reload();
              }} style={{
                fontSize: '1rem',
                color: 'white',
                backgroundColor: '#1a1a1a',
                padding: "5px 15px",
                borderRadius: "10px"
              }} >
                Retry
              </button>

            </div>}
        </div>
        <Creator />
      </div>
    </div>
  );
}

export default Structure;
