import React, { useState, useEffect, useRef } from 'react';
import { Excalidraw } from "@excalidraw/excalidraw";
import toast, { Toaster } from "react-hot-toast";
import { useData } from "../../context/DataContext";
import { useUserAuth } from '../../context/UserAuthContext';
import { v4 as uuidv4 } from "uuid";
import { IoMdCloud } from 'react-icons/io';
import { GrClear } from "react-icons/gr";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../config/firebase';

function CanvaCard({ setImagePopup }) {
  const [name, setName] = useState("Untitled");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useUserAuth();
  const { setCards } = useData();
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const excalidrawRef = useRef(null);

  useEffect(() => {
    if (excalidrawRef.current) {
      setExcalidrawAPI(excalidrawRef.current);
    }
  }, [excalidrawRef]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      if (name !== 'Untitled') {
        const sceneData = excalidrawAPI.getSceneElements();
        const appState = excalidrawAPI.getAppState();
        const exportData = {
          elements: sceneData,
          appState: appState,
          collaborators: []
        };
        if (sceneData.length !== 0) {
          const canvaData = {
            uid: user.uid,
            ncid: uuidv4(),
            name,
            type: "canva",
            shared: [],
            data: exportData,
            createdAt: new Date(),
            updatedAt: new Date(),
            color: '#ffffff',
          };

          await addDoc(collection(db, "notecard"), canvaData);
          setCards((prevCards) => [canvaData, ...prevCards]);
          setImagePopup(false);
        }
        else {
          toast('Cannot Save Empty Canvas');
        }
      } else {
        navigator.vibrate(500);
        toast("Change the Name of the CanvaCard");
      }
    } catch (error) {
      console.error('Error saving canvas:', error);
      toast.error('Error saving canvas. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    setIsEditingName(false);
  };

  const handleReset = async () => {
    excalidrawAPI.resetScene();
  };

  return (
    <div className="bg-white w-full h-full rounded-t-2xl">
      <div className="w-full max-h-[10vh] px-4 py-4 rounded-t-2xl bg-main flex items-center justify-between gap-3">
        {isEditingName ? (
          <form onSubmit={handleNameSubmit} className="flex-1">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={handleNameSubmit}
              className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold w-full outline-none"
              autoFocus
            />
          </form>
        ) : (
          <h1
            onClick={() => setIsEditingName(true)}
            className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold cursor-pointer"
          >
            {name}
          </h1>
        )}
        <div className="flex items-center gap-3">
          <button
            onClick={handleReset}
            className="bg-white disabled:opacity-50 transition-all text-main font-bold p-2 rounded-full"
          >
            <GrClear />
          </button>
          <button
            onClick={handleSave}
            className="bg-white disabled:opacity-50 transition-all text-main font-bold p-2 rounded-full"
          >
            <IoMdCloud />
          </button>
        </div>
      </div>

      <div style={{ height: '85vh' }}>
        <Excalidraw
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
        />
      </div>

      <Toaster
        toastOptions={{
          className: "",
          style: { background: "#ffffff", color: "#1a1a1a" },
        }}
      />
    </div>
  );
}

export default CanvaCard;
