import React from 'react'
import { Link } from 'react-router-dom'

function CTA1() {
  return (
    <section class="text-main/60 body-font">
      <div class="container mx-auto flex lg:px-24 px-5 pt-24 pb-12 md:py-24 md:flex-row flex-col items-center">
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img class="object-cover object-center rounded" alt="hero" src="https://ik.imagekit.io/vituepzjm/notebook.svg?updatedAt=1725465913911" />
        </div>
        <div class="lg:flex-grow md:w-1/2 lg:pl-32 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="px-8 py-3 bg-main/5 uppercase text-sm rounded-md mb-5 font-bold text-main">Note Book</h1>
          <h1 class="title-font sm:text-5xl text-3xl mb-4 break-words font-bold text-main">Manage Your pocket-sized
            <br class="hidden lg:inline-block" /> Notebook
          </h1>
          <p class="mb-8 font-medium leading-relaxed w-[80%]"> Notebook gives you the flexibility to capture everything from brainstorming sessions to complex code snippets with ease.</p>
          <Link to='/about' class="flex justify-center items-center bg-main ps-5 pe-3 py-3 rounded-md gap-5">
            <span class="text-white">Learn More</span>
            <i className="fas fa-arrow-right text-main bg-white p-2 rounded-md"></i>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CTA1