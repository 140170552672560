import React, { useState, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Marker from "@editorjs/marker";
import Underline from "@editorjs/underline";
import ChangeCase from "editorjs-change-case";
import Checklist from "@editorjs/checklist";
import { IoMdCloud } from "react-icons/io";
import { useHotkeys } from "react-hotkeys-hook";
import { RiEdit2Fill, RiLockFill } from "react-icons/ri";

function NoteBookEditor() {
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] = useState(null);
  const [editorData, setEditorData] = useState({});
  const [readOnly, setReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useHotkeys("ctrl+s", (event) => {
    event.preventDefault();
    alert("Saved");
    handleSave();
  });

  useEffect(() => {
    if (editorRef.current) {
      const newEditor = new EditorJS({
        holder: "editorjs",
        autofocus: true,
        tools: {
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },
          underline: {
            class: Underline,
            shortcut: "alt+u",
          },
          Marker: {
            class: Marker,
            shortcut: "alt+v",
          },
          changeCase: {
            class: ChangeCase,
            config: {
              showLocaleOption: true,
              shortcut: "alt+c",
              locale: "tr",
            },
          },
        },
        placeholder: "Let`s write an awesome story!",
        data: editorData,
      });
      setEditorInstance(newEditor);
    }
    return () => {
      if (editorInstance) {
        editorInstance.destroy();
      }
    };
  }, []);

  const handleSave = async () => {
    if (editorInstance) {
      const savedData = await editorInstance.save();
      setEditorData(savedData);
      console.log(JSON.stringify(editorData));
    }
  };

  const handleToggleReadOnly = () => {
    setReadOnly(!readOnly);
    if (editorInstance) {
      editorInstance.readOnly.toggle();
    }
  };

  // if (isLoading) {
  //   return <LoadingPage />;
  // }

  return (
    <div className="bg-white w-full rounded-t-2xl">
      <div className="w-full h-[10vh] px-4 rounded-t-2xl bg-main flex items-center justify-between gap-3">

        <h1
          className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold cursor-pointer"
        >
          Todo Card
        </h1>
        <div className="flex items-center gap-3">
          <button
            className="bg-white disabled:opacity-50 transition-all text-main font-bold p-2 rounded-full"
          >
            <IoMdCloud />
          </button>

          <button
            onClick={handleToggleReadOnly}
            className="bg-white text-main font-bold p-2 rounded-full"
          >
            {readOnly ? <RiEdit2Fill /> : <RiLockFill />}
          </button>
        </div>
      </div>
      <div
        ref={editorRef}
        id="editorjs"
        style={{ backgroundColor: "#f7bfff" }}
        className="w-full h-[85vh] text-main placeholder:text-main overflow-y-auto px-4 py-3"
      />
    </div>
  );
}

export default NoteBookEditor;
