import React, { useEffect, useState } from "react";
import { useData } from "../../context/DataContext";
import { generateHTML, ParseDate } from "../../../common/methods";
import { addDoc, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { ping } from "ldrs";
import * as Tabs from "@radix-ui/react-tabs";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useUserAuth } from "../../context/UserAuthContext";
import NoteBookEdit from "./notebookedit/NoteBookEdit";
import { IoCloseSharp } from "react-icons/io5";
import SketchCardEditor from "./noteboardedit/SketchCardEditor";
import ImageCardEditor from "./noteboardedit/ImageCardEditor";
import NoteCardEditor from "./noteboardedit/NoteCardEditor";
import TodoCardEditor from "./noteboardedit/TodoCardEditor";

function SharedSpace() {
  const { fetchSharedItems, setTitle, Sharedbooks, setSharedbooks, SharedbooksLoading, setSharedbooksLoading, Sharedcards, setSharedcards, SharedcardsLoading, setSharedcardsLoading, setpreviewSharedBook, previewSharedBook, setpreviewSharedCard, previewSharedCard } = useData();
  const [isBookEditing, setIsBookEditing] = useState(false);
  const [isNoteEditing, setisNoteEditing] = useState(false);
  const [isImageEditing, setisImageEditing] = useState(false);
  const [isTodoEditing, setisTodoEditing] = useState(false);
  const [isSketchEditing, setisSketchEditing] = useState(false);

  useEffect(() => {
    fetchSharedItems("notecard", setSharedcards, setSharedcardsLoading);
    fetchSharedItems("notebooks", setSharedbooks, setSharedbooksLoading);
  }, []);

  useEffect(() => {
    setTitle("Shared with Me");
    ping.register();

    return () => {
      setTitle("");
    };
  }, []);

  const sortCardsByDate = () => {
    setSharedcards(items =>
      items.sort((a, b) => {
        const dateA = a.updatedAt?.toDate ? a.updatedAt.toDate() : a.updatedAt;
        const dateB = b.updatedAt?.toDate ? b.updatedAt.toDate() : b.updatedAt;
        return dateB - dateA;
      })
    );
  };

  const sortBooksByDate = () => {
    setSharedcards(items =>
      items.sort((a, b) => {
        const dateA = a.updatedAt?.toDate ? a.updatedAt.toDate() : a.updatedAt;
        const dateB = b.updatedAt?.toDate ? b.updatedAt.toDate() : b.updatedAt;
        return dateB - dateA;
      })
    );
  };

  const renderCard = (item) => (
    <div
      key={item.ncid}
      style={{ backgroundColor: item.color }}
      className="relative bg-white border shadow-sm rounded-md h-[300px] p-3"
    >
      <div className="flex items-center justify-between">
        <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
          {item.name.length <= 10 ? item.name : `${item.name.slice(0, 10)}..`}
        </div>

        <div className="w-fit px-2 py-1 rounded-md text-main">
          {ParseDate(item.updatedAt)}
        </div>
      </div>
      <div className="h-[230px] overflow-y-auto my-2 flex items-center justify-center py-2">
        {item.type === "image" && (
          <>
            <img
              onClick={() => {
                setpreviewSharedCard(item);
                setisImageEditing(true);
              }}
              className="w-full bg-main cursor-pointer h-full object-cover rounded-md"
              src={item.data}
              alt={item.name}
            />
            <AlertDialog.Root open={isImageEditing}>
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-main bg-opacity-70 data-[state=open]:animate-overlayShow fixed inset-0" />
                <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                  <button
                    onClick={() => { sortCardsByDate(); setisImageEditing(false); }}
                    className="fixed top-1 rounded-md p-1 right-1"
                  >
                    <IoCloseSharp className="text-xl rounded-full text-white" />
                  </button>
                  <ImageCardEditor sortData={sortCardsByDate} item={previewSharedCard} setisEditing={setisImageEditing} />
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
          </>
        )}
        {item.type === "note" && (
          <>
            <div onClick={() => {
              setpreviewSharedCard(item);
              setisNoteEditing(true);
            }} className="w-full h-full rounded-lg p-4 cursor-pointer">
              <div dangerouslySetInnerHTML={{ __html: generateHTML(item.data) }} />
            </div>
            <AlertDialog.Root open={isNoteEditing}>
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-main bg-opacity-70 data-[state=open]:animate-overlayShow fixed inset-0" />
                <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                  <button
                    onClick={() => { sortCardsByDate(); setisNoteEditing(false); }}
                    className="fixed top-1 rounded-md p-1 right-1"
                  >
                    <IoCloseSharp className="text-xl rounded-full text-white" />
                  </button>
                  <NoteCardEditor sortData={sortCardsByDate} card={previewSharedCard} setisCardEditing={setisNoteEditing} />
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
          </>
        )}
        {item.type === "todo" && (
          <>
            <div onClick={() => {
              setpreviewSharedCard(item);
              setisTodoEditing(true);
            }} className="w-full h-full rounded-lg p-4 cursor-pointer">
              <div dangerouslySetInnerHTML={{ __html: generateHTML(item.data) }} />
            </div>

            <AlertDialog.Root open={isTodoEditing}>
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-main bg-opacity-70 data-[state=open]:animate-overlayShow fixed inset-0" />
                <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                  <button
                    onClick={() => { sortCardsByDate(); setisTodoEditing(false); }}
                    className="fixed top-1 rounded-md p-1 right-1"
                  >
                    <IoCloseSharp className="text-xl rounded-full text-white" />
                  </button>
                  <TodoCardEditor sortData={sortCardsByDate} card={previewSharedCard} setisCardEditing={setisTodoEditing} />
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
          </>
        )}
        {item.type === "sketch" && (
          <>
            <div onClick={() => {
              setpreviewSharedCard(item);
              setisSketchEditing(true);
            }} dangerouslySetInnerHTML={{ __html: item?.data }} className="w-full h-full cursor-pointer" />
            <AlertDialog.Root open={isSketchEditing}>
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-main bg-opacity-70 data-[state=open]:animate-overlayShow fixed inset-0" />
                <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
                  <button
                    onClick={() => { sortCardsByDate(); setisSketchEditing(false); }}
                    className="fixed top-1 rounded-md p-1 right-1"
                  >
                    <IoCloseSharp className="text-xl rounded-full text-white" />
                  </button>
                  <SketchCardEditor sortData={sortCardsByDate} item={previewSharedCard} setisEditing={setisSketchEditing} />
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
          </>
        )}
      </div>

    </div>
  );

  const renderContent = (items, isLoading) => {
    if (isLoading) {
      return (
        <div className="flex w-full h-[60vh] items-center justify-center text-main">
          <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
        </div>
      );
    }

    if (items.length === 0) {
      return (
        <div className="flex items-center justify-center w-full h-[60vh]">
          <div className="flex flex-col items-center justify-center">
            <img
              src={"https://ik.imagekit.io/vituepzjm/undraw_cat_epte.svg?updatedAt=1724847831719"}
              alt="Not Shared Yet"
              className="size-52 -mb-3"
            />
            <h1 className="text-center italic font-semibold text-lg text-main">Nothing shared yet</h1>
            <p className="text-center text-base italic font-medium text-main/80">
              {"Cards"} shared with you will show up here.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className={`grid gap-5 w-full md:grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 lg:grid-cols-2`}>
        {items.map((item) => renderCard(item))}
      </div>
    );
  };

  const renderBook = (book) => (
    <div
      key={book.nbid}
      style={{ backgroundColor: book.color }}
      className="relative bg-white border shadow-sm rounded-md h-[300px] p-3"
    >
      <div className="flex items-center justify-between">
        <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
          {book.name.length <= 10 ? book.name : `${book.name.slice(0, 10)}..`}
        </div>
        <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
          {ParseDate(book.updatedAt)}
        </div>
      </div>
      <div className="h-[230px] overflow-y-auto my-2 flex items-center justify-center py-2">
        <div onClick={() => { setpreviewSharedBook(book); setIsBookEditing(true); }} className="w-full h-full cursor-pointer rounded-lg p-4">
          <div dangerouslySetInnerHTML={{ __html: generateHTML(book.data) }} />
        </div>
      </div>

      <AlertDialog.Root open={isBookEditing}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="bg-main bg-opacity-70 data-[state=open]:animate-overlayShow fixed inset-0" />
          <AlertDialog.Content className="data-[state=open]:animate-slideDown fixed bottom-0 left-0 h-[94vh] w-full rounded-t-2xl bg-white shadow-md focus:outline-none">
            <button
              onClick={() => { sortBooksByDate(); setIsBookEditing(false); }}
              className="fixed top-1 rounded-md p-1 right-1"
            >
              <IoCloseSharp className="text-xl rounded-full text-white" />
            </button>
            <NoteBookEdit SortData={sortBooksByDate} setisBookEditing={setIsBookEditing} setSharedbooks={setSharedbooks} book={previewSharedBook} />
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );

  const renderBookContent = (books, isLoading) => {
    if (isLoading) {
      return (
        <div className="flex w-full h-[60vh] items-center justify-center text-main">
          <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
        </div>
      );
    }

    if (books.length === 0) {
      return (
        <div className="flex items-center justify-center w-full h-[60vh]">
          <div className="flex flex-col items-center justify-center">
            <img
              src={"https://ik.imagekit.io/vituepzjm/undraw_cat_epte.svg?updatedAt=1724847831719"}
              alt="Not Shared Yet"
              className="size-52 -mb-3"
            />
            <h1 className="text-center italic font-semibold text-lg text-main">Nothing shared yet</h1>
            <p className="text-center text-base italic font-medium text-main/80">
              {"Notebooks"} shared with you will show up here.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className={`grid gap-5 w-full md:grid-cols-1 sm:grid-cols-2 lg:grid-cols-2`}>
        {books.map((book) => renderBook(book))}
      </div>
    );
  };

  return (
    <div className="w-full bg-white h-full px-3 py-2 rounded-lg">
      <Tabs.Root className="flex flex-col w-full h-full overflow-y-auto" defaultValue="notecard">
        <Tabs.List className="text-main font-medium flex items-center justify-center rounded-md w-fit gap-2 pt-3 pb-5 mx-2">
          <Tabs.Trigger
            className="data-[state=active]:bg-main data-[state=active]:text-white px-3 py-2 rounded-md text-sm"
            value="notecard"
          >
            NoteCards
          </Tabs.Trigger>
          <Tabs.Trigger
            className="data-[state=active]:bg-main data-[state=active]:text-white px-3 py-2 rounded-md text-sm"
            value="notebook"
          >
            Notebooks
          </Tabs.Trigger>
          <div className="">

          </div>
        </Tabs.List>
        <Tabs.Content value="notecard" className="rounded-md px-2 pb-1 overflow-y-auto w-full">
          {renderContent(Sharedcards, SharedcardsLoading)}
        </Tabs.Content>
        <Tabs.Content value="notebook" className="rounded-md px-2 pb-1 overflow-y-auto w-full">
          {renderBookContent(Sharedbooks, SharedbooksLoading)}
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
}

export default SharedSpace;
