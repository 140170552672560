import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";

function Landing() {
  const { user } = useUserAuth();
  const splineViewerRef = useRef(null);

  useEffect(() => {
    if (splineViewerRef.current?.shadowRoot) {
      const style = document.createElement('style');
      style.innerHTML = `
        #logo { opacity: 0 !important; }
        #spline { border-radius: 20px; }
      `;
      splineViewerRef.current.shadowRoot.appendChild(style);
    }
  }, []);
  const heroImage = user
    ? "https://ik.imagekit.io/vituepzjm/Scribby/front_main_hero_together.webp?updatedAt=1725384601380"
    : "https://ik.imagekit.io/vituepzjm/Scribby/33EhGewunGf8RUO3AOwC7nwgcM.avif?updatedAt=1723024784413";

  const buttonText = user ? "Go to your Space" : "Access Your Scribby Now";
  const buttonLink = user ? "/dashboard/overview" : "/access-now";

  return (
    <section className="text-main/80 body-font">
      <div className="min-h-[60vh] md:min-h-[65vh] mx-auto flex px-5 lg:px-14 md:pt-14 pt-5 md:flex-row lg:pb-14 flex-col-reverse gap-10 lg:gap-0 items-center justify-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left items-center text-center">
          <div className="mb-5 px-5 font-medium text-sm rounded-full border bg-main/5 text-main py-3">
            Note Taking App
          </div>
          <h1 className="sm:text-4xl text-3xl mb-3 font-bold text-main leading-10">
            Tired of forgetting important things?
            <br className="hidden mt-3 lg:inline-block" />
            Capture & organize your stories.
          </h1>
          <h2 className="text-xl mb-8 font-normal leading-8">
            The future of work is seamless and connected. Linked notes are key to unlocking a more efficient, productive, and collaborative workflow.
          </h2>

          <Link to={buttonLink}>
            <button className="group/button relative inline-flex items-center justify-center overflow-hidden rounded-md bg-main backdrop-blur-lg px-6 py-2 text-base font-semibold text-white transition-all duration-300 ease-in-out hover:scale-[1.03] border border-white/20">
              <span className="text-lg">{buttonText}</span>
              <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
                <div className="relative h-full w-10 bg-white/30"></div>
              </div>
            </button>
          </Link>
        </div>

        <div className="block lg:max-w-lg rounded-[20px] bg-main shadow md:border-4 border-white md:outline outline-[3px] outline-main cursor-pointer transition-all lg:h-[65vh] lg:w-full md:w-1/2 w-full">
          <spline-viewer
            ref={splineViewerRef}
            url="https://prod.spline.design/WkJYJyP83x9tSi7M/scene.splinecode"
            className="w-full h-full"
          ></spline-viewer>
        </div>
      </div>
    </section>
  );
}

export default Landing;
