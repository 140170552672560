import { Timestamp } from "firebase/firestore";
import showdown from "showdown";

const converter = new showdown.Converter();

export function extractChecklistData(data) {
  if (!data) return null;
  const result = [];

  data.blocks.forEach((block) => {
    block.data.items.forEach((item) => {
      result.push({
        name: item.text,
        checked: item.checked,
      });
    });
  });

  return result;
}

export const getCurrentTimeInUTCFormat = () => {
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear();
  const month = String(currentDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getUTCDate()).padStart(2, "0");
  const hours = String(currentDate.getUTCHours()).padStart(2, "0");
  const minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(currentDate.getUTCMilliseconds()).padStart(
    3,
    "0"
  );

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

export const ParseDate = (date) => {
  const event = date instanceof Timestamp ? date.toDate() : date;
  const currentDate = new Date();

  const timeDiffInMilliseconds = currentDate.getTime() - event.getTime();
  const timeDiffInSeconds = Math.floor(timeDiffInMilliseconds / 1000);

  if (timeDiffInSeconds < 60) {
    return `${timeDiffInSeconds} seconds ago`;
  } else {
    const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);

    if (timeDiffInMinutes < 60) {
      return `${timeDiffInMinutes} minutes ago`;
    } else {
      const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);

      if (timeDiffInHours < 24) {
        return `${timeDiffInHours} hours ago`;
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = event.getMonth();
        const month = months[monthIndex];

        const day = event.getDate();
        const year = event.getFullYear();

        return `${month} ${day}, ${year}`;
      }
    }
  }
};

export function generateHTML(data) {
  const { blocks } = data;
  let html = "";

  blocks.slice(0, 3).forEach((block) => {
    switch (block.type) {
      case "paragraph":
        html += `<p class="my-3">${block.data.text}</p>`;
        break;
      case "delimiter":
        html += `<hr class="my-3" style="border: 1px solid #1a1a1a;" />`;
        break;
      case "heading":
        html += `<h1 class="my-3">${block.data.text}</h1>`;
        break;
      case "title":
        html += `<h1 class="font-semibold my-3">${block.data.text}</h1>`;
        break;
      case "mermaid":
        html += `<h2 class="font-light my-3">${block.data.code}</h2>`;
        break;
      case "Math":
        html += `<h2 class="font-light my-3">${block.data.math}</h2>`;
        break;
      case "code":
        html += converter.makeHtml(block.data.code.slice(0, 50) + "..");
        break;
      case "quote":
        html += `<h2 class="font-light my-3">${
          block.data.text.slice(0, 30) + ".."
        }</h2>`;
        break;
      case "alert":
        html += `<div class="bg-white px-3 py-2 rounded-lg my-2 text-${
          block.data.align
        }">${
          block.data.message.length > 30
            ? block.data.message.slice(0, 30) + ".."
            : block.data.message
        }</div>`;
        break;
      case "list":
        if (block.data.style === "unordered") {
          html += "<ul class='ps-5'>";
          block.data.items.forEach((item) => {
            html += `<li class='list-disc'>${item}</li>`;
          });
          html += "</ul>";
        } else if (block.data.style === "ordered") {
          html += "<ol class='ps-5'>";
          block.data.items.forEach((item) => {
            html += `<li class='list-decimal'>${item}</li>`;
          });
          html += "</ol>";
        }
        break;
      case "checklist":
        html +=
          "<ul class='list-disc h-[200px] p-4 rounded-md list-inside mb-2'>";
        block.data.items.slice(0, 3).forEach((todo, index) => {
          html += `<li key="${index}" class="text-gray-700 ${
            todo.checked ? "line-through" : ""
          }">${todo.text}</li>`;
        });
        html += "</ul>";
        break;
      default:
        break;
    }
  });

  return html;
}

export function getUniqueColors(dataArray) {
  const colorSet = new Set();
  dataArray.forEach((item) => {
    if (item.color) {
      colorSet.add(item.color);
    }
  });
  return Array.from(colorSet);
}

export function getMaxColorOccurrence(arr) {
  if (arr.length == 0) return "#dddddd";

  const colorFrequency = {};
  arr.forEach((obj) => {
    if (obj.color) {
      if (colorFrequency[obj.color]) {
        colorFrequency[obj.color]++;
      } else {
        colorFrequency[obj.color] = 1;
      }
    }
  });

  let maxColor = null;
  let maxCount = 0;
  for (const color in colorFrequency) {
    if (colorFrequency[color] > maxCount) {
      maxCount = colorFrequency[color];
      maxColor = color;
    }
  }

  return maxColor;
}

export const ParseAIDate = (date) => {
  const event = new Date(date);
  const currentDate = new Date();

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  const isToday =
    event.getDate() === currentDate.getDate() &&
    event.getMonth() === currentDate.getMonth() &&
    event.getFullYear() === currentDate.getFullYear();

  const isYesterday =
    event.getDate() === yesterday.getDate() &&
    event.getMonth() === yesterday.getMonth() &&
    event.getFullYear() === yesterday.getFullYear();

  if (isToday) {
    const hours = event.getHours();
    const minutes = event.getMinutes();
    let period = "AM";

    if (hours >= 12) {
      period = "PM";
    }

    const displayHours = hours % 12 || 12;
    const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${displayHours}:${displayMinutes} ${period}`;
  } else if (isYesterday) {
    const hours = event.getHours();
    const minutes = event.getMinutes();
    let period = "AM";

    if (hours >= 12) {
      period = "PM";
    }

    const displayHours = hours % 12 || 12;
    const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${displayHours}:${displayMinutes} ${period} , yesterday`;
  } else {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = event.getMonth();
    const month = months[monthIndex];
    const hours = event.getHours();
    const minutes = event.getMinutes();
    let period = "AM";

    if (hours >= 12) {
      period = "PM";
    }

    const displayHours = hours % 12 || 12;
    const displayMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const day = event.getDate();
    const year = event.getFullYear();

    return `${displayHours}:${displayMinutes} ${period} , ${month} ${day}`;
  }
};

export function processChecklistData(checklistObject) {
  const completedItems = [];
  const incompleteItems = [];

  checklistObject.blocks.forEach((block) => {
    if (block.type === "checklist") {
      block.data.items.forEach((item) => {
        if (item.checked) {
          completedItems.push(item.text);
        } else {
          incompleteItems.push(item.text);
        }
      });
    }
  });

  return {
    completed: {
      items: completedItems,
      count: completedItems.length,
    },
    incomplete: {
      items: incompleteItems,
      count: incompleteItems.length,
    },
  };
}

export function processChecklistArrayData(checklistArray) {
  const completedItems = [];
  const incompleteItems = [];

  checklistArray.forEach((block) => {
    if (block.type === "checklist") {
      block.data.items.forEach((item) => {
        if (item.checked) {
          completedItems.push(item.text);
        } else {
          incompleteItems.push(item.text);
        }
      });
    }
  });

  return {
    completed: {
      items: completedItems,
      count: completedItems.length,
    },
    incomplete: {
      items: incompleteItems,
      count: incompleteItems.length,
    },
  };
}


export function convertBoldSyntax(obj) {
  const convertText = (text) => {
      let isBold = false;
      return text.replace(/\*\*/g, () => {
          isBold = !isBold;
          return isBold ? '<b>' : '</b>';
      });
  };

  const traverseAndConvert = (item) => {
      if (typeof item === 'string') {
          return convertText(item);
      } else if (typeof item === 'object' && item !== null) {
          for (let key in item) {
              if (item.hasOwnProperty(key)) {
                  item[key] = traverseAndConvert(item[key]);
              }
          }
      }
      return item;
  };

  return traverseAndConvert(obj);
}



