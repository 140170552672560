import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import { MdOutlineKeyboardDoubleArrowRight, MdOutlinePlayCircleFilled, MdVerified } from "react-icons/md";
import { generateHTML, getMaxColorOccurrence } from "../../../common/methods"
import * as Dialog from "@radix-ui/react-dialog";
import { useData } from "../../context/DataContext";
import { ping } from "ldrs";
import { Link } from "react-router-dom";
import Darkmodebtn from "../../common/Darkmodebtn";

function Profile() {
  const { user, VerifyAccount } = useUserAuth();
  const [open, setOpen] = useState(false);
  const { setTitle, cards, books, Sharedbooks, Sharedcards, CardsLoading } = useData();

  useEffect(() => {
    setTitle("User Profile");

    return () => {
      setTitle("");
    };
  }, []);

  const formatCount = (count) => (count < 10 ? `0${count}` : count);

  const HandleVerify = async () => {
    try {
      setOpen(false);
      const { status } = await VerifyAccount();
      if (status) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
      setOpen(false);
    }
  };

  useEffect(() => {
    console.log(user);
  }, []);
  useEffect(() => {
    ping.register();
  }, [])

  const renderCardHeader = (name) => (
    <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
      {name.length <= 10 ? name : `${name.slice(0, 10)}..`}
    </div>
  );

  const renderCardContent = (card) => {
    switch (card.type) {
      case "image":
        return (
          <div className="relative bg-white border shadow-sm rounded-md" style={{ backgroundColor: card.color }}>
            <img className="w-full h-[300px] object-cover brightness-95 rounded-md" src={card.data} alt={card.name} />
            <div className="absolute top-3 start-3">
              {renderCardHeader(card.name)}
            </div>
          </div>
        );
      case "note":
        return (
          <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
            <div className="flex justify-between">{renderCardHeader(card.name)}</div>
            <div className="h-[200px] my-2">
              <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
            </div>
          </div>
        );
      case "todo":
        return (
          <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
            <div className="flex justify-between">{renderCardHeader(card.name)}</div>
            <ul className="list-disc h-[200px] p-4 list-inside mb-2">
              {card.data?.blocks?.[0]?.data?.items?.slice(0, 5).map((todo, index) => (
                <li key={index} className={`text-gray-700 ${todo.checked ? "line-through" : ""}`}>
                  {todo.text}
                </li>
              ))}
            </ul>
          </div>
        );
      case "sketch":
        return (
          <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
            <div className="flex justify-between">{renderCardHeader(card.name)}</div>
            <div className="h-[200px] my-2" dangerouslySetInnerHTML={{ __html: card.data }} />
          </div>
        );
      case "audio":
        return (
          <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
            <div className="flex justify-between">{renderCardHeader(card.name)}</div>
            <div className="h-[200px] flex items-center justify-center p-4">
              <MdOutlinePlayCircleFilled className="text-8xl text-white border-dashed border-[3px] border-white rounded-full" />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderBookHeader = (name) => (
    <div className="w-fit px-2 py-1 rounded-md bg-white text-main">
      {name.length <= 10 ? name : `${name.slice(0, 10)}..`}
    </div>
  );

  const renderBookContent = (card) => {
    return (
      <div className="h-[300px] p-3 border rounded-md shadow-sm" style={{ backgroundColor: card.color }}>
        <div className="flex justify-between">{renderBookHeader(card.name)}</div>
        <div className="h-[200px] my-2">
          <div dangerouslySetInnerHTML={{ __html: generateHTML(card.data) }} />
        </div>
      </div>
    )
  };

  return (
    <section class="relative bg-white dark:bg-main rounded-md overflow-y-auto h-full max-h-[90vh] pt-48 ">
      <img
        src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw5fHxjb3ZlcnxlbnwwfDB8fHwxNzEwNzQxNzY0fDA&ixlib=rb-4.0.3&q=80&w=1080"
        alt="cover-image"
        class="w-full absolute top-0 left-0 z-0 h-60"
      />

      {/* <div className="absolute top-5 right-5">
        <Darkmodebtn />
      </div> */}

      <div class="w-full mx-auto px-6 md:px-8">
        <div class="flex items-center justify-center sm:justify-start relative z-10 mb-5">
          <img
            src={user.photoURL || 'https://xsgames.co/randomusers/assets/avatars/pixel/51.jpg'}
            alt="user-avatar-image"
            class="border-4 border-solid bg-gray-300 size-[100px] border-white dark:border-main rounded-3xl"
          />
        </div>
        <div class="flex items-center justify-center flex-col sm:flex-row max-sm:gap-5 sm:justify-between mb-5">
          <div class="block">
            <h3 class=" font-bold text-4xl text-main dark:text-white mb-1 max-sm:text-center">
              {user?.displayName}
            </h3>
            <p class="font-normal lg:ms-3 text-base leading-7 text-main dark:text-white max-sm:text-center">
              {user?.email}
            </p>
          </div>
          <div class="hidden lg:flex items-center gap-4">
            <button title="Comming Soon" class="rounded-full opacity-50 border border-solid border-main/30 bg-white text-main py-3 px-4 text-base font-semibold shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-50 ">
              Web Lock
            </button>
            {user.emailVerified ? (
              <button className="rounded-full border inline-flex items-center justify-center gap-2 border-solid border-main bg-main dark:bg-white dark:border-white py-3 px-4 font-semibold text-white dark:text-main whitespace-nowrap shadow-sm transition-all duration-500 ">
                <MdVerified className="text-lg text-white dark:text-main" /> Email Verfied
              </button>
            ) : (
              <span className="">
                <button
                  onClick={() => HandleVerify()}
                  className="rounded-full border inline-flex items-center justify-center gap-2 border-solid border-main bg-main py-3 px-4 font-semibold text-white whitespace-nowrap shadow-sm transition-all duration-500 "
                >
                  Verify Email
                </button>
                <Dialog.Root open={open}>
                  <Dialog.Portal>
                    <Dialog.Overlay
                      onClick={() => setOpen(false)}
                      className="bg-blackA6 z-[100000] data-[state=open]:animate-overlayShow fixed inset-0"
                    />
                    <Dialog.Content className="z-[1000000] data-[state=open]:animate-contentShow p-3 outline-white fixed top-[50%] left-[50%] h-[85vh] w-[80vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-lg focus:outline-none">
                      <div className="h-full w-full flex items-center justify-center border-dashed rounded-lg border-2 border-main flex-col">
                        <img
                          src="https://ik.imagekit.io/vituepzjm/undraw_mail_sent_re_0ofv%20(1).svg?updatedAt=1723978026611"
                          alt="verify_img"
                          className="size-44 md:size-52 mb-3"
                        />

                        <h1 className="md:text-2xl mb-2 text-center text-main">
                          Verification Link is Sent to{" "}
                          <span className="font-semibold">{user.email}</span>,
                        </h1>
                        <h1 className="md:text-2xl text-center text-main">
                          please verify yourself
                        </h1>
                      </div>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>
              </span>
            )}
          </div>
        </div>
        <div class="flex mb-10 max-sm:flex-wrap max-sm:justify-center items-center gap-4">
          <h1 style={{ backgroundColor: getMaxColorOccurrence(cards) }}
            class="rounded-full py-2 px-4 md:py-3 md:px-6 bg-stone-100 text-main font-semibold text-sm leading-6 transition-all duration-500 hover:bg-stone-200 hover:text-gray-900"
          >
            {cards.length > 0 ? `${formatCount(cards.length)} Cards created` : `Not Created Yet`}
          </h1>
          <h1 style={{ backgroundColor: getMaxColorOccurrence(books) }}
            class="rounded-full py-2 px-4 md:py-3 md:px-6 bg-stone-100 text-main font-semibold text-sm leading-6 transition-all duration-500 hover:bg-stone-200 hover:text-gray-900"
          >
            {books.length > 0 ? `${formatCount(books.length)} Books created` : `Not Created Yet`}
          </h1>

        </div>

        {cards.length > 0 && <div className="mb-5" >
          {CardsLoading ? <div className="flex items-center flex-col w-full h-[200px] mb-5 px-3 justify-center ">
            <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
          </div> :
            <div>
              {cards.length < 1 ?
                <div className="flex items-center flex-col w-full h-full mb-5 px-3 justify-center ">
                  <img src="https://ik.imagekit.io/vituepzjm/No%20data-pana.svg?updatedAt=1724334371763" alt="NO DATA" className="size-52" />
                  <p className="text-main text-xl dark:text-white break-words">Not Created Any Cards Yet !! </p>
                </div>
                :
                <div>
                  <h1 className="text-xl mb-3 text-main dark:text-white font-semibold">Recent Cards</h1>
                  <div className="grid gap-5 h-auto pb-5 overflow-y-auto relative md:pe-6 md:grid-cols-1 grid-cols-1 lg:grid-cols-2 sm:grid-cols-2 xl:grid-cols-3">
                    {cards.length > 0 && cards.slice(0, 3).map((card, index) => <div key={index}>{renderCardContent(card)}</div>)}
                    <Link to="/dashboard/noteboard" className="absolute lg:flex hidden -right-2.5 top-1/2 transition-all  bg-main/80 text-white dark:text-main dark:bg-white -mt-6 p-2 rounded-full -translate-x-1/2"><MdOutlineKeyboardDoubleArrowRight className="text-lg" /></Link>
                  </div>
                </div>
              }
            </div>
          }
        </div>}

        {books.length > 0 && <div className="mb-5" >
          {CardsLoading ? <div className="flex items-center flex-col w-full h-[200px] mb-5 px-3 justify-center ">
            <l-ping bg-opacity="0.1" speed="1.75" color="#1a1a1a"></l-ping>
          </div> :
            <div>
              {cards.length < 1 ?
                <div className="flex items-center flex-col w-full h-full mb-5 px-3 justify-center ">
                  <img src="https://ik.imagekit.io/vituepzjm/No%20data-pana.svg?updatedAt=1724334371763" alt="NO DATA" className="size-52" />
                  <p className="text-main dark:text-white text-xl break-words">Not Created Any Books Yet !! </p>
                </div>
                :
                <div>
                  <h1 className="text-xl mb-3 text-main dark:text-white font-semibold">Recent Books</h1>
                  <div className="grid relative gap-5 h-auto pb-5 overflow-y-auto md:pe-6 md:grid-cols-1 grid-cols-1 lg:grid-cols-2 sm:grid-cols-2 xl:grid-cols-3">
                    {books.length > 0 && books.slice(0, 3).map((book, index) => <div key={index}>{renderBookContent(book)}</div>)}
                    <Link to="/dashboard/notebook" className="absolute lg:flex hidden -right-2.5 top-1/2 transition-all  bg-main/80 text-white dark:text-main dark:bg-white -mt-6 p-2 rounded-full -translate-x-1/2"><MdOutlineKeyboardDoubleArrowRight className="text-lg" /></Link>
                  </div>
                </div>
              }
            </div>
          }
        </div>}
      </div>
    </section>
  );
}

export default Profile;
