import React from 'react';
import { generateHTML, ParseDate } from '../../../../../common/methods';
import { FaCompressAlt } from 'react-icons/fa';
import { useData } from '../../../../context/DataContext';
import { MdDeleteSweep, MdFolderDelete } from 'react-icons/md';
import { TbRestore } from "react-icons/tb";
import { TbTrashFilled } from 'react-icons/tb';

function Card({ item, HandleDelete, HandleRestore, isRestoring, isDelete }) {
    const { setpreviewCardTrash } = useData();

    const renderContent = () => {
        switch (item.type) {
            case "image":
                return (
                    <div className="flex h-[50vh] justify-center rounded-md items-center mb-4">
                        <img
                            src={item?.data}
                            alt={item?.name || "Image"}
                            className="max-w-full rounded-md object-cover h-full"
                        />
                    </div>
                );
            case "note":
                return (
                    <div
                        className="p-4 bg-white h-[50vh] w-full rounded-lg mb-4"
                    >
                        <div
                            dangerouslySetInnerHTML={{ __html: generateHTML(item?.data) }}
                            className="text-gray-800 break-all leading-relaxed"
                        />
                    </div>
                );
            case "todo":
                return (
                    <div
                        className="p-4 bg-white h-[50vh] w-full rounded-lg mb-4"
                    >
                        <div
                            dangerouslySetInnerHTML={{ __html: generateHTML(item?.data) }}
                            className="text-gray-800 break-all leading-relaxed"
                        />
                    </div>
                );
            case "audio":
                return (
                    <audio controls className="w-full max-w-lg rounded-lg">
                        <source src={item?.data} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                );
            case "sketch":
                return (
                    <div
                        className="w-full  h-[50vh] rounded-lg mb-4 overflow-hidden"
                        dangerouslySetInnerHTML={{ __html: item?.data }}
                    />
                );
            default:
                return (
                    <p className="text-gray-800 leading-relaxed mb-4 p-4 bg-gray-50 rounded-lg">
                        {item?.data}
                    </p>
                );
        }
    };

    return (
        <div style={{ backgroundColor: item?.color || '#fff' }} className="rounded-lg w-full">
            <div className="bg-main text-white h-auto p-4 rounded-t-lg flex justify-between items-center">
                <h2 className="text-xl font-semibold">
                    {item?.name} - {item.type[0].toUpperCase() + item.type.slice(1)} Card
                </h2>
                <span className="inline-flex items-center gap-3">
                    <button disabled={isRestoring} onClick={() => HandleRestore(item)}
                        className="text-white hover:text-gray-200 text-lg transition-transform transform hover:scale-105"
                    >
                        <TbRestore />
                    </button>
                    <button disabled={isDelete} onClick={() => HandleDelete(item)}
                        className="text-white hover:text-gray-200 text-lg transition-transform transform hover:scale-105"
                    >
                        <TbTrashFilled />
                    </button>
                    <button
                        onClick={() => setpreviewCardTrash({})}
                        className="text-white hover:text-gray-200 transition-transform transform hover:scale-105"
                    >
                        <FaCompressAlt />
                    </button>
                </span>
            </div>
            <div className="bg-main/90 text-gray-200 h-auto px-4 py-2 flex justify-between">
                <p>Created: {ParseDate(item?.createdAt)}</p>
                <p>Deleted: {ParseDate(item?.updatedAt)}</p>
            </div>
            <div className="overflow-y-auto max-h-[80vh] h-full">
                <div className="w-full p-4 flex items-center justify-center h-full">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default Card;
