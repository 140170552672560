import React from 'react';
import { generateHTML, ParseDate } from '../../../../../common/methods';
import { FaCompressAlt } from 'react-icons/fa';
import { useData } from '../../../../context/DataContext';
import { MdDeleteSweep, MdFolderDelete, MdRestorePage } from 'react-icons/md';
import { TbTrashFilled } from 'react-icons/tb';

function Book({ item, HandleDelete, HandleRestore, isRestoring, isDelete }) {
    const { setpreviewBookTrash } = useData();

    return (
        <div style={{ backgroundColor: item?.color || '#fff' }} className="rounded-lg w-full">
            <div className="bg-main text-white h-auto p-4 rounded-t-lg flex justify-between items-center">
                <h2 className="text-xl font-semibold">
                    {item?.name} - Note Book
                </h2>
                <span className="inline-flex items-center gap-3">
                    <button disabled={isRestoring} onClick={() => HandleRestore(item)}
                        className="text-white hover:text-gray-200 text-lg transition-transform transform hover:scale-105"
                    >
                        <MdRestorePage />
                    </button>
                    <button disabled={isDelete} onClick={() => HandleDelete(item)}
                        className="text-white hover:text-gray-200 text-lg transition-transform transform hover:scale-105"
                    >
                        <TbTrashFilled />
                    </button>
                    <button
                        onClick={() => setpreviewBookTrash({})}
                        className="text-white hover:text-gray-200 transition-transform transform hover:scale-105"
                    >
                        <FaCompressAlt />
                    </button>
                </span>
            </div>
            <div className="bg-main/90 text-gray-200 h-auto px-4 py-2 flex justify-between">
                <p>Created: {ParseDate(item?.createdAt)}</p>
                <p>Deleted: {ParseDate(item?.updatedAt)}</p>
            </div>
            <div className="overflow-y-auto max-h-[80vh] h-full">
                <div className="w-full p-4 flex items-center justify-center h-full">
                    <div className="p-4 bg-white h-[50vh] overflow-y-auto w-full rounded-lg mb-4">
                        <div dangerouslySetInnerHTML={{ __html: generateHTML(item?.data) }} className="text-gray-800 break-all leading-relaxed" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Book;
