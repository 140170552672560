import React from 'react'

function Collabration() {
    return (
        <section class="py-24 relative">
            <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                <div class="w-full flex-col justify-center items-center lg:gap-14 gap-10 inline-flex">
                    <div class="w-full flex-col justify-center items-center gap-5 flex">
                        <img className=' h-auto md:h-96' src="https://ik.imagekit.io/vituepzjm/Scribby/front_main_hero_together.webp?updatedAt=1725384601380" alt="under maintenance image" />
                        <div class="w-full flex-col justify-center items-center gap-6 flex">
                            <div class="w-full flex-col justify-start items-center gap-2.5 flex">
                                <h2 class="text-center text-gray-800 text-3xl font-bold leading-normal">Share and collaborate</h2>
                                <p class="text-center max-w-3xl w-[90%] text-gray-500 text-lg font-medium leading-relaxed">Safely and securely share your notes with friends and co-workers. Share notes and collaborate securely with the people you trust—and only them.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Collabration