import React, { useEffect, useState } from "react";
import Banner from "./components/Banner";
import Navbar from "./components/Navbar";
import LoadingPage from "../Loading/LoadingPage";

function Contact() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <div>
      <Banner />
      <Navbar />
    </div>
  );
}

export default Contact;
