import React from "react";
import { TbSquareRoundedPlus } from "react-icons/tb";
import { MdCheck } from "react-icons/md";

function Card({ data, status, meth }) {
  return (
    <div>
      {status ? (
        <div className="flex flex-col px-4 py-4 rounded-md border dark:border-white border-main/40 dark:bg-white bg-main ">
          <div className="inline-flex items-center mb-5 justify-between">
            <div
              className="text-white dark:text-main text-3xl"
              dangerouslySetInnerHTML={{ __html: data.icon }}
            ></div>

            <span className="">
              <button
                onClick={() => meth(data)}
                className="text-xs font-medium px-2 py-1 inline-flex items-center justify-center gap-1 bg-white dark:bg-main dark:text-white text-main rounded-md"
              >
                {" "}
                <MdCheck /> Added
              </button>
            </span>
          </div>
          <div className="dark:text-main text-white">
            <h1 className="text-lg leading-none mb-2 font-semibold">
              {data.name}
            </h1>
            <p className="text-base leading-relaxed font-normal">{data.desc}</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col px-4 py-4 rounded-md border dark:border-main border-main/40 ">
          <div className="inline-flex items-center mb-5 justify-between">
            <div
              className="dark:text-white text-main text-3xl"
              dangerouslySetInnerHTML={{ __html: data.icon }}
            ></div>
            <span className="">
              <button
                onClick={() => meth(data)}
                className="text-xs font-medium px-2 py-1 inline-flex items-center justify-center gap-1 dark:bg-white bg-main/10  text-main rounded-md"
              >
                {" "}
                <TbSquareRoundedPlus /> Add to Home
              </button>
            </span>
          </div>
          <div className="">
            <h1 className="text-lg dark:text-white leading-none mb-2 font-semibold">
              {data.name}
            </h1>
            <p className="text-base leading-relaxed dark:text-white font-normal">{data.desc}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Card;
