import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import Calendar from "react-widgets/Calendar";
import "react-widgets/styles.css";
import Clock from "../widgets/Clock";
import RssNews from "../widgets/RssNews";
import Calculator from "../widgets/Calculator";
import Maps from "../widgets/Maps";
import Card from "../widgets/Cards";
import LinkCard from "../widgets/card/Card";
import StopClock from "../widgets/StopClock";
import CardCount from "../widgets/CardCount";
import * as Dialog from "@radix-ui/react-dialog";
import { BiSolidWidget } from "react-icons/bi";
import { useData } from "../../context/DataContext";
import { widgetslist } from "../../../common/links";
import { BsThreeDotsVertical } from "react-icons/bs";
import * as Popover from "@radix-ui/react-popover";
import { Link } from "react-router-dom";
import RecentsCards from "../widgets/RecentsCards";
import { MdRemoveCircle } from "react-icons/md";

function DashboardHome() {
  const { user } = useUserAuth();
  const { widgets, setWidgets } = useData();
  const [ToolKitOpen, setToolKitOpen] = useState();
  const { setTitle, setLocal, Local } = useData();

  useEffect(() => {
    setTitle("Dashboard Overview");

    return () => {
      setTitle("");
    };
  }, [setTitle]);

  const updateLocalStorage = (updatedWidgets) => {
    localStorage.setItem("widgets", JSON.stringify(updatedWidgets));
  };

  const AddWidget = (widget) => {
    const updatedWidgets = [...widgets, widget];
    setWidgets(updatedWidgets);
    updateLocalStorage(updatedWidgets);
  };

  const isNotEmptyObject = (obj) => Object.keys(obj).length > 0;

  const RemoveWidget = (widget) => {
    if (widget.name == "Embed Cards") {
      setLocal({})
      localStorage.removeItem('embed');
    }
    const updatedWidgets = widgets.filter((item) => item.name !== widget.name);
    setWidgets(updatedWidgets);
    updateLocalStorage(updatedWidgets);
    setToolKitOpen(false)
  };

  return (
    <div className="w-full shadow-md h-full dark:bg-main bg-white overflow-y-auto rounded-md max-h-[90vh] flex flex-col items-start justify-between">
      <div className="flex items-center justify-end w-full pe-5 pt-5 ps-2 pb-2 mb-3">
        <button onClick={() => { setToolKitOpen(true) }} className="px-3 py-2 rounded-md dark:bg-white dark:text-main bg-main text-white">
          Manage ToolsKit
        </button>
        <Dialog.Root open={ToolKitOpen} >
          <Dialog.Portal>
            <Dialog.Overlay onClick={() => { setToolKitOpen(false) }} className="bg-main/5 z-[100] data-[state=open]:right-0 right-[50%] top-0 fixed inset-0">
              <Dialog.Content className="z-[1000] h-screen data-[state=open]:animate-enterFromRight fixed top-0 right-0 w-[75%] flex flex-col max-w-[400px] dark:bg-main bg-white focus:outline-none">
                <span className="dark:bg-white bg-main/10 p-5 h-[10vh]">
                  <Dialog.Title className="text-main  inline-flex items-center gap-3 text-[17px] font-medium">
                    <BiSolidWidget className="text-main text-2xl" />
                    <p className="text-xl">Add Tools</p>
                  </Dialog.Title>
                </span>

                <div className="w-full flex flex-col gap-5 p-5 h-[90vh] overflow-y-auto">
                  {widgetslist.map((item1, index) => (
                    <div key={index}>
                      {widgets.some((item2) => item2.name === item1.name) ? (
                        <LinkCard
                          data={item1}
                          meth={RemoveWidget}
                          status={true}
                        />
                      ) : (
                        <LinkCard data={item1} meth={AddWidget} status={false} />
                      )}
                    </div>
                  ))}
                </div>
              </Dialog.Content>
            </Dialog.Overlay>
          </Dialog.Portal>
        </Dialog.Root>
      </div>

      <span className={`grid xl:grid-cols-3 lg:grid-cols-4 grid-cols-3 gap-5 pb-10 mx-auto md:mx-0 md:px-10 md:w-full w-[90%]`}>
        {widgets.some((obj) => obj.name.includes("Banner")) && (
          <div className=" col-span-3 lg:col-span-4 xl:col-span-3 relative mt-5 py-3 flex flex-col-reverse md:flex-row items-center min-h-[300px] justify-center md:gap-5 md:justify-evenly rounded-lg bg-white bg-[url('https://dashboard.algolia.com/client-assets/c1c9361fe75370d1b156733e962f7214/514f2ec3798090c6df00dad1592c8166.svg')]">
            <span className="md:mt-10 flex items-center justify-center flex-col">
              <h1 className="lg:text-4xl text-3xl text-center font-medium title-font mb-4 italic text-[#3f3d56]">
                Welcome <br className="flex md:hidden" />
                <span className="text-main font-semibold break-words capitalize">
                  {user.displayName}
                </span>
                &nbsp;!
              </h1>
              <p className="text-[#3f3d56] break-words text-center xl:text-left text-base md:text-lg">
                Check what's happening on your Scribby Dashboard.
              </p>
            </span>
            <span className="md:hidden lg:flex flex">
              <img
                src="https://ik.imagekit.io/vituepzjm/Scribby/Notes-pana.svg?updatedAt=1723928906412"
                alt="banner"
                className=" w-72 md:w-[22rem]"
              />
            </span>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute  transition-all p-2 dark:bg-main bg-white block  rounded-bl-lg rounded-tr-md top-0 right-0">
                  <BsThreeDotsVertical className="text-main dark:text-white" />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 dark:bg-main bg-white w-fit border dark:border-white border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={3}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main dark:text-white focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[0]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main dark:lg:fill-main/80 hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("Recent Cards")) && (
          <div className="col-span-3  lg:col-span-4 xl:col-span-3 md:px-5 relative mt-5 md:p-3 min-h-[300px] dark:bg-main bg-white">
            <h1 className="text-xl text-main dark:text-white ms-4 font-semibold pb-4">Recent Cards</h1>
            {/* <br /> */}
            <RecentsCards />
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute transition-all p-3 bg-transparent block rounded-bl-lg rounded-tr-md top-0 right-0">
                  <BsThreeDotsVertical className="text-main dark:text-white" />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[1]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main dark:lg:fill-white hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.filter(item => !item.name.includes("Banner") && !item.name.includes("Recent Cards")).length > 0 && (
          <div className="relative pt-5 group col-span-3">
            <p className="text-xl ps-1 inline-flex gap-3 items-center justify-center md:text-2xl  text-start text-main break-words">
              <BiSolidWidget /> Your ToolKit
            </p>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("NoteCard Counts")) && (
          <div className="shadow-lg relative group min-h-[300px] xl:min-h-[300px] lg:min-h-fit col-span-3 md:col-span-3 lg:col-span-2 xl:col-span-1 border-2 border-main/30 p-3 bg-main dark:bg-white rounded-lg">
            <CardCount />
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute  transition-all p-2 bg-white dark:bg-main block  rounded-bl-lg rounded-tr-md top-0 right-0">
                  <BsThreeDotsVertical className="text-main dark:text-white" />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[2]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("Calendar & Time")) && (
          <div className="shadow-lg relative group flex flex-col border-2 border-main/30 order-1 lg:order-3 xl:order-1 md:flex-row justify-center gap-3 lg:col-span-4 xl:col-span-2 col-span-3 bg-main p-2 rounded-lg">
            <div className="">
              <div className="bg-white border-2 border-white h-full rounded-md">
                <Clock />
              </div>
            </div>
            <div className="bg-white rounded-md">
              <Calendar defaultValue={new Date()} />
            </div>

            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute  transition-all p-2 bg-main block  rounded-bl-lg rounded-tr-md top-0 right-0">
                  <BsThreeDotsVertical className="text-white " />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[3]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("Stopwatch")) && (
          <div className="shadow-lg relative group min-h-[300px] col-span-3 order-1 xl:order-1 md:col-span-3 lg:col-span-2 xl:col-span-1 border-2 border-main/30 p-3 bg-main rounded-lg">
            <StopClock />
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute  transition-all p-2 bg-white block  rounded-bl-lg rounded-tr-md top-0 right-0">
                  <BsThreeDotsVertical className="text-main " />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[4]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("Calculator")) && (
          <div className="shadow-lg relative group min-h-[300px] order-1 xl:order-1 col-span-3 md:col-span-3 lg:col-span-2 xl:col-span-1 border-2 border-main rounded-lg">
            <Calculator />

            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute  transition-all p-2 bg-main block  rounded-bl-lg rounded-tr-md top-0 right-0">
                  <BsThreeDotsVertical className="text-white " />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[5]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("Embed Cards")) && (
          <div className="shadow-lg relative group min-h-[300px] order-1 xl:order-1 col-span-3 md:col-span-3 lg:col-span-2 xl:col-span-1 border-2 border-main/30 bg-main rounded-lg">
            <Card />
            <div className="absolute right-0 top-0 flex items-center justify-center border-b-2 border-l-2 rounded-bl-lg border-main divide-x-2 divide-main">
              {isNotEmptyObject(Local) && <button onClick={() => {
                setLocal({})
                localStorage.removeItem('embed');
              }} className="transition-all block  p-2 rounded-bl-md bg-white ">
                <MdRemoveCircle className="text-main" />
              </button>}
              <Popover.Root>
                <Popover.Trigger asChild>
                  <button className={`transition-all p-2 bg-white block ${!isNotEmptyObject(Local) && 'rounded-bl-md'} rounded-tr-md`}>
                    <BsThreeDotsVertical className="text-main " />
                  </button>
                </Popover.Trigger>
                <Popover.Portal>
                  <Popover.Content
                    className="grid grid-cols-1 me-16 gap-3 rounded-md p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                    sideOffset={5}
                  >
                    <div
                      className="px-2"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <button
                        className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                        onClick={() => {
                          RemoveWidget(widgetslist[6]);
                        }}
                      >
                        Remove Widget
                      </button>
                    </div>
                    <Popover.Arrow className="fill-transparent lg:fill-main hidden lg:block -ms-9" />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </div>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("Maps")) && (
          <div className="shadow-lg relative group min-h-[300px] h-full lg:col-span-2 order-1 lg:order-6 xl:order-1 xl:col-span-2 col-span-3 border-2 border-main/30 bg-white rounded-lg">
            <Maps />
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute  transition-all block  p-2 rounded-lg bg-main top-2 right-12">
                  <BsThreeDotsVertical className="text-white" />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[7]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.some((obj) => obj.name.includes("News Feed")) && (
          <div className="shadow-lg relative group col-span-3 md:col-span-3 order-1 lg:order-5 xl:order-1 lg:col-span-2 xl:col-span-1 overflow-y-auto border-2 border-main bg-white rounded-lg">
            <RssNews />
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="absolute transition-all p-2 bg-main block rounded-bl-lg rounded-tr-md top-0 right-0">
                  <BsThreeDotsVertical className="text-white " />
                </button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content
                  className="grid grid-cols-1 me-16 gap-3 rounded-lg p-1 mt-1 bg-white w-fit border border-main shadow-lg will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                  sideOffset={5}
                >
                  <div
                    className="px-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <button
                      className={` block w-full text-start px-2 py-2 text-sm font-semibold text-main focus:outline-none`}
                      onClick={() => {
                        RemoveWidget(widgetslist[8]);
                      }}
                    >
                      Remove Widget
                    </button>
                  </div>
                  <Popover.Arrow className="fill-transparent lg:fill-main hidden lg:block -ms-9" />
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        )}

        {widgets.filter(item => !item.name.includes("Banner") && !item.name.includes("Recent Cards")).length == 0 && (
          <div className={` ${widgets.filter(item => item.name.includes("Banner") || item.name.includes("Recent Cards")).length == 0 ? 'h-[45vh]' : ' max-h-[50vh]'} relative group col-span-3 lg:col-span-4 xl:col-span-3 overflow-y-auto`}>
            <div className={` ${widgets.filter(item => item.name.includes("Banner") || item.name.includes("Recent Cards")).length == 0 ? 'text-main/80 italic' : 'bg-main text-white'} w-full rounded-lg h-fit`}>
              <p className="text-xl p-5 text-center break-words">
                Select Some Tools From the Manage ToolsKit.
              </p>
            </div>
          </div>
        )}
      </span>
    </div>
  );
}

export default DashboardHome;
