import React, { useEffect, useState } from "react";
import { generateHTML, ParseDate, processChecklistData } from "../../../../common/methods";
import { LuLoader2 } from "react-icons/lu";
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useData } from "../../../context/DataContext";
import { db } from "../../../../config/firebase";
import toast, { Toaster } from "react-hot-toast";
import RingBar from "./Ring";

function Card({ item }) {
  const [revokingEmail, setRevokingEmail] = useState(null);
  const { setCards, setboardPreview } = useData();

  useEffect(() => {
    console.log(item);
  }, [item]);

  const revokeAccess = async (email) => {
    try {
      setRevokingEmail(email);
      const userDocQuery = query(
        collection(db, "notecard"),
        where("ncid", "==", item.ncid)
      );

      const querySnapshot = await getDocs(userDocQuery);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        const updatedArray = item.shared.filter((id) => id !== email);

        await updateDoc(docRef, {
          shared: updatedArray,
        });

        setCards((prevCards) =>
          prevCards.map((c) =>
            c.ncid === item.ncid ? { ...c, shared: updatedArray } : c
          )
        );

        setboardPreview({});
        toast.success("Access revoked successfully");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRevokingEmail(null);
    }
  };

  const renderContent = () => {
    switch (item.type) {
      case "image":
        return (
          <div className="flex justify-center items-center mb-4">
            <img
              src={item?.data}
              alt={item?.name || "Image"}
              className="max-w-full max-h-60 rounded-lg shadow-md"
            />
          </div>
        );
      case "note":
        return (
          <div
            style={{ backgroundColor: item?.color }}
            className="p-4 rounded-lg shadow-md mb-4"
          >
            <div
              dangerouslySetInnerHTML={{ __html: generateHTML(item?.data) }}
              className="text-gray-800 break-all leading-relaxed"
            />
          </div>
        );
      case "todo":
        return (
          <div
            style={{ backgroundColor: item?.color }}
            className="p-4 rounded-lg flex items-center justify-center shadow-md mb-4"
          >
            {/* <div
              dangerouslySetInnerHTML={{ __html: generateHTML(item?.data) }}
              className="text-gray-800 break-all leading-relaxed"
            /> */}
            <RingBar data={processChecklistData(item.data)} color={item?.color} />
          </div>
        );
      case "canva":
        return (
          <div
            style={{ backgroundColor: item?.color }}
            className="p-4 rounded-lg flex items-center justify-center shadow-md mb-4"
          >

          </div>
        );
      case "audio":
        return (
          <div className="flex justify-center items-center mb-4">
            <audio controls className="w-full max-w-lg rounded-lg">
              <source src={item?.data} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        );
      case "sketch":
        return (
          <div
            className="w-full max-w-md h-auto rounded-lg shadow-md mb-4 overflow-hidden"
            dangerouslySetInnerHTML={{ __html: item?.data }}
          />
        );
      case "card":
      default:
        return (
          <p className="text-gray-800 leading-relaxed mb-4 p-4 bg-gray-50 rounded-lg shadow-md">
            {item?.data}
          </p>
        );
    }
  };

  return (
    <div className="bg-white pt-6 px-6">
      {renderContent()}
      <h2 className="text-2xl font-semibold text-gray-900 mb-2">
        {item?.name.length > 15 ? item?.name.slice(0, 15) + '..' : item?.name}
      </h2>
      <div className="text-gray-700 mb-4">
        {/* <p className="mb-1 break-all">{item?.ncid}</p> */}
        <p className="capitalize inline-flex items-center gap-3">
          <div
            style={{ backgroundColor: item?.color }}
            className="size-5 border rounded-md shadow"
          />{" "}
          {item?.type} Card
        </p>
        <p className="mb-1">
          <span className="font-medium">Created:</span>{" "}
          {ParseDate(item?.createdAt)}
        </p>
        <p className="mb-1">
          <span className="font-medium">Edited:</span>{" "}
          {ParseDate(item?.updatedAt)}
        </p>
      </div>
      <div className="">
        {item.shared.length > 0 && <h1 className="text-main font-semibold text-lg mb-3 underline underline-offset-2">
          Shared with
        </h1>}
        {item?.shared.map((email) => (
          <span
            key={email}
            className="flex items-center justify-between mb-1 gap-3"
          >
            <h1 title={email} className="text-gray-500">
              {email.slice(0, 15) + ".."}
            </h1>
            {revokingEmail === email ? (
              <LuLoader2 className="text-red-700 animate-spin text-xs mt-1" />
            ) : (
              <button
                onClick={() => revokeAccess(email)}
                className="text-red-700 hover:underline underline-offset-2"
              >
                Revoke
              </button>
            )}
          </span>
        ))}
      </div>
      <Toaster />
    </div>
  );
}

export default Card;
