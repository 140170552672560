import React from 'react'

function Features() {
  return (
    <section class="text-main body-font">
      <div class="container lg:px-20 px-5 py-24 mx-auto">
        <div class="flex flex-col text-center  w-full lg:mb-10 mb-14">
          <h1 class="sm:text-4xl text-2xl font-bold title-font  leading-10 text-main/70">We Offer the <span className="text-main italic">Best Features</span> From Us For Your Convenience</h1>
        </div>
        <div class="flex flex-wrap -m-4">
          <div class="p-4 md:w-1/3">
            <div class="flex border-s-8 border-main rounded-lg h-full bg-main/5 p-8 flex-col">
              <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-main text-white flex-shrink-0">
                  <i className="size-4 fas fa-users"></i>
                </div>
                <h2 class="text-main text-lg title-font font-semibold">Collaboration</h2>
              </div>
              <div class="flex-grow">
                <p class="leading-relaxed text-base font-medium">Some platforms allow multiple users to edit same Card or Book simultaneously.</p>
              </div>
            </div>
          </div>
          <div class="p-4 md:w-1/3">
            <div class="flex border-s-8 border-main rounded-lg h-full bg-main/5 p-8 flex-col">
              <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-main text-white flex-shrink-0">
                  <i className="size-4 fas fa-brain"></i>
                </div>
                <h2 class="text-main text-lg title-font font-semibold">Ai Content Creator</h2>
              </div>
              <div class="flex-grow">
                <p class="leading-relaxed text-base font-medium">Unleash the power of AI for effortless content creation. Try Jarvis AI today!</p>

              </div>
            </div>
          </div>
          <div class="p-4 md:w-1/3">
            <div class="flex border-s-8 border-main rounded-lg h-full bg-main/5 p-8 flex-col">
              <div class="flex items-center mb-3">
                <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-main text-white flex-shrink-0">
                  <i className="size-4 fab fa-dropbox"></i>
                </div>
                <h2 class="text-main text-lg title-font font-semibold">Backup and Restore</h2>
              </div>
              <div class="flex-grow">
                <p class="leading-relaxed text-base font-medium">Back up and restore deleted Cards and Books from Trash</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features