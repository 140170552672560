import React, { useState, useEffect, useRef } from "react";
import { IoMdCloud } from "react-icons/io";
import { useHotkeys } from "react-hotkeys-hook";
import { IoArrowUpCircle, IoColorFill } from "react-icons/io5";
import * as Popover from "@radix-ui/react-popover";
import { collection, updateDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useData } from "../../context/DataContext";
import toast, { Toaster } from "react-hot-toast";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { HiTrash } from "react-icons/hi2";
import { helix } from 'ldrs';
import { FaGear } from "react-icons/fa6";
import { RiAiGenerate } from "react-icons/ri";
import { Excalidraw, MainMenu, WelcomeScreen } from "@excalidraw/excalidraw";

function NoteCardEditor({ setResults, setisEditing, item, HandleDelete, isDeleting, SortData }) {
    const [excalidrawAPI, setExcalidrawAPI] = useState(null);
    const [name, setName] = useState(item.name);
    const [isEditingName, setIsEditingName] = useState(false);
    const [isSaving, setisSaving] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const { setCards, setboardPreview } = useData();

    useEffect(() => {
        console.log(item)
    }, [])

    useHotkeys("ctrl+s", (event) => {
        event.preventDefault();
        handleSave();
    });

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            setisEditing(false);
            window.history.pushState(null, "", window.location.pathname);

            console.log("Back navigation attempt prevented");
        };
        window.addEventListener('popstate', handlePopState);
        window.history.pushState(null, "", window.location.pathname);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [setisEditing]);

    const handleSave = async () => {
        try {
            setisSaving(true);
            const sceneData = await excalidrawAPI.getSceneElements();
            if (sceneData.length > 0) {
                const noteData = {
                    name,
                    data: sceneData,
                    updatedAt: new Date(),
                };
                const userDocQuery = query(
                    collection(db, "notecard"),
                    where("ncid", "==", item.ncid)
                );
                const querySnapshot = await getDocs(userDocQuery);
                if (!querySnapshot.empty) {
                    const docRef = querySnapshot.docs[0].ref;
                    await updateDoc(docRef, noteData);

                    setCards((prevCards) =>
                        prevCards.map((c) =>
                            c.ncid === item.ncid
                                ? { ...c, ...noteData }
                                : c
                        )
                    );

                    if (setResults) {
                        setResults((prevCards) =>
                            prevCards.map((c) =>
                                c.ncid === item.ncid
                                    ? { ...c, ...noteData }
                                    : c
                            )
                        );
                    }

                    setboardPreview({});
                    setisEditing(false);
                    SortData();
                    toast.success("Card Saved Successfully");
                } else {
                    console.log("No matching document found.");
                }

                setisEditing(false);
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setisSaving(false);
        }
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        setIsEditingName(false);
    };

    return (
        <div className="bg-white relative w-full rounded-t-2xl">
            <div className="w-full h-[10vh] px-4 rounded-t-2xl bg-main flex items-center justify-between gap-3">
                {isEditingName ? (
                    <form onSubmit={handleNameSubmit} className="flex-1">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={handleNameSubmit}
                            className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold w-full outline-none"
                            autoFocus
                        />
                    </form>
                ) : (
                    <h1
                        onClick={() => setIsEditingName(true)}
                        className="text-lg text-white bg-main rounded-md px-4 py-2 font-bold cursor-pointer"
                    >
                        {name.length > 15
                            ? `${name.slice(0, 15)}..`
                            : name}
                    </h1>
                )}
                <div className="flex items-center gap-3">
                    <button
                        onClick={handleSave}
                        disabled={isSaving}
                        className="bg-white disabled:opacity-50 transition-all text-main font-bold p-2 rounded-full"
                    >
                        <IoMdCloud />
                    </button>

                    <button
                        onClick={() => setIsDeleteOpen(true)}
                        className="bg-red-500 disabled:opacity-50 transition-all text-white font-bold p-2 rounded-full"
                    >
                        <HiTrash className="text-lg" />
                    </button>
                    <AlertDialog.Root open={isDeleteOpen}>
                        <AlertDialog.Portal>
                            <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
                            <AlertDialog.Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-md focus:outline-none">
                                <AlertDialog.Title className="text-red-600 text-xl font-semibold">
                                    Are you absolutely sure?
                                </AlertDialog.Title>
                                <AlertDialog.Description className="text-gray-600 font-medium mt-2 mb-6">
                                    This action cannot be undone. This will move{" "}
                                    <span className="font-bold text-main">
                                        {`${item.name} - ${item.type[0].toUpperCase() + item.type.slice(1)} Card`}
                                    </span>{" "}
                                    to the Trash page where you can restore it anytime.
                                </AlertDialog.Description>
                                <div className="flex justify-start gap-3">
                                    <button
                                        disabled={isDeleting}
                                        onClick={() => {
                                            HandleDelete(item);
                                            setisEditing(false);
                                            setIsDeleteOpen(false);
                                        }}
                                        className="px-4 py-2 bg-red-600 disabled:bg-red-400 text-white rounded-md hover:bg-red-700"
                                    >
                                        Move to Trash
                                    </button>
                                    <button
                                        onClick={() => setIsDeleteOpen(false)}
                                        className="px-4 py-2 bg-main/10 text-main/90 rounded-md hover:bg-main/20"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </AlertDialog.Content>
                        </AlertDialog.Portal>
                    </AlertDialog.Root>

                    <AlertDialog.Root open={isSaving}>
                        <AlertDialog.Portal>
                            <AlertDialog.Overlay className="bg-black/30 fixed inset-0" />
                            <AlertDialog.Content className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[400px] transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 shadow-md focus:outline-none">
                                <div className="flex items-center pb-3 pt-6 justify-center">
                                    <l-helix
                                        size={60} bg-opacity="0.1" speed="1.75" color="#1a1a1a"
                                    ></l-helix>
                                </div>
                                <AlertDialog.Title className="text-center text-lg font-semibold mt-4">
                                    Hold Still Saving Changes
                                </AlertDialog.Title>
                                <AlertDialog.Description className="text-center text-gray-500 mt-2">
                                    Please wait while we save your changes. This might take a few moments.
                                </AlertDialog.Description>
                            </AlertDialog.Content>
                        </AlertDialog.Portal>
                    </AlertDialog.Root>

                </div>
            </div>

            <div className="w-full h-[85vh] text-main overflow-y-auto  py-3" >
                <Excalidraw initialData={{
                    elements: item.data,
                    appState: {},
                    collaborators: []
                }} excalidrawAPI={(api) => setExcalidrawAPI(api)}  >
                </Excalidraw>
            </div>

            <Toaster />

        </div>
    );
}

export default NoteCardEditor;
